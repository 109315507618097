import React, { useState, useRef } from "react";
import Layouts from "../../Layouts";
import { LocationMarkerIcon } from "@heroicons/react/solid";
import {
  setAddressString,
  setLatLong,actioncustomPinCode,
  //actioncustomRadius
} from "../../../Redux/Action/userActions";
import { secureStorage } from "../../../Redux/Reducer/authReducer";
import LocateIcon from "../../../Assets/Images/locate.svg";
import doctorImage from "../../../Assets/Images/doctorImage.svg";
import OneImage from "../../../Assets/Images/Icons/one.svg";
import TwoImage from "../../../Assets/Images/Icons/Two.svg";
import ThreeImage from "../../../Assets/Images/Icons/Three.svg";
import FourImage from "../../../Assets/Images/Icons/Four.svg";
import RoundClockImage from "../../../Assets/Images/Icons/RoundClock.svg";
import EmergencyImage from "../../../Assets/Images/Icons/EmergencyVan.svg";
import TimeframeImage from "../../../Assets/Images/Icons/TimeFrame.svg";
import CostEffectiveImage from "../../../Assets/Images/Icons/CostEffictive.svg";
import AnytimeImage from "../../../Assets/Images/Icons/Anytime.svg";
import DoctorImage from "../../../Assets/Images/Doctor/image 155.png";
import { useDispatch, useSelector } from "react-redux";
import doctorService from "../../../Redux/Services/doctorService";
import { Segmented } from "antd";
import { Input } from "antd";
import axios from "axios";
import http from "../../../Redux/Services/http-common";
import LocationImages from "../../../Assets/Images/Icons/locationblue.svg";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";
import { getDoctorslist } from "../../../Redux/Action/doctorAction";
import DoctorSpeciality from "./DoctorSpeciality";
import BookSymptoms from "./BookSymptoms";
import VideoCall from "../../../Assets/Images/Icons/videocall.svg";
import NodataImage from "../../../Assets/Images/Icons/nodata.svg";
import Loader from "../../Shared/loader";
import {Helmet} from "react-helmet";
import SearchBar from "../../Shared/SearchBar";
import { trackPageEvent } from "../../utils/analytics/events";


const { Search } = Input;

function BookanAppointment() {
  const dispatch = useDispatch();
  const doctorlist = useSelector((state) => state.doctorlist);
  const { doctorData, isLoading } = doctorlist;
  const [searchData, setSearchData] = useState("");
  const [selectedOption, setSelectedOption] = useState("Book by Speciality");
  const [showSearchContent, setShowSearchContent] = useState(true);
  const [showAllCards, setShowAllCards] = useState(false);
  const history = useHistory();
  const searchss = useLocation().search;
  const search_query = new URLSearchParams(searchss).get("search_query");
  const [isOpen, setIsOpen] = useState(false);
  const patientinfo = useSelector((state) => state.patientinfo);
  const { patientinfoData } = patientinfo;
  const [loading, setLoading] = useState(false);
  const [radiusselected, setRadiusselected] = useState("25");
  const [searchedText, setSearchedText] = useState("")
  const {radius, customPinCode: pincode, coords} = useSelector((state) => state.authReducer)
  const handleCardClick = (e, doctor) => {
    e.preventDefault();
    console.log(doctor.seoNameId, "seoNameId");
    history.push(`/doctors/${doctor.seoNameId}`);
    
    
   
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // const handleSearchDoctor = (e) => {
  //   setSearchData(e.target.value);
  //   if (e.target.value) {
  //     setIsOpen(true);
  //   }
  //   if (!e.target.value) {
  //     setIsOpen(false);
  //   }
  // };

  useEffect(() =>{
    trackPageEvent("doctor_page_view")
  },[])

  const seletedSearch = (event) => {
    console.log(event);
    let x = [];
    x.push(event);
    window.scrollTo({
      top: 530,
      behavior: "smooth",
    });
    setIsOpen(false);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        if (coords) {
            dispatch(
            getDoctorslist(coords,searchedText,1,100,radiusselected ?radiusselected:" ",radius)
          ).then(res => {
            window.scrollTo({
              top: 530,
              behavior: "smooth",
            });
          })
          setLoading(false)

        }
      } catch (error) {
        console.error("Error fetching doctors list:", error);
        setLoading(false)

      }
    };

    fetchData();
  }, [coords, searchedText,pincode]);

  const handleImgError = (e) => {
    e.target.src = doctorImage;
  };

  const onSearch = () => {
    setIsOpen(false);
    window.scrollTo({
      top: 530,
      behavior: "smooth",
    });
  };

  const displayedDoctors = showAllCards ? doctorData : doctorData.slice(0, 3);

  const DoctorCard = ({ doctor }) => {
    return (
      <>   
      
   
           {loading && <Loader />}
          

      <div className="bg-White p-3 md:p-5 rounded-lg flex flex-col justify-between">
        <div className="flex gap-4">
        
          <div className="w-[75px] h-[75px]">
            <img
              src={
                doctor.userPhoto
                  ? `${process.env.REACT_APP_IMG_BASEURL}${doctor.userPhoto}`
                  : doctorImage
              }
              alt={doctor.userName}
              className="rounded-full bg-lightBlue text-xs  w-[65px] h-[65px] object-contain"
              onError={handleImgError}
            />
          </div>
          <div className="w-full">
            <div className="flex justify-between w-full items-baseline mb-2">
              <div className="w-[75%]">
                <h6 className="text-primaryColor uppercase text-base font-semibold">
                  {doctor.userName}
                </h6>
                <p className="text-textGray text-xs font-medium mb-1.5 flex">
                  {doctor.specialities}
                </p>
                <p className="text-textGray text-xs font-medium mb-1.5 flex">
                  {doctor.yearOfExperience} years of exp.
                </p>
              </div>
              <div className="w-[20%] flex justify-end">
              {/* <i class="fa fa-hospital-o" aria-hidden="true"></i> */}
                <img src={VideoCall} alt="images" />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap justify-between items-center">
          <p
            style={{ width: "max-content" }}
            className="text-textGray text-xs font-medium mb-1.5 flex gap-2 items-center"
          >
            <img src={LocationImages} alt="images" className="w-3" />
            {doctor.city}
          </p>
          <p className="text-secondaryColor text-sm cursor-pointer font-medium">
            ₹ {doctor.videoConsultCharges}
          </p>
        </div>
        <div className="flex gap-3 mt-5">
          <button
            onClick={(e) => handleCardClick(e, doctor)}
            className="hover:text-secondaryColor text-primaryColor text-xs md:text-sm border border-primaryColor hover:border-secondaryColor w-full py-2 rounded-lg "
          >
            View Profile
          </button>
          <button
            className="hover:bg-secondaryColor bg-primaryColor rounded-lg text-White text-xs md:text-sm w-full py-2 "
            onClick={(e) => handleCardClick(e, doctor)}
          >
            Book Consultation
          </button>
        </div>
      </div>      </>

    );
  };

  const handleNavigation = (path) => {
    history.push(path);
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  };


  return (
    <Layouts>
          <Helmet> 
   <title>{"Hospitals | Curebay"} </title>
{/* <meta name='description' content={EnglishText.HOSPITAL_META_DESCRIPTION} /> */}
<link rel="canonical" href={window.location.href} /> 

<meta property="og:title" content={"Doctors | Curebay"} />
{/* <meta property="og:description" name='description' content={hosp?.hospitalName + " "+hosp?.city} /> */}
  </Helmet>
      {/* <div className="w-full h-[75px] bg-[#bfe4ff] py-3 fixed z-10">
        <div className="container mx-auto">
          <div className="md:w-[45%] mx-auto">
            <Search
              className="uniqueSearch apptWhite bg-White"
              placeholder="Search Doctors & Speciality"
              onChange={handleSearchDoctor}
              onKeyPress={handleSearchKeyPress}
              value={searchData}
              onSearch={onSearch}
              addonBefore={LocationSearch}
            />
            {doctorData.length > 0 && isOpen ? (
              <div className="relative w-full min-h-[10rem] mt-2">
                <div className=" flex  absolute  w-full bg-White max-h-[20rem] overflow-y-scroll rounded-2xl scrollWidth">
                  <div className="w-full flex flex-col gap-1">
                    {doctorData.map((doc, i) => (
                      <div key={i} className="w-full p-1 rounded-lg ">
                        <p
                          onClick={() => seletedSearch(doc)}
                          className="py-1 px-16 hover:bg-lightGray hover:text-primaryColor rounded-lg  w-full"
                        >
                          {doc.userName}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div> */}
      <SearchBar searchedText = {(text) => setSearchedText(text)} />
      <section className="home-banner md:bg-lightGray md:min-h-[60vh] py-8 ">
        <div className="container mx-auto pt-[70px]">
          <div className="bg-primaryColor absolute left-0 md:w-[70%] md:h-[60vh] rounded-e-xl"></div>
          <div className="md:grid grid-cols-2 gap-5 md:min-h-[60vh] items-center">
            <div className="relative z-1 pt-7 md:pt-5 p-5 md:p-0 rounded-2xl bg-[#F3F8FF] md:bg-primaryColor">
              <h1 className="text-primaryColor md:text-White text-lg md:text-5xl font-bold mb-5 !leading-snug">
                Consult the Best{" "}
                <span className="text-secondaryColor">Speciality</span> Doctors
              </h1>
              <p className="text-primaryColor md:text-White font-light text-sm md:text-lg">
                Doctor consultation starts at ₹ 100/- only
              </p>
            </div>
            <div className="relative z-1 flex justify-end mt-5 md:mt-0">
              <div className="w-full md:w-[90%]  rounded ">
                <img
                  src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/Doctors.webp"
                  alt="images"
                  className="w-full h-full object-cover image-boxshadow rounded-xl"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-lightGray">
        <div className="container mx-auto py-[50px] md:py-[90px]">
          <h2 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-[50px]">
            Book your <span className="text-secondaryColor">appointment</span>
          </h2>
          <div className="">
            {displayedDoctors.length !== 0 ? (
              <div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-6">
                {displayedDoctors?.map((doctor, index) => (
                  <DoctorCard key={index} doctor={doctor} />
                ))}
              </div>
            ) : (
              <div class=" w-full h-[inherit] flex gap-5 flex-col justify-center my-12 items-center text-center ">
                <div>
                  <img src={NodataImage} alt="emptyData" className="w-[60px]" />
                </div>
                <p className="text-center font-light item-center text-base text-textGray">
                  No results found
                </p>
              </div>
            )}
            <div className="flex justify-center">
              <div
                onClick={() => handleNavigation("/find-doctors")}
                className="mt-5 md:mt-10 cursor-pointer underline text-secondaryColor text-lg md:text-xl font-semibold text-center"
              >
                View All
              </div>
              <div className="hidden">
                {!showAllCards ? (
                  <div
                    onClick={() => setShowAllCards(true)}
                    className="mt-5 md:mt-10 cursor-pointer underline text-secondaryColor text-lg md:text-xl font-semibold text-center"
                  >
                    View All
                  </div>
                ) : (
                  <div
                    onClick={() => setShowAllCards(false)}
                    className="mt-5 md:mt-10 cursor-pointer underline text-secondaryColor text-lg md:text-xl font-semibold text-center"
                  >
                    View Less
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-White">
        <div className="container mx-auto  py-[50px] md:py-[60px]">
          <div className="md:w-[40%] mx-auto">
            {showSearchContent ? (
              <Segmented
                className="commonSegment "
                options={["Book by Speciality", "Book by Symptoms"]}
                block
                onChange={(option) => setSelectedOption(option)}
              />
            ) : (
              <Search className="apptSearch" placeholder="Search Doctors" />
            )}

            {/* <div className="mt-5">
              {showSearchContent ? (
                <div
                  className="underline text-textGray hover:text-primaryColor text-center cursor-pointer"
                  onClick={toggleContent}
                >
                  Search as your wish
                </div>
              ) : (
                <div
                  className="underline text-textGray hover:text-primaryColor text-center cursor-pointer"
                  onClick={toggleContent}
                >
                  Choose as your wish
                </div>
              )}
            </div> */}
          </div>
          {selectedOption === "Book by Speciality" && (
            <div>
              <DoctorSpeciality />
            </div>
          )}
          {selectedOption === "Book by Symptoms" && (
            <div>
              <BookSymptoms />
            </div>
          )}
        </div>
      </section>
      <section className="bg-primaryColor">
        <div className="container mx-auto py-12 md:py-[90px]">
          <h2 className="text-center text-lg md:text-4xl font-bold text-White leading-normal mb-12 md:mb-[50px]">
            How it <span className="text-secondaryColor">works</span>?
          </h2>
          <div className="grid md:grid-cols-4  grid-cols-1 gap-5 md:gap-10">
            <div className="flex md:block justify-center flex-col gap-4 items-center w-[80%] mx-auto">
              <img src={OneImage} alt="images" className="w-10 md:w-2/6" />
              <p className="text-White md:text-lg text-sm text-center md:text-left">
                Select a doctor based on symptoms or specialities.
              </p>
            </div>
            <div className="flex md:block justify-center flex-col gap-4 items-center w-[80%] mx-auto">
              <img src={TwoImage} alt="images" className="w-10 md:w-2/6" />
              <p className="text-White md:text-lg text-sm text-center md:text-left">
                Book an appointment at your convenience and make payment.
              </p>
            </div>
            <div className="flex md:block justify-center flex-col gap-4 items-center w-[80%] mx-auto">
              <img src={ThreeImage} alt="images" className="w-10 md:w-2/6" />
              <p className="text-White md:text-lg text-sm text-center md:text-left">
                Attend the consultation and receive your prescription.
              </p>
            </div>
            <div className="flex md:block justify-center flex-col gap-4 items-center w-[80%] mx-auto">
              <img src={FourImage} alt="images" className="w-10 md:w-2/6" />
              <p className="text-White md:text-lg text-sm text-center md:text-left">
                Our doctors can recommend specialists for further assessment.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-lightGray">
        <div className="container mx-auto py-[50px] md:py-[90px]">
          <h2 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-10 md:mb-[50px]">
            Why Online{" "}
            <span className="text-secondaryColor">Consultations</span>?
          </h2>
          <p className="bg-White px-5 md:px-10 py-5 text-sm md:text-lg md:leading-loose text-textGray rounded-xl">
            In the modern era, technology offers multiple avenues to connect
            with healthcare professionals without the necessity of physically
            visiting a medical facility. Utilizing online platforms for
            consultations has streamlined access to quality healthcare, ensuring
            individuals receive appropriate medical attention from the comfort
            of their own space.
          </p>
        </div>
      </section>
      <section className="bg-White md:bg-lightGray">
        <div className="container mx-auto py-[50px] md:pt-0 md:pb-[90px]">
          <h2 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-12 md:mb-[50px] ">
            Benefits of selecting{" "}
            <span className="text-secondaryColor">CureBay’s</span> online <br />{" "}
            doctor consultations
          </h2>
          <div className="md:flex gap-5 justify-center flex-wrap">
            <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow  md:w-[32%] hoverWhite mb-4 md:mb-0">
              <div className="mb-2 md:mb-5">
                <img src={RoundClockImage} alt="images" className="" />
              </div>
              <p className="text-textGray md:leading-loose md:text-lg text-sm">
                Round-the-clock availability of highly-qualified medical
                professionals
              </p>
            </div>
            <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow md:w-[32%] hoverWhite mb-4 md:mb-0">
              <div className="mb-2 md:mb-5">
                <img src={EmergencyImage} alt="images" className="" />
              </div>
              <p className="text-textGray md:leading-loose md:text-lg text-sm">
                Access to emergency medical services via digital platforms
              </p>
            </div>
            <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow md:w-[32%] hoverWhite mb-4 md:mb-0">
              <div className="mb-2 md:mb-5">
                <img src={TimeframeImage} alt="images" className="" />
              </div>
              <p className="text-textGray md:leading-loose md:text-lg text-sm">
                Receive prompt online consultations within a 15-minute timeframe
              </p>
            </div>
            <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow md:w-[32%] hoverWhite mb-4 md:mb-0">
              <div className="mb-2 md:mb-5">
                <img src={CostEffectiveImage} alt="images" className="" />
              </div>
              <p className="text-textGray md:leading-loose md:text-lg text-sm">
                Cost-effective rates coupled with tailored healthcare solutions
              </p>
            </div>
            <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow md:w-[32%] hoverWhite mb-4 md:mb-0">
              <div className="mb-2 md:mb-5">
                <img src={AnytimeImage} alt="images" className="" />
              </div>
              <p className="text-textGray md:leading-loose md:text-lg text-sm">
                Instant access to online consultations, anytime and anywhere.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layouts>
  );
}

export default BookanAppointment;
