import React, { useRef, useEffect, useState } from "react";
import Layouts from "../Layouts";
import videoIcon from "../../Assets/Images/Home/video-call.png";
import TrustIcon from "../../Assets/Images/Icons/Trust Icon.svg";
import TrustIcon1 from "../../Assets/Images/Icons/TrustIcon1.svg";
import Progress1 from "../../Assets/Images/progress-chat1.png";
import Progress2 from "../../Assets/Images/progress-chat2.png";
import Slider from "react-slick";
import doctorImage from "../../Assets/Images/doctorImage.svg";
import female_icon from "../../Assets/Images/Female-Vector-icon.svg";
import male_icon from "../../Assets/Images/Male-Vector-icon.svg";
import LabImage from "../../Assets/Images/LabImage.jpg";
import PrevArrow from "../../Assets/Images/left_arrow.png";
import NextArrow from "../../Assets/Images/right_arrow.png";
import Whychoose from "./WhyChoose";
import Linkarrow from "../../Assets/Images/Icons/linkarrow.svg";
import BgRectImage from "../../Assets/Images/bg-rect.png";
import TotalCareImage from "../../Assets/Images/Home/TotalCare.png";
import IndustryLead from "./IndustryLead";
import mapImage from "../../Assets/Images/map.png";
import { Input, Modal, message } from "antd";
import FAQ from "./FAQ";
import Contactus from "./Contactus";
import PatientBPChecking from "../../Assets/Images/Home/PatientBPChecking.jpg";
import PatientBPChecking1 from "../../Assets/Images/Home/PatientBPChecking1.jpg";
import PatientBPChecking2 from "../../Assets/Images/Home/PatientBPChecking2.jpg";
import PatientBPChecking3 from "../../Assets/Images/Home/PatientBPChecking3.jpg";
import PatientBPChecking4 from "../../Assets/Images/Home/PatientBPChecking4.jpg";
import PatientBPChecking5 from "../../Assets/Images/Home/PatientBPChecking5.jpg";

import PatientPulseChecking from "../../Assets/Images/Home/PatientTempChecking.jpg";
import Mobileai from "../../Assets/Images/mobile_ai.svg";
import PatientBloodTest from "../../Assets/Images/PatientBloodTest.jpg";
import careSathiMain from "../../Assets/Images/careSathiMain.svg";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Login from "../Authentications/Login";
import Calendar1 from "../../Assets/Images/Icons/calendar1.svg";
import ClockImage from "../../Assets/Images/Icons/clock.svg";
import DummyImage from "../../Assets/Images/dummyphoto.png";
// import AddFamilyMember from "../Shared/AddFamilyMember";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Mapmuind from "../Shared/Mapmyindia";
import { Helmet } from "react-helmet";
import { getToken } from "../../Redux/Action/userActions";
import {
  getPatientDetails,
  getPatientLabTestsList,
  getPatientfamilymembers,
  onPatientfamilyMembersDelete,
} from "./../../Redux/Action/UserprofileActions";
import patientService from "../../Redux/Services/patientService";
import MedicalSummaryImage1 from "../../Assets/Images/Home/Medicine-summary1.jpg";
import {
  deleteAppointment,
  removeUpdateSuccess,
} from "../../Redux/Action/doctorAction";
import { getLocalTime, getLocalDateWithTime } from "../Helper/LocalTimeFormat";
import { APP_ROUTES } from "../PageRouting/AppRoutes";
import CancelAppointmentPopup from "./PostLogin/CancelAppointmentPopup";
import RescheduleModal from "./PostLogin/RescheduleModal";
import { getPatientmedicinedeliveryList, getPatientmedicinedeliveryListOnFilter } from "../../Redux/Action/patientAction";
import moment from "moment";
import { AddtoCart } from "../../Redux/Action/cartPlaceOrderAction";
import UploadPrescriptionHelper from "../Helper/uploadPrescription";
import { getAllProductByIds } from "../../Redux/Action/pharmacyAction";
import AddFamilymember from "./PostLogin/AddFamilyMember";
import EprescriptionsView from "./Services/EprescriptionsView";
import VideoImage from "../../Assets/Images/Home/videoimage.png";
import NodataImage from "../../Assets/Images/Icons/nodata.svg";
import SubHeader from "../Shared/SearchBar";
import VideoImage1 from "../../Assets/Images/Home/Akshya Nayak.png";
import VideoImage2 from "../../Assets/Images/Home/Anita Dehuri.png";
import VideoImage3 from "../../Assets/Images/Home/Basanti Ratha.png";
import VideoImage4 from "../../Assets/Images/Home/Dipti Ranjan Parida_subtitle.png";
import VideoImage5 from "../../Assets/Images/Home/Kumuduni Parida.png";
import VideoImage6 from "../../Assets/Images/Home/Manmohan Biswal_subtitle.png";
import VideoImage7 from "../../Assets/Images/Home/Mitali Sahoo_subtitle.png";
import VideoImage8 from "../../Assets/Images/Home/Prasanna Behera_subtitle.png";
import VideoImage9 from "../../Assets/Images/Home/Rabi Behera.png";
import VideoImage10 from "../../Assets/Images/Home/Sanatan Kar_subtitle.png";
import { ToastContainer, toast } from "react-toastify";
import AISMARTVITALSIMAGE from '../../Assets/Images/smartVitals.svg'

import DENTISTRYIMAGE from '../../Assets/Images/dentalCare.svg'
import LABREPORTANALYSERIMAGE from '../../Assets/Images/labInsight.svg'
import QUANTUMHEROIMAGE from '../../Assets/Images/quantum-hero-image.png'
import { RightOutlined } from "@ant-design/icons";
import "react-toastify/dist/ReactToastify.css";
import { trackAddToCartEvent } from "../utils/analytics/events";

const Home = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isHide, setHide] = useState(true);
  const from = location?.state?.medicine || "";
  const needHelp = useSelector((state) => state.needheplReducer.needHelp);
  const [initialRender, setInitialRender] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [appointmentdetails, setappointmentdetails] = useState();
  const [appointmentlistData, setAppointmentlistData] = useState([]);
  const doctorAppointment = useSelector((state) => state.doctorAppointment);
  const { success, isError } = doctorAppointment;
  const firstContentRef = useRef(null);
  const secondContentRef = useRef(null);
  // const videoRef = useRef(null);
  const patientCode = useSelector((state) => state.authReducer.patientCode);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [error, setError] = useState("");
  const [isErrorMsg, setErrorMsg] = useState("");
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [selectedUserCode, setSelectedUserCode] = useState(patientCode);
  const [happyPatientFullView, setHappyPatientFullView] = useState(false);
  const userData = useSelector((state) => state.authReducer.patientData);
  const [medicineList, setmedicineList] = useState([]);
  const [list, setList] = useState([]);
  const Need = location?.state?.from || "";
  const [modal1, setModal1] = useState(false);
  const [prescriptionDialog, setPrescriptionDialog] = useState(false);
  const [prescriptionDoc, setPrescriptionDoc] = useState([]);
  const [reorderData, setReorderData] = useState([]);
  const [isDialogOpen, setDialog] = useState(false);
  const [cartData, setCartData] = useState({});
  const [addToCartLoading, setAddToCartLoading] = useState(false);
  const { cartList } = useSelector((state) => state.cartReducer);
  const [prescriptionView, setPrescriptionView] = useState(false);
  const [userID, setUserId] = useState(null);

  const medicinedeliverylist = useSelector(
    (state) => state.patientmedicinedeliverylist
  );
  const { PatientmedicinedeliveryData } = medicinedeliverylist;
  const familymemberinfo = useSelector((state) => state.familymembers);
  const { FamilymembersData } = familymemberinfo;
  const [Editmember, setEditmember] = useState();
  const [showaddmemberpopup, setshowaddmemberpopup] = useState(false);
  const [currSelectedOrder, setCurrSelectedOrder] = useState();

  const showModal1 = (medicineOrder) => {
    setModal1(true);
    setCurrSelectedOrder(medicineOrder);
  };

  useEffect(() => {
    if(userData?.code){
      const payload = {
        patientId: userData.code,
        photoRequired: "Y",
        status: 1,
      };
      dispatch(getPatientmedicinedeliveryListOnFilter(payload, 1));
    }

  }, [userData.code]);

  useEffect(() => {
    if (PatientmedicinedeliveryData?.data?.length) {
      setmedicineList(PatientmedicinedeliveryData?.data);
    }
  }, [PatientmedicinedeliveryData?.data?.length]);

  useEffect(() => {
    if(userData?.code){
      dispatch(getPatientLabTestsList(userData.code))
      .then((res) => {
        let data = res?.filter((item) => item.procedureStatus !== -1);
        setList(data);
      })
      .catch((err) => {
        setList([]);
      });
      dispatch(getPatientfamilymembers(userData.code));
      getPatientAppointment(userData?.code);
    }

  }, [userData.code]);

  const handlePrescription = (userId) => {
    setPrescriptionView(true);
    setUserId(userId);
  };

  const editmembers = (e, user) => {
    e.preventDefault();
    setshowaddmemberpopup(true);
    setEditmember(user);
  };

  const handleScroll = () =>{
    if(window.innerWidth < 768){
        document.body.scrollTop= 4400;
      document.documentElement.scrollTop = 4400;
    }
    else{
      document.body.scrollTop= 3500;
      document.documentElement.scrollTop = 3500;
    }
    
  }

  const deleteMembers = (e, user) => {
    e.preventDefault();
    dispatch(onPatientfamilyMembersDelete(user?.code)).then((res) => {
      dispatch(getPatientfamilymembers(userData.code));
    });
  };

  const refreshFamilyMembers = () => {
    dispatch(getPatientfamilymembers(userData.code));
  };

  const addtoCart = (e, user) => {
    e.preventDefault();
    // setIsAdding(true);

   

    let prescriptionRequired = "N";
    let ePrescriptionRequired = "N";

    if (user?.prescriptionDoc) {
      prescriptionRequired = "Y";
      ePrescriptionRequired = "Y";
    } else {
      prescriptionRequired = "N";
      ePrescriptionRequired = "N";
    }
    let dataObj = {};

    let isAdded = false;
    setAddToCartLoading(true);
    let productDetail = reorderData.map((res) => {
      return {
        drugCode: res?.id,
        drugName: res?.drugName,
        unitPrice: res?.medicineRate,
        drugCategory: res?.drugCategory,
        discountAmount: res?.drugsInfoDiscountedRate
          ? res?.drugsInfoDiscountedRate
          : 0.0,
        totalAmount: res?.medicineRate * res.quantity,
        quantity: res.quantity,
        cartId: cartList && cartList.id ? cartList.cartId : "",
        createdBy: userData.code,
        createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
        modifiedBy: userData.code,
        modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
        orderId: null,
        patientId: userData.code,
        prescriptionRequired: res.prescriptionRequired == "Yes" ? "Y" : "N",
        ePrescriptionRequired: res.ePrescriptionRequired == "Yes" ? "Y" : "N",
        medicineTypeOfSell: res?.medicineTypeOfSell,
        membershipCode: null,
        membershipName: null,
        membershipCard: null,
        membershipDiscountPercentage: 0.0,
      };
    });
    if (cartList && cartList.patientMedicineOrder) {
      cartList.patientMedicineOrder.forEach((element) => {
        productDetail.map((res) => {
          element.patientMedicineOrderDetailsList.push(res);
        });
      });
      dataObj = cartList;
    } else {
      dataObj = {
        cartId: cartList && cartList.id ? cartList.cartId : "",
        createdBy: userData.code,
        createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
        modifiedBy: userData.code,
        modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
        orderId: null,
        patientId: userData.code,
        status: 1,
        labOrdersYN: false,
        drugsOrdersYN: true,
        totalAmount: 0,
        patientMedicineOrder: [
          {
            orderId: "",
            patientId: userData.code,
            prescriptionId: null,
            cartId: cartList && cartList.id ? cartList.cartId : "",
            txnId: "",
            totalAmount: reorderData[0]?.medicineRate,
            address1: null,
            address2: null,
            address3: null,
            city: null,
            state: null,
            country: null,
            pincode: null,
            deliveryAddress1: null,
            deliveryAddress2: null,
            deliveryAddress3: null,
            deliveryCity: null,
            deliveryState: null,
            deliveryCountry: null,
            deliveryZipcode: null,
            createdBy: userData.code,
            createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
            modifiedBy: userData.code,
            modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
            status: 1,
            procedureStatus: -1,
            payMode: "E",
            collectionStatus: null,
            paymentLinkForPatient: "N",
            discountCouponCode: null,
            patientName: userData.firstName,
            patientGender: userData?.gender,
            patientMobile: userData?.mobile,
            docName: user?.prescriptionDoc,
            patientMedicineOrderDetailsList: productDetail,
          },
        ],
      };
    }

    if (
      dataObj?.patientMedicineOrder?.length &&
      dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList.length > 0
    ) {
      let id =
        dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList.map(
          (item) => item.drugCode
        );
      id.map((item, index, self) => {
        if (self.indexOf(item) === index) {
        } else {
          dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList[
            self.indexOf(item)
          ].quantity +=
            dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList[
              index
            ].quantity;
          dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList[
            self.indexOf(item)
          ].totalAmount =
            dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList[
              self.indexOf(item)
            ].quantity *
            dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList[
              self.indexOf(item)
            ].unitPrice;
        }
      });

      let res =
        dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList.filter(
          (item, i, self) => {
            if (id.indexOf(item.drugCode) == i) {
              return item;
            }
          }
        );

      dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList = res;
    }
    dispatch(AddtoCart(dataObj)).then(async (res) => {
      message.success("Added to cart");
      trackAddToCartEvent(0, "MultipleMedicineAdded")
      if (user?.prescriptionDocArray?.length) {
        await UploadPrescriptionHelper(res, user, "key");
      }
      setAddToCartLoading(false);
      setDialog(false);
    });
  };
  
  const onPrescriptionOpenClick = (user) => {
    setPrescriptionDialog(true);
    setPrescriptionDoc(user.prescriptionDocArray);
  };
  const handleReorderClick = async (user) => {
    let productCode = [];
    productCode = user?.orderDetails.map((res) => {
      if (res.orderItems.length > 0) {
        return res.orderItems.map((code) => {
          return code.drugId;
        });
      }

      if (res.refundableAmountForItems.length > 0) {
        return res.refundableAmountForItems.map((code) => {
          return code.drugId;
        });
      }
    });

    let productStringfy = JSON.stringify(...productCode);
    let reorderItem = [];
    await dispatch(getAllProductByIds(productStringfy))
      .then((res) => {
        if (user?.orderStatus == "Refunded") {
          user?.orderDetails[0].refundableAmountForItems.forEach((item) => {
            let data = res.find((item1) => item1.id == item.drugId);
            data.quantity = item.quantity;
            data.drugName = item.drugName;
            reorderItem.push(data);
          });
        } else {
          user?.orderDetails[0].orderItems.forEach((item) => {
            let data = res.find((item1) => item1.id == item.drugId);
            data.quantity = item.quantity;
            data.drugName = item.drugName;
            reorderItem.push(data);
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setReorderData(reorderItem);
    setDialog(true);
    setCartData(user);
  };

  useEffect(() => {
    dispatch(getToken());
    // if (videoRef.current) {
    //   videoRef.current.play();
    // }
    if (Object.keys(userData).length === 0) {
      setIsModalOpen(true);
    }
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    prevArrow: <img src={PrevArrow} alt="icons" />,
    nextArrow: <img src={NextArrow} alt="icons" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const bottomTwo = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    prevArrow: <img src={PrevArrow} alt="icons" />,
    nextArrow: <img src={NextArrow} alt="icons" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const bottomOne = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    prevArrow: <img src={PrevArrow} alt="icons" />,
    nextArrow: <img src={NextArrow} alt="icons" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const healths = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    prevArrow: <img src={PrevArrow} alt="icons" />,
    nextArrow: <img src={NextArrow} alt="icons" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const { Search } = Input;
  const onSearch = (value) => console.log(value);
  const history = useHistory();

  const handleNavigation = (path) => {
    history.push(path);
    window.scrollTo(0, 0);
  };

  const showModal = () => {
    setIsModalOpen1(true);
  };
  const handleOk = () => {
    setIsModalOpen1(false);
    setPrescriptionView(false);
  };
  const handleCancel = () => {
    setIsModalOpen1(false);
    setPrescriptionView(false);
    setUserId(null);
  };

  useEffect(() => {
    if (success && showCancelDialog) {
      setShowCancelDialog(false);
    }
    if (isError && showCancelDialog) {
      setError("Something went wrong");
    }
   
  }, [success, isError]);

  const redirectToVideo = (event, location, data) => {
    event.preventDefault();
    history.push({ pathname: location, state: data });
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.scrollTo(0, 0);
    }else {
      window.scrollTo(0, 0);
    }
  };

  const getPatientAppointment = (code) => {
    let payload = {
      patientId: code,
      photoRequired: "Y",
    };
    setLoading(true);
    patientService
      .getpatientappointmentlistFilter(payload)
      .then((res) => {
        if (res.data) {
          setAppointmentlistData(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onRescheduleModelClose = () => {
    setHide(true);
    // getPatientAppointment();
    dispatch(removeUpdateSuccess());
  };

  const confirmCancelDialog = (e, val) => {
    e.preventDefault();
    setappointmentdetails(val);
    setShowCancelDialog(true);
  };

  const confirmCancelAppointment = (reason) => {
    if (reason == "") {
      setErrorMsg("Please select reason");
      return;
    }
    dispatch(deleteAppointment(appointmentdetails?.id, reason))
      .then((res) => {
        setShowCancelDialog(false);
      })
      .catch((err) => setErrorMsg(err?.response?.data?.details[0]));
  };

  const openModal = (val) => {
    setappointmentdetails(val);
    setHide(false);
  };

  const isTimeExceed = (data) => {
    let appointmentDate = new Date(
      `${data.whenAppointment} " " ${data.fromTime}`
    );
    let isAllowedToCancel = Math.floor(
      (Date.now() - appointmentDate.getTime()) / 1000 / 60
    );
    if (isAllowedToCancel >= -4) {
      return false;
    } else {
      return true;
    }
  };

  // useEffect(() => {
  //   if (initialRender && Need != "Need") {
  //     setInitialRender(false);
  //     firstContentRef.current.scrollIntoView({ behavior: "smooth" });

  //     return;
  //   }
  //   if (!initialRender || Need == "Need") {
  //     secondContentRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, []);
  const scrollToMembershipCart = () => {
    const membershipCartSection = document.getElementById("membershipCart");
    if (membershipCartSection) {
      membershipCartSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleCheckout = () => {
    handleNavigation("/services/membership/");
    setTimeout(scrollToMembershipCart, 500); // Scroll after navigating to ensure the section is loaded
  };

  const handleImgError = (e) => {
    e.target.src = doctorImage;
  };

  return (
    <>
      <ToastContainer />
     <Helmet>
        <title>{"HomePage | Curebay"}</title>
        {/* <meta name='description' content={EnglishText.HOME_META_DESCRIPTION} /> */}
        <meta name="title" content={"HomePage | Curebay"} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Layouts>
        {/* Title Content */}
        <SubHeader />
        <section className="home-banner min-h-[70vh] my-8 ">
          <div className="container mx-auto">
            <div className="bg-primaryColor absolute left-0 md:w-[70%] h-[70vh] rounded-e-xl"></div>
            <div className="md:grid grid-cols-2 gap-5 min-h-[70vh] items-center">
              <div className="relative z-1 pt-7 md:pt-5 p-5 md:p-0 rounded-2xl bg-[#F3F8FF] md:bg-primaryColor">
                <span className="text-secondaryColor text-sm md:text-3xl font-medium  mb-1 md:mb-3">
                  Bridging Healthcare Gaps
                </span>
                <h1 className="text-primaryColor md:text-White text-2xl md:text-6xl font-bold mb-5">
                  Transforming Lives
                </h1>
                <p className="text-primaryColor md:text-White text-base md:text-lg font-medium text-lg">
                  Welcome to CureBay.
                </p>
                <p className="text-primaryColor md:text-White font-extralight text-sm md:text-base mt-2">
                  We at CureBay revolutionise healthcare with our innovative
                  hybrid approach, reaching remote and medically underserved
                  locations. Our network of world-class eClinics powered by an
                  intelligent tech platform and operated by trained and
                  certified health workers delivers quality healthcare services
                  in an assisted mode. Our mission goes beyond mere
                  connectivity; making quality & reliable healthcare accessible
                  and affordable to all.
                </p>
                <div className="flex items-center gap-3 my-7">
                  <a
                    href="#allservice"
                    className="bg-primaryColor md:bg-White text-White md:text-primaryColor text-xs md:text-sm font-semibold py-2.5 px-3 md:px-5 rounded-full md:rounded"
                    // onClick={handleScroll}
                  >
                    See Our Services
                  </a>
                  <a
                    href="#Contactus"
                    className="bg-White text-primaryColor border-primaryColor border md:border-0 text-xs md:text-sm font-semibold py-2.5 px-3 md:px-5 rounded-full md:rounded"
                  >
                    Contact Us
                  </a>
                </div>
                <div className="hidden md:flex items-center gap-3">
                  <img src={TrustIcon} alt="images" width={25} />
                  <p className="text-White font-extralight text-sm">
                    Trusted by{" "}
                    <span className="font-medium">Over 100,000 Patients</span>
                  </p>
                </div>
              </div>
              {/* <div className="relative z-1 flex justify-end mt-5 md:mt-0">
                              <div className="w-full md:w-[90%] h-[300px] md:h-[350px] rounded banner-bg"></div>
                          </div> */}
              <div className="z-1 relative flex justify-end ">
                <div className="mt-10 md:mt-0 md:w-[90%]">
                  <div className="relative z-[10]">
                    <video
                      className="w-full rounded-[20px] "
                      autoPlay
                      loop
                      muted
                      preload="auto"
                      playsInline
                      controls
                      // ref={videoRef}
                      poster={VideoImage}
                    >
                      <source
                        src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/CurebayVideo.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                  <div className="absolute top-10 md:top-0 z-1">
                    <img
                      src={VideoImage}
                      alt="images"
                      className="rounded-3xl"
                    />
                  </div>
                </div>
              </div>
              <div className="flex md:hidden justify-center items-center gap-3 mt-5">
                <img src={TrustIcon1} alt="images" width={25} />
                <p className="text-primaryColor font-extralight text-sm">
                  Trusted by{" "}
                  <span className="font-medium">Over 100,000 Patients</span>
                </p>
              </div>
            </div>
            {/* <div className="swipicon">
                          <img src={Swipicon} alt="images" />
                      </div> */}
          </div>
        </section>
        <section className="ai-container"> 
          <div className="flex sm:flex-col flex-row sm:justify-center " >
            <div className="sm:px-0 px-4">  
          <h4 className="ai-heading" style={{color:"#ffff"}} ><p className='sm:text-4xl text-lg' style={{color:"#42BA85"}} >CureBay Quantum</p>Empower Your Health With AI Ecosystem.</h4>
          </div>
          <div className="flex sm:justify-start justify-end sm:mt-0 mt-[-30px]">
            
          <img className="hidden sm:block" src= {QUANTUMHEROIMAGE} alt='img'/>
          <img className="block sm:hidden h-[181px] w-[281px]" src={Mobileai} alt="img" />

          </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 px-4 sm:px-0 sm:gap-8 sm:pr-20 ai-service-info-container" >
            <div className="ai-service-info">
              <div className='relative z-10' >
              <img className = "ai-service-image" src = {careSathiMain} />
              </div>
              <div className="px-2 py-2 z-50 sm:h-[140px] h-[125px]" style={{backgroundColor:"#FFFFFF", borderBottomRightRadius: 21, borderBottomLeftRadius: 12}}>
              <p className=" sm:text-xl text-base font-bold mb-2 mt-3" style={{color:"#005D8D"}} >CareSathi</p>
              <p className=" sm:text-base text-sm sm:font-normal mb-2" style={{color:"#676C80"}} >Your trusted friend in healing, always by your side</p>
              <a style={{color:"#42BA85"}} className=" sm:text-base text-sm sm:font-normal mb-4" href="/care-sathi" >Know More <RightOutlined className="pl-2" /> </a>
              </div> 
            </div>
            <div className="ai-service-info">
              <div className='relative z-10' >
              <img className = "ai-service-image" src = {LABREPORTANALYSERIMAGE} />
              </div>
              <div className="px-2 py-2 z-50 sm:h-[140px] h-[125px] " style={{backgroundColor:"#FFFFFF", borderBottomRightRadius: 21, borderBottomLeftRadius: 12}}>
              <p className=" sm:text-xl text-base sm:font-semibold mb-2 mt-3" style={{color:"#005D8D"}} >LabInsights</p>
              <p className=" sm:text-base text-sm sm:font-normal mb-2" style={{color:"#676C80"}} >From uncertainty to clarity, we are with you</p>
              <a style={{color:"#42BA85"}} className=" sm:text-base text-sm sm:font-normal mb-4"  href="/lab-insight" >Know More <RightOutlined className="pl-2" /> </a>
              </div> 
            </div>
            <div className="ai-service-info"  >
            <div className='relative z-10' >
              <img className = "ai-service-image" src = {DENTISTRYIMAGE} />
              </div>
              <div className="px-2 py-2 z-50 sm:h-[140px] h-[125px]" style={{backgroundColor:"#FFFFFF", borderBottomRightRadius: 21, borderBottomLeftRadius: 12}}>
              <p className=" sm:text-xl text-base sm:font-semibold mb-2 mt-3" style={{color:"#004171"}} >DentalCare</p>
              <p className=" sm:text-base text-sm sm:font-normal mb-2" style={{color:"#676C80"}} >Transform your smile with technology</p>
              <a style={{color:"#42BA85"}} className=" sm:text-base text-sm sm:font-normal mb-4" href="/services/dentalcare" >Know More <RightOutlined className="pl-2" /> </a>
              </div> 
            </div>
            <div className="ai-service-info"  >
            <div className='relative z-10' >
              <img className = "ai-service-image" src = {AISMARTVITALSIMAGE} />
              </div>
              <div className="px-2 py-2 z-50 sm:h-[140px] h-[125px]" style={{backgroundColor:"#FFFFFF", borderBottomRightRadius: 21, borderBottomLeftRadius: 12}}>
              <p className=" sm:text-xl text-base sm:font-semibold mb-2 mt-3" style={{color:"#004171"}} >SmartVitals</p>
              <p className=" sm:text-base text-sm sm:font-normal mb-2" style={{color:"#676C80"}} >A caring touch without the touch</p>
              <a style={{color:"#42BA85"}} className=" sm:text-base text-sm sm:font-normal mb-4" href="/smart-vitals-detail" >Know More <RightOutlined className="pl-2" /> </a>
              </div> 
            </div>
          </div>
        </section>
        {Object.keys(userData).length !== 0 && (
          <section className="bg-White">
            <div className="container mx-auto py-[50px] md:py-[70px]">
              <div className="md:w-[80%]  p-8  mx-auto bg-secondaryColor rounded-xl md:flex relative">
                <div className="md:w-[60%] ">
                  <div className="text-White text-2xl font-semibold mb-3">
                    Check Your Medical Summary
                  </div>
                  <p className="text-White text-base font-light mb-5">
                    Access your medical information from anywhere securely with
                    an easily accessible format. Concise summary of your medical
                    history, vitals, e-prescriptions, lab reports and
                    medications empowers you to actively engage in managing your
                    health.
                  </p>
                  <button
                    onClick={() =>handleNavigation("/medical-summary")}
                    // href="#Contactus"
                    className="bg-primaryColor hover:bg-White text-White hover:text-secondaryColor text-xs md:text-sm font-medium py-2.5 px-5 rounded-md"
                  >
                    Medical Summary
                  </button>
                </div>
                <div className="mt-5 md:mt-0 md:w-[40%] md:h-[200px] flex justify-end">
                  <img
                    src={MedicalSummaryImage1}
                    alt="images"
                    className="rounded-md h-full"
                  />
                </div>
              </div>
            </div>
          </section>
        )}
        {/* My Family */}
        {Object.keys(userData).length !== 0 && (
          <section className="bg-primaryColor ">
            <div className="container mx-auto py-[50px] md:py-[90px]">
              <h2 className="text-center text-2xl md:text-4xl font-bold text-White leading-normal mb-[30px] md:mb-[50px]">
                My <span className="text-secondaryColor">Family</span>
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-3 md:px-12">
                {FamilymembersData.map((user, i) => (
                  <div className="bg-White rounded-lg p-4">
                    <div className="flex gap-3 items-center justify-between">
                      <div className="flex gap-3 items-center">
                        <img
                          src={
                            user.photoName
                              ? process.env.REACT_APP_IMG_BASEURL +
                                user.photoName
                              : user.gender === "M"
                              ? male_icon
                              : female_icon
                          }
                          alt={user.name}
                          className="rounded-full w-[50px] "
                        />
                        <div className="">
                          <h5 className="text-primaryColor text-base font-medium">
                            {user.name}
                          </h5>
                          <p className="text-textGray text-sm font-light">
                            Blood Group :<span>{user.bloodGroup}</span>
                          </p>
                        </div>
                      </div>
                      <div className="border bg-lightGreen px-2 py-1 rounded-full border-secondaryColor text-secondaryColor text-xs">
                        {user.relation}
                      </div>
                    </div>
                  </div>
                ))}
                {/* <div className="bg-White rounded-lg p-4">
                  <div className="flex gap-3 items-center justify-between">
                    <div className="flex gap-3 items-center">
                      <img
                        src={DummyImage}
                        alt="images"
                        className="rounded-full w-[50px] "
                      />
                      <div className="">
                        <h5 className="text-primaryColor text-base font-medium">
                          Kevin Srikanth
                        </h5>
                        <p className="text-textGray text-sm font-light">
                          Blood Group
                        </p>
                      </div>
                    </div>
                    <div className="border bg-lightBlue2 px-2 py-1 rounded-full border-primaryColor text-primaryColor text-xs">
                      Son
                    </div>
                  </div>
                </div>
                <div className="bg-White rounded-lg p-4">
                  <div className="flex gap-3 items-center justify-between">
                    <div className="flex gap-3 items-center">
                      <img
                        src={DummyImage}
                        alt="images"
                        className="rounded-full w-[50px] "
                      />
                      <div className="">
                        <h5 className="text-primaryColor text-base font-medium">
                          Kevin Srikanth
                        </h5>
                        <p className="text-textGray text-sm font-light">
                          Blood Group
                        </p>
                      </div>
                    </div>
                    <div className="border bg-lightOrange px-2 py-1 rounded-full border-orangeColor text-orangeColor text-xs">
                      Daughter
                    </div>
                  </div>
                </div>
                <div className="bg-White rounded-lg p-4">
                  <div className="flex gap-3 items-center justify-between">
                    <div className="flex gap-3 items-center">
                      <img
                        src={DummyImage}
                        alt="images"
                        className="rounded-full w-[50px] "
                      />
                      <div className="">
                        <h5 className="text-primaryColor text-base font-medium">
                          Kevin Srikanth
                        </h5>
                        <p className="text-textGray text-sm font-light">
                          Blood Group
                        </p>
                      </div>
                    </div>
                    <div className="border bg-lightGreen px-2 py-1 rounded-full border-secondaryColor text-secondaryColor text-xs">
                      Father
                    </div>
                  </div>
                </div>
                <div className="bg-White rounded-lg p-4">
                  <div className="flex gap-3 items-center justify-between">
                    <div className="flex gap-3 items-center">
                      <img
                        src={DummyImage}
                        alt="images"
                        className="rounded-full w-[50px] "
                      />
                      <div className="">
                        <h5 className="text-primaryColor text-base font-medium">
                          Kevin Srikanth
                        </h5>
                        <p className="text-textGray text-sm font-light">
                          Blood Group
                        </p>
                      </div>
                    </div>
                    <div className="border bg-lightGreen px-2 py-1 rounded-full border-secondaryColor text-secondaryColor text-xs">
                      Father
                    </div>
                  </div>
                </div>
                <div className="bg-White rounded-lg p-4">
                  <div className="flex gap-3 items-center justify-between">
                    <div className="flex gap-3 items-center">
                      <img
                        src={DummyImage}
                        alt="images"
                        className="rounded-full w-[50px] "
                      />
                      <div className="">
                        <h5 className="text-primaryColor text-base font-medium">
                          Kevin Srikanth
                        </h5>
                        <p className="text-textGray text-sm font-light">
                          Blood Group
                        </p>
                      </div>
                    </div>
                    <div className="border bg-lightBlue2 px-2 py-1 rounded-full border-primaryColor text-primaryColor text-xs">
                      Son
                    </div>
                  </div>
                </div>
                <div className="bg-White rounded-lg p-4">
                  <div className="flex gap-3 items-center justify-between">
                    <div className="flex gap-3 items-center">
                      <img
                        src={DummyImage}
                        alt="images"
                        className="rounded-full w-[50px] "
                      />
                      <div className="">
                        <h5 className="text-primaryColor text-base font-medium">
                          Kevin Srikanth
                        </h5>
                        <p className="text-textGray text-sm font-light">
                          Blood Group
                        </p>
                      </div>
                    </div>
                    <div className="border bg-lightOrange px-2 py-1 rounded-full border-orangeColor text-orangeColor text-xs">
                      Daughter
                    </div>
                  </div>
                </div>
                <div className="bg-White rounded-lg p-4">
                  <div className="flex gap-3 items-center justify-between">
                    <div className="flex gap-3 items-center">
                      <img
                        src={DummyImage}
                        alt="images"
                        className="rounded-full w-[50px] "
                      />
                      <div className="">
                        <h5 className="text-primaryColor text-base font-medium">
                          Kevin Srikanth
                        </h5>
                        <p className="text-textGray text-sm font-light">
                          Blood Group
                        </p>
                      </div>
                    </div>
                    <div className="border bg-lightGreen px-2 py-1 rounded-full border-secondaryColor text-secondaryColor text-xs">
                      Father
                    </div>
                  </div>
                </div>
                <div className="bg-White rounded-lg p-4">
                  <div className="flex gap-3 items-center justify-between">
                    <div className="flex gap-3 items-center">
                      <img
                        src={DummyImage}
                        alt="images"
                        className="rounded-full w-[50px] "
                      />
                      <div className="">
                        <h5 className="text-primaryColor text-base font-medium">
                          Kevin Srikanth
                        </h5>
                        <p className="text-textGray text-sm font-light">
                          Blood Group
                        </p>
                      </div>
                    </div>
                    <div className="border bg-lightBlue2 px-2 py-1 rounded-full border-primaryColor text-primaryColor text-xs">
                      Son
                    </div>
                  </div>
                </div> */}
              </div>
              <button
                onClick={(e) => editmembers(e, "")}
                className="bg-secondaryColor text-White text-sm rounded-lg py-3 px-6 flex justify-center mx-auto mt-10"
              >
                + Add a family member
              </button>
            </div>
          </section>
        )}

        {/* Appointment / Order */}
        {Object.keys(userData).length !== 0 && (
          <section className="bg-White ">
            <div className="container mx-auto py-[50px] md:py-[90px]">
              <h2 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-[30px] md:mb-[50px]">
                Your <span className="text-secondaryColor">Appointments</span>
              </h2>
              <div className="w-full md:flex justify-evenly items-center gap-3">
                <div className="md:w-[33%]">
                  <div className="bg-White p-5 rounded-lg common-shadow min-h-[14rem]">
                    <div className="flex justify-between items-center mb-5">
                      <h4 className="text-lg text-primaryColor font-medium">
                        Doctor Appointments
                      </h4>
                    </div>
                    <div className="overflow-y-scroll h-[14rem] scrollWidth">
                      {appointmentlistData &&
                        appointmentlistData.map((user, i) => (
                          <div className="bg-lightBlue rounded-lg p-4 my-2">
                            <div className="">
                              <div className="flex gap-3 items-center justify-between">
                                <div className="flex gap-3 items-center">
                                  <img
                                    src={
                                      user.userPhotoName
                                        ? `${process.env.REACT_APP_IMG_BASEURL}${user.userPhotoName}`
                                        : doctorImage
                                    }
                                    alt={user.userName}
                                    className="rounded-full w-[45px]"
                                    onError={handleImgError}
                                    style={{
                                      clipPath: "circle(50% at 50% 50%)",
                                    }}
                                  />
                                  <div className="">
                                    <h5 className="text-primaryColor text-base font-medium">
                                      {user.userName}
                                    </h5>
                                    <h6 className="text-secondaryColor text-sm font-medium">
                                      {user.patientName}
                                    </h6>
                                    <div>
                                      {user.status == 8 ? (
                                        ""
                                      ) : user.status == 2 ? (
                                        ""
                                      ) : user.status == 3 ? (
                                        ""
                                      ) : user.status == 4 ? (
                                        ""
                                      ) : user.status == 5 ? (
                                        ""
                                      ) : user.status == 1 ? (
                                        <span className="flex gap-1  ">
                                          {user.consultationsType === "V" && (
                                            <div>
                                              <img
                                                onClick={(e) =>
                                                  redirectToVideo(
                                                    e,
                                                    APP_ROUTES.VIDEO_CALL,
                                                    user
                                                  )
                                                }
                                                src={videoIcon}
                                                alt="img"
                                                className="h-9 w-9 cursor-pointer"
                                              />
                                              <span className="text-primaryColor cursor-pointer text-sm">
                                                Join
                                              </span>
                                            </div>
                                          )}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex justify-end items-end flex-col">
                                  <div className="flex gap-2">
                                    <img
                                      src={Calendar1}
                                      alt="images"
                                      className="w-[12px]"
                                    />
                                    <p className="text-textGray text-xs ">
                                      {getLocalTime(user.whenAppointment)}
                                    </p>
                                  </div>
                                  <div className="flex gap-2 mt-3">
                                    <img
                                      src={ClockImage}
                                      alt="images"
                                      className="w-[15px]"
                                    />
                                    <p className="text-textGray text-xs ">
                                      {user.fromTime}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="flex justify-between items-center mt-2">
                                {/* {user.status == 8 ? (
                             ""
                           ) : user.status == 2 ? (
                             ""
                           ) : user.status == 3 ? (
                             ""
                           ) : user.status == 4 ? (
                             ""
                           ) : user.status == 5 ? (
                             ""
                           ) : user.status == 1 ? (
                             <div>
                               {user.consultationsType === "V" && (
                                 <div
                                   onClick={(e) =>
                                     redirectToVideo(
                                       e,
                                       APP_ROUTES.VIDEO_CALL,
                                       user
                                     )
                                   }
                                   className="text-[#005D8D] underline text-sm cursor-pointer"
                                 >
                                   <p className="text-primaryColor mr-4 underline cursor-pointer text-sm">
                                     Join Call
                                   </p>
                                 </div>
                               )}
                             </div>
                           ) : (
                             ""
                           )} */}
                                {user?.isConfirm !== "1" &&
                                  user.status === 1 && (
                                    <button
                                      onClick={() => openModal(user)}
                                      disabled={user.status !== 1}
                                      className="w-auto text-left md:w-1/3 md:mr-2 disabled:opacity-50 text-brand-secondary hover:text-brand-secondary"
                                    >
                                      <p className="text-textGray underline cursor-pointer text-sm">
                                        Reschedule
                                      </p>
                                    </button>
                                  )}
                                <div className="text-[#005D8D] underline text-sm cursor-pointer">
                                  {user.status === 2 && user.status !== 1 && (
                                    <button
                                      key={user.id}
                                      onClick={() =>
                                        // history.push({
                                        //   pathname: APP_ROUTES.POST_CONSULTATION,
                                        //   search: `?id=${user.id}`,
                                        // })
                                        handlePrescription(user.id)
                                      }
                                      disabled={user.status !== 2}
                                      className="w-auto text-left md:w-1/3 md:mr-2 disabled:opacity-50 text-brand-secondary hover:text-brand-secondary"
                                    >
                                      Prescription
                                    </button>
                                  )}
                                </div>
                                {user?.isConfirm !== "1" &&
                                  user.status === 1 && (
                                    <button
                                      onClick={(e) =>
                                        confirmCancelDialog(e, user)
                                      }
                                      className="w-auto text-left md:w-1/3 md:mr-2 text-brand-secondary disabled:opacity-50 hover:text-brand-secondary"
                                      disabled={
                                        user.status !== 1 || !isTimeExceed(user)
                                      }
                                      style={{ width: "max-content" }}
                                    >
                                      <p
                                        style={{ width: "max-content" }}
                                        className="text-danger underline cursor-pointer text-sm"
                                      >
                                        Cancel Appointment
                                      </p>
                                    </button>
                                  )}
                              </div>
                            </div>
                          </div>
                        ))}
                      {appointmentlistData.length === 0 ? (
                        <div class=" w-full flex gap-5 flex-col justify-center min-h-[30vh] items-center text-center ">
                          <div className="">
                            <img
                              src={NodataImage}
                              alt="image"
                              className="w-[40px]"
                            />
                          </div>
                          <p className="text-center font-light item-center text-sm text-textGray">
                            No Appointments
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="md:w-[33%]">
                  <div className="bg-White p-5 rounded-lg common-shadow min-h-[14rem]">
                    <div className="flex justify-between items-center mb-3">
                      <h4 className="text-lg text-primaryColor font-medium mb-2">
                        Medicine Orders
                      </h4>
                    </div>
                    <div className="h-[14rem] overflow-y-scroll scrollWidth">
                      {medicineList?.length > 0 &&
                        medicineList
                          .sort((a, b) => {
                            return (
                              moment(b.createdOn, "yyyy-MM-DD HH:mm:ss") -
                              moment(a.createdOn, "yyyy-MM-DD HH:mm:ss")
                            );
                          })
                          .map((user, i) => (
                            <div className="w-full rounded-md p-4 my-2 bg-lightBlue">
                              <div className="flex justify-between gap-3">
                                {/* <div className=" text-right text-primaryColor text-sm md:text-base font-medium mt-1">
                         Number of items :{" "}
                         <span className="text-secondaryColor font-medium">
                           {user.totalOrderItems}
                         </span>
                       </div> */}
                                {/* <div className=" text-right text-primaryColor text-sm md:text-base font-medium mt-1">
                         <span className="text-secondaryColor font-medium">
                           Rs. {user.orderAmount.toFixed(2)}
                         </span>
                       </div> */}
                              </div>
                              <div className="flex justify-between text-textGray w-full gap-3 mt-4">
                                <div className="text-textGray text-xs md:text-sm">
                                  <label className="font-light">Order ID</label>
                                  <p className="font-medium mt-1">
                                    {user.patientOrderId}
                                  </p>
                                </div>
                                <div className="text-textGray text-xs md:text-sm">
                                  <label className="font-light">
                                    Placed On
                                  </label>
                                  <p
                                    style={{ width: "max-content" }}
                                    className="font-medium mt-1"
                                  >
                                    {user?.paymentDateTime?.substring(0, 10)}
                                  </p>
                                </div>
                                <div className="text-textGray text-xs md:text-sm">
                                  <label className="font-light">
                                    Schedule Delivery
                                  </label>
                                  <p className="font-medium mt-1">
                                    {user?.scheduledDelivery?.substring(0, 10)}
                                  </p>
                                </div>
                              </div>
                              <div className="flex gap-3">
                                <div className="flex gap-8 items-center font-medium mt-4">
                                  {user.prescriptionDocArray &&
                                    user.prescriptionDocArray.length > 0 &&
                                    user.prescriptionDocArray[0] !== "" && (
                                      <div
                                        onClick={() =>
                                          onPrescriptionOpenClick(user)
                                        }
                                        className="text-textGray underline text-xs md:text-sm cursor-pointer"
                                      >
                                        View Prescription
                                      </div>
                                    )}
                                  <div
                                    onClick={() => showModal1(user)}
                                    className="text-[#005D8D] underline text-xs md:text-sm cursor-pointer"
                                  >
                                    View Details
                                  </div>
                                  <div
                                    onClick={(e) => handleReorderClick(user)}
                                    className="text-secondaryColor underline text-xs md:text-sm cursor-pointer"
                                  >
                                    Reorder
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      {medicineList?.length == 0 && (
                        <div class=" w-full flex gap-5 flex-col justify-center min-h-[30vh] items-center text-center ">
                          <div className="">
                            <img
                              src={NodataImage}
                              alt="image"
                              className="w-[40px]"
                            />
                          </div>
                          <p className="text-center font-light item-center text-sm text-textGray">
                            No Medicine Orders
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="md:w-[33%]">
                  <div className="bg-White p-5 rounded-lg common-shadow min-h-[14rem]">
                    <div className="flex justify-between items-center mb-3">
                      <h4 className="text-lg text-primaryColor font-medium mb-2">
                        Diagnostics Appointments
                      </h4>
                    </div>
                    <div className="h-[14rem] overflow-y-scroll scrollWidth">
                      {list?.length > 0 &&
                        list
                          .sort((a, b) => {
                            return (
                              moment(b.createdOn, "yyyy-MM-DD HH:mm:ss") -
                              moment(a.createdOn, "yyyy-MM-DD HH:mm:ss")
                            );
                          })
                          .map((user, i) => (
                            <div className="w-full rounded-md p-4 my-2 bg-lightBlue mb-5">
                              <div className=" flex gap-5">
                                {/* <div className="w-[20%] h-full hidden md:block">
                         <img
                           src={
                             user.hospitalPhoto
                               ? process.env.REACT_APP_IMG_BASEURL +
                                 user.hospitalPhoto
                               : LabImage
                           }
                           alt="img"
                           className="h-[100px] w-full rounded-sm shadow-lg"
                         />
                       </div> */}
                                <div className="w-full">
                                  <div className="flex justify-between items-center">
                                    <div className="text-primaryColor font-semibold text-base md:text-lg w-full">
                                      {
                                        user?.patientLabTestsOrderDetailsList[0]
                                          ?.hospitalName
                                      }
                                    </div>
                                    <div className="text-secondaryColor bg-lightGreen px-3 py-1.5 rounded-full text-xs cursor-pointer">
                                      {user.procedureStatusName}
                                    </div>
                                  </div>
                                  <div className="flex text-textGray w-full justify-between gap-5 mt-4">
                                    <div className="text-textGray text-xs md:text-sm">
                                      <label className="font-light">
                                        Order ID
                                      </label>
                                      <p className="font-medium mt-1">
                                        {user.orderId}
                                      </p>
                                    </div>
                                    <div className="text-textGray text-xs md:text-sm">
                                      <label className="font-light">
                                        Placed On
                                      </label>
                                      <p className="font-medium mt-1">
                                        {moment(user?.paymentDateTime).format(
                                          "MM/DD/YYYY"
                                        )}
                                      </p>
                                    </div>
                                    <div className="text-textGray text-xs md:text-sm">
                                      <label className="font-light">
                                        Preferred Date
                                      </label>
                                      <p className="font-medium mt-1">
                                        {moment(user?.preferDateTime).format(
                                          "MM/DD/YYYY"
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {user.patientLabTestsOrderDetailsList.map(
                                (user1, i) => (
                                  <div className="flex justify-between gap-5 mt-3">
                                    <div className="md:w-[75%] text-textGray text-xs md:text-sm font-medium mt-1">
                                      <span>{user1?.labTestName}</span>
                                    </div>
                                    <div className="w-[40%] md:w-[25%] text-right text-textGray text-sm font-light mt-1">
                                      <span className="text-secondaryColor font-medium text-base md:text-lg">
                                        ₹{" "}
                                        {parseFloat(user1?.discountAmount)
                                          ? user1?.discountAmount
                                          : user1?.amount}
                                      </span>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          ))}
                      {list?.length == 0 && (
                        <div class=" w-full flex gap-5 flex-col justify-center min-h-[30vh] items-center text-center ">
                          <div className="">
                            <img
                              src={NodataImage}
                              alt="image"
                              className="w-[40px]"
                            />
                          </div>
                          <p className="text-center font-light item-center text-sm text-textGray">
                            No Diagnosics Appointments
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {/* HealthCare Disparities */}
        <section className="bg-lightGray">
          <div className="container mx-auto py-[50px] md:py-[90px]">
            <h2 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-[30px] md:mb-[50px]">
              Overcoming India’s <br />{" "}
              <span className="text-secondaryColor">
                Healthcare Disparities
              </span>
            </h2>
            <div className="hidden md:flex justify-around gap-5">
              <div className="bg-White py-4 sectionTwo relative rounded-xl md:w-[420px] mb-5">
                <h4 className="text-2xl px-6 text-primaryColor font-bold mt-10 mb-5 w-[75%]">
                  Urban bias in{" "}
                  <span className="text-secondaryColor">
                    Medical Healthcare
                  </span>
                </h4>
                <div className="relative">
                  <div className="flex justify-end">
                    <img src={Progress1} alt="images" className="w-[45%]" />
                  </div>
                  <div className="absolute top-0 left-[20px] h-[300px] flex justify-around flex-col  w-[55%]">
                    <div className="progressOne relative">
                      <div className="text-primaryColor text-5xl font-semibold mb-3">
                        74%
                      </div>
                      <p className="text-textGray">
                        of doctors are located in urban areas
                      </p>
                    </div>
                    <div className="progressTwo relative">
                      <div className="text-secondaryColor text-5xl font-semibold mb-3">
                        28%
                      </div>
                      <p className="text-textGray">
                        They serve only 28% of the population served
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-White py-4 sectionTwo relative rounded-xl md:w-[420px] mb-5">
                <h4 className="text-2xl px-6 text-primaryColor font-bold mt-10 mb-5 w-[75%]">
                  <span className="text-secondaryColor">Urban-Rural</span>{" "}
                  Healthcare Gap
                </h4>
                <div className="relative">
                  <div className="flex justify-end">
                    <img src={Progress2} alt="images" className="w-[45%]" />
                  </div>
                  <div className="absolute top-0 left-[20px] h-[300px] flex justify-around flex-col w-[55%]">
                    <div className=" progressOne relative">
                      <div className="text-primaryColor text-5xl font-semibold mb-3">
                        65%
                      </div>
                      <p className="text-textGray">
                        of the rural population lacks fundamental healthcare.
                      </p>
                    </div>
                    <div className="progressTwo relative">
                      <div className="text-secondaryColor text-5xl font-semibold mb-3">
                        75%
                      </div>
                      <p className="text-textGray">
                        resources concentrated in urban areas for only 30% of
                        the populace.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-White py-4 sectionTwo relative rounded-xl md:w-[420px] mb-5">
                <h4 className="text-2xl px-6 text-primaryColor font-bold mt-10 mb-5 w-[75%]">
                  <span className="text-secondaryColor">Patient Journey</span>{" "}
                  in Peril
                </h4>
                <div className="relative px-6">
                  <div className="bg-primaryColor rounded-lg text-White p-3 mb-5 text-base">
                    Broken patient journey with fragmented local care.
                  </div>
                  <div className="bg-lightBlue rounded-lg p-3 mb-5 text-base text-primaryColor">
                    Extended journeys, pharmacy struggles, and delayed lab tests
                    hamper patient-centric care.
                  </div>
                  <div className="bg-primaryColor rounded-lg text-White p-3 text-base">
                    Healthcare intermediaries needed for distant city hospital
                    admissions.
                  </div>
                </div>
              </div>
            </div>
            <div className="block md:hidden">
              <Slider {...healths}>
                <div>
                  <div className="bg-White py-4 sectionTwo relative rounded-xl md:w-[420px] mb-5">
                    <h4 className="text-2xl px-6 text-primaryColor font-bold mt-10 mb-5 md:w-[75%] relative z-10">
                      Urban bias in{" "}
                      <span className="text-secondaryColor">
                        Medical Healthcare
                      </span>
                    </h4>
                    <div className="relative z-10">
                      <div className="flex justify-end">
                        <img src={Progress1} alt="images" className="w-[45%]" />
                      </div>
                      <div className="absolute top-0 left-[20px] h-[300px] flex justify-around flex-col  w-[55%]">
                        <div className="progressOne relative">
                          <div className="text-primaryColor text-3xl md:text-5xl font-semibold mb-3">
                            74%
                          </div>
                          <p className="text-textGray text-sm md:text-base">
                            of doctors located in urban areas
                          </p>
                        </div>
                        <div className="progressTwo relative">
                          <div className="text-secondaryColor text-3xl md:text-5xl font-semibold mb-3">
                            28%
                          </div>
                          <p className="text-textGray text-sm md:text-base">
                            Serving only 28% of the population
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="bg-White py-4 sectionTwo relative rounded-xl md:w-[420px] mb-5">
                    <h4 className="text-2xl px-6 text-primaryColor font-bold mt-10 mb-5 md:w-[75%] relative z-10">
                      <span className="text-secondaryColor">
                        Patient Journey
                      </span>{" "}
                      in Peril
                    </h4>
                    <div className="relative px-6 z-10">
                      <div className="bg-primaryColor rounded-lg text-White p-3 mb-5 text-sm md:text-base">
                        Broken patient journey with fragmented local care.
                      </div>
                      <div className="bg-lightBlue rounded-lg p-3 mb-5 text-sm md:text-base text-primaryColor">
                        Extended journeys, pharmacy struggles, and delayed lab
                        tests hamper patient-centric care.
                      </div>
                      <div className="bg-primaryColor rounded-lg text-White p-3 text-sm md:text-base">
                        Healthcare intermediaries needed for distant city
                        hospital admissions.
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="bg-White py-4 sectionTwo relative rounded-xl md:w-[420px] mb-5">
                    <h4 className="text-2xl px-6 text-primaryColor font-bold mt-10 mb-5 md:w-[75%] relative z-10">
                      <span className="text-secondaryColor">Urban-Rural</span>{" "}
                      Healthcare Gap
                    </h4>
                    <div className="relative z-10">
                      <div className="flex justify-end">
                        <img src={Progress2} alt="images" className="w-[45%]" />
                      </div>
                      <div className="absolute top-0 left-[20px] h-[300px] flex justify-around flex-col w-[55%]">
                        <div className=" progressOne relative">
                          <div className="text-primaryColor text-3xl md:text-5xl font-semibold mb-3">
                            65%
                          </div>
                          <p className="text-textGray">
                            rural population lacks fundamental healthcare.
                          </p>
                        </div>
                        <div className="progressTwo relative">
                          <div className="text-secondaryColor text-3xl md:text-5xl font-semibold mb-3">
                            75%
                          </div>
                          <p className="text-textGray">
                            resources concentrated in urban areas for only 30%
                            of the populace.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </section>
        {/* HealthCare response */}
        <section className="bg-primaryColor">
          <div className="container mx-auto py-[50px] md:py-[90px] ">
            <h2 className="text-center text-2xl md:text-4xl font-bold text-White leading-normal mb-[50px]">
              Healthcare's Call <br />{" "}
              <span className="text-secondaryColor">Our Response</span>
            </h2>
            <Slider {...settings}>
              <div>
                <div className="md:flex w-full relative sm:rounded-l-2xl rounded-r-2xl">
                  <div className="md:absolute md:w-[60%] h-full sm:rounded-l-2xl rounded-r-2xl">
                    <img
                      src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/our_eclinic.png"
                      alt="images"
                      className="w-full h-full object-cover sm:rounded-l-2xl rounded-r-2xl"
                    />
                  </div>
                  <div className="w-full  flex justify-end z-10">
                    <div className="bg-White md:w-[45%] md:min-h-[350px]  px-[30px] py-[30px] md:py-[50px] sm:rounded-r-2xl rounded-b-2xl">
                      <h6 className="text-xl md:text-2xl font-bold text-primaryColor leading-normal mb-3">
                        Our{" "}
                        <span className="text-secondaryColor">
                          {" "}
                          Hybrid eClinics
                        </span>
                      </h6>
                      <p className="text-textGray text-sm md:text-lg">
                        Strategically located in remote areas, our eClinics
                        blend advanced medical facilities with traditional
                        healthcare, providing check-ups, health education,
                        online and in-person consultations. Skilled nurses and
                        pharmacists form our rural healthcare backbone, ensuring
                        specialised care and improved well-being.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="md:flex w-full relative">
                  <div className="md:absolute md:w-[60%] h-full sm:rounded-l-2xl rounded-r-2xl">
                    <img
                      src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/Network_healthcare.png"
                      alt="images"
                      className="w-full h-full object-cover sm:rounded-l-2xl rounded-r-2xl"
                    />
                  </div>
                  <div className="w-full  flex justify-end z-10">
                    <div className="bg-White md:w-[45%] md:min-h-[350px] px-[30px] py-[30px] md:py-[50px] sm:rounded-r-2xl rounded-b-2xl">
                      <h6 className="text-xl md:text-2xl font-bold text-secondaryColor leading-normal mb-3">
                        Network of{" "}
                        <span className="text-primaryColor">Healthcare</span>{" "}
                        Providers
                      </h6>
                      <p className="text-textGray text-sm md:text-lg ">
                        Our network of healthcare providers, including skilled
                        and compassionate professionals from top cities, is the
                        cornerstone of our services. They ensure optimal medical
                        attention in rural areas, guiding patients through
                        diagnoses, treatments, and supporting their healthcare
                        journeys.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="md:flex w-full relative">
                  <div className="md:absolute md:w-[60%] h-full sm:rounded-l-2xl rounded-r-2xl">
                    <img
                      src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/Swasthya_Mitra.png"
                      alt="images"
                      className="w-full h-full object-cover sm:rounded-l-2xl rounded-r-2xl"
                    />
                  </div>
                  <div className="w-full  flex justify-end z-10">
                    <div className="bg-White md:w-[45%] md:min-h-[350px] px-[30px] py-[30px] md:py-[50px] sm:rounded-r-2xl rounded-b-2xl">
                      <h6 className="text-xl md:text-2xl font-bold text-primaryColor leading-normal mb-3">
                        Swasthya{" "}
                        <span className="text-secondaryColor">Mitras</span>
                      </h6>
                      <p className="text-textGray text-sm md:text-lg " id={window.innerWidth < 768? "allservice" : "normalId"}>
                        {/* Trained in tier-2 or tier-3 cities, our Swasthya
                        Mitras-The Digital Franchisees, act as crucial
                        intermediaries in communities. They bridge the gap,
                        facilitating access to medical services and enhancing
                        community health. */}
                        Digital Franchises are individuals empowered through
                        training. They play a vital role in providing quality &
                        reliable healthcare to communities while creating
                        opportunities for themselves. Swasthya Mitras facilitate
                        easier access to medical facilities within these
                        communities.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
            {window.innerWidth > 768 && <div  id="allservice"></div>}
          </div>
        </section>
        {/* Why Choose curebay */}
        <section className="bg-lightGray">
          <div className="container mx-auto py-[50px] md:py-[90px]">
            <h2 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-[30px] md:mb-[50px]">
              Why Choose <span className="text-secondaryColor">CureBay</span>?
            </h2>
            <div className="md:mt-[5rem]">
              <Whychoose />
            </div>
          </div>
        </section>
        {/* Good Health for everyone */}
        <section className="bg-primaryColor relative">
          <div className="container mx-auto py-[50px] md:py-[90px] relative z-10">
            <h2 className="text-center text-2xl md:text-4xl font-bold text-White leading-normal mb-[30px] md:mb-[50px]">
              Good <span className="text-secondaryColor">Health</span> for{" "}
              <span className="text-secondaryColor">everyone</span>
            </h2>
            <p className="md:w-[70%] mx-auto text-sm md:text-lg md:leading-loose text-White text-center">
              Introducing CureBay's affordable, first-of-its-kind, Annual Health
              Membership specially designed for rural patients. Beyond
              insurance, our program offers wellness benefits, unlimited doctor
              consultations, and discounts on medicines, tests, and ambulances.
            </p>
            <div className="flex justify-center my-10">
              <img
                src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/good_health.png"
                alt="images"
              />
            </div>
            <div className="w-full flex justify-center">
              <div
                onClick={handleCheckout}
                className="group flex justify-center items-center w-fit text-base hover:bg-White bg-secondaryColor rounded p-3 hover:text-secondaryColor text-White gap-3 cursor-pointer"
              >
                Avail Now{" "}
                <img
                  src={Linkarrow}
                  alt="images"
                  className="brightness-200 group-hover:brightness-100"
                />
              </div>
            </div>
          </div>
          <div className="w-full absolute top-[50%] ">
            <img
              src={BgRectImage}
              alt="images"
              className="w-full h-[200px] md:h-full"
            />
          </div>
        </section>
        {/* Total Cure */}
        <section className="bg-White">
          <div className="container mx-auto py-[50px] md:py-[90px] relative">
            <div className="md:flex w-full gap-5">
              <div className="bg-lightGreen md:w-[60%] p-5 md:p-10 rounded-xl">
                <h6 className="text-secondaryColor text-base md:text-xl">
                  CureBay Totalcare
                </h6>
                <h2 className="text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-5">
                  Take care from anywhere
                </h2>
                <p className="text-sm md:text-lg text-textGray">
                  CureBay Totalcare is a meticulously crafted healthcare
                  solution for your loved ones, providing holistic care for
                  their well-being. With instant emergency intervention,
                  experienced doctors visit your home, and an emergency
                  ambulance service just a call away, we ensure swift responses
                  in critical situations. Our services include timely diagnosis,
                  periodic health check-ups with instant online reports, and
                  top-notch medical assistance.
                </p>
                <button
                  onClick={() => handleNavigation("/services/totalcare")}
                  className="hover:bg-primaryColor bg-secondaryColor text-White w-[130px] h-10 rounded-md mt-10 hidden md:block"
                >
                  Know more
                </button>
                <button
                  onClick={() => handleNavigation("/services/totalcare")}
                  className="hover:bg-primaryColor bg-secondaryColor text-White w-[130px] h-8 text-sm rounded-md mt-10 block md:hidden"
                >
                  Know more
                </button>
              </div>
              <div className="md:w-[40%]">
                <img
                  src={TotalCareImage}
                  alt="images"
                  className="image-boxshadow "
                />
              </div>
            </div>
          </div>
        </section>
        {/* Leading Partners */}
        <section className="bg-primaryColor">
          <div className="container mx-auto py-[50px] md:py-[90px] relative">
            <h2 className="text-center text-2xl md:text-4xl font-bold text-White leading-normal mb-[50px]">
              Industry Leading{" "}
              <span className="text-secondaryColor">Partners</span>
            </h2>
            <div className="md:w-[80%] mx-auto">
              <IndustryLead />
            </div>
          </div>
        </section>
        <section className="bg-White">
          <div className="container mx-auto py-[50px] md:py-[90px] relative">
            <div className="hidden md:block">
              <div className="tech-grid">
                <div
                  className=" bg-lightGray px-7 py-10 rounded-2xl flex flex-col justify-between"
                  id="techOne"
                >
                  <p className="uppercase text-primaryColor font-semibold text-base">
                    Happy Patients
                  </p>
                  <span className="text-primaryColor text-7xl font-medium">
                    100,000+
                  </span>
                </div>
                <div className="w-full" id="techTwo">
                  <img
                    src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/patient1.png"
                    alt="images"
                    className="w-full"
                  />
                </div>
                <div
                  className=" bg-lightGray px-7 py-10 rounded-2xl flex flex-col justify-between"
                  id="techThree"
                >
                  <p className="uppercase text-primaryColor font-semibold text-base">
                    VERIFIED DOCTORS
                  </p>
                  <span className="text-primaryColor text-6xl font-medium">
                    170+
                  </span>
                </div>
                <div className="w-full" id="techFour">
                  <img
                    src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/patient2.png"
                    alt="images"
                    className="w-full"
                  />
                </div>
                <div
                  className=" bg-lightGray px-7 py-10 rounded-2xl flex flex-col justify-between"
                  id="techFive"
                >
                  <p className="uppercase text-primaryColor font-semibold text-base">
                    PIN-CODES COVERED
                  </p>
                  <span className="text-primaryColor text-6xl font-medium">
                    398
                  </span>
                </div>
                <div className="w-full" id="techSix">
                  <img
                    src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/patient3.png"
                    alt="images"
                    className="w-full"
                  />
                </div>
                <div
                  className=" bg-primaryColor px-7 py-10 rounded-2xl flex flex-col justify-between"
                  id="techSeven"
                >
                  <p className="uppercase text-White font-semibold text-base">
                    HOSPITAL PARTNERS
                  </p>
                  <span className="text-White text-6xl font-medium">100+</span>
                </div>
              </div>
            </div>
            <div className="block md:hidden">
              <div className="flex gap-3">
                <div className="w-[40%]">
                  <div
                    className="mb-3 bg-primaryColor p-3 md:px-7 h-[150px] md:py-10 rounded-2xl flex flex-col justify-between"
                    id="techSeven"
                  >
                    <p className="capitalize text-White font-medium text-xs md:text-base">
                      Happy Patients
                    </p>
                    <span className="text-White text-2xl md:text-6xl font-medium">
                      1 Lac+
                    </span>
                  </div>
                  <div className="w-full mb-3" id="techFour">
                    <img
                      src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/patient2.png"
                      alt="images"
                      className="w-full"
                    />
                  </div>
                  <div
                    className="mb-3 bg-primaryColor p-3 md:px-7 h-[100px] md:py-10 rounded-2xl flex flex-col justify-between"
                    id="techFive"
                  >
                    <p className="capitalize text-White font-medium text-xs md:text-base">
                      Pin-Code Covers
                    </p>
                    <span className="text-White text-2xl md:text-6xl font-medium">
                      398
                    </span>
                  </div>
                  <div className="w-full" id="techSix">
                    <img
                      src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/patient3.png"
                      alt="images"
                      className="w-full"
                    />
                  </div>
                </div>
                <div className="w-[60%]">
                  <div className="w-full mb-3" id="techTwo">
                    <img
                      src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/patient1.png"
                      alt="images"
                      className="w-full"
                    />
                  </div>
                  <div
                    className="mb-3 bg-lightGray p-3 md:px-7 h-[225px] md:py-10 rounded-2xl flex flex-col justify-between"
                    id="techSeven"
                  >
                    <p className="capitalize text-primaryColor font-medium text-base md:text-base">
                      Hospital Partners
                    </p>
                    <span className="text-primaryColor text-4xl md:text-6xl font-medium">
                      100+
                    </span>
                  </div>
                  <div
                    className="mb-3 bg-lightGray p-3 md:px-7 h-[120px]  md:py-10 rounded-2xl flex flex-col justify-between"
                    id="techSeven"
                  >
                    <p className="capitalize text-primaryColor font-medium text-xs md:text-base">
                      Verified Doctors
                    </p>
                    <span className="text-primaryColor text-2xl md:text-6xl font-medium">
                      170+
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Eclinic */}
        {/* <section className="bg-lightGray">
          <div className="container mx-auto py-[50px] md:py-[90px] relative mb-10">
            <h2 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal  md:mb-[50px]">
              Find your{" "}
              <span className="text-secondaryColor">nearest eClinic</span>
            </h2>
           {process.env.REACT_APP_ENV == 'production' ? <div className="relative">
              <Mapmuind />
            </div> : null}
          </div>
        </section> */}
        {/* FAQ */}
        <section className="bg-primaryColor sm:mt-0 mt-20">
          <div className="container mx-auto sm:py-[90px] py-[10px]">
            <h2 className="text-center text-2xl md:text-4xl font-bold text-White leading-normal mb-[50px]">
              Frequently Asked{" "}
              <span className="text-secondaryColor">Questions</span>
            </h2>
            <div className="md:w-[90%] mx-auto">
              <FAQ />
            </div>
          </div>
        </section>
        {/* Get in Touch */}
        <section className="bg-White" id="Contactus">
          <div
            ref={secondContentRef}
            className="container mx-auto py-[50px] md:py-[90px]"
          >
            <h2 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-[30px] md:mb-[50px]">
              Get in <span className="text-secondaryColor">Touch</span>
            </h2>
            <div className="md:grid grid-cols-2 gap-5">
              <div className="flex justify-center">
                <img
                  src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/contactus.png"
                  alt="images"
                  className="h-full hidden md:block"
                />
                <img
                  src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/contactus1.png"
                  alt="images"
                  className="h-full block md:hidden"
                />
              </div>
              <div className="bg-lightGray rounded-2xl p-5 md:p-10">
                <Contactus />
              </div>
            </div>
          </div>
        </section>
        {/* Happy Patients */}
        <section className="bg-lightGray">
          <div className="container mx-auto py-[50px] md:py-[90px]">
            <h2 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-[50px]">
              Happy <span className="text-secondaryColor">Patients</span> Speak
            </h2>
            <div className="mb-[90px] md:mb-0 ">
              <Slider {...bottomOne}>
                <div className="px-3">
                  <div className="bg-White rounded-2xl  p-5">
                    <div className="mb-5">
                      <img
                        src={PatientBPChecking}
                        alt="images"
                        className="w-full"
                      />
                    </div>
                    <p className="text-sm text-textGray h-[120px] overflow-y-auto">
                      I live on the outskirts of Kundhei and last week, I was
                      looking for medicine unavailable at any nearby local
                      pharmacy. it was when one of my neighbours suggested the
                      CureBay e-clinic. Recently I contacted their e-clinic in
                      my area for teleconsultation. Their team helped me
                      teleconsult with the leading heart specialist. The
                      medicine order can be placed directly on phone calls and
                      WhatsApp. The medicines are delivered to the nearest
                      e-clinic. I collected it from there.
                    </p>
                    <div className="text-center mt-5">
                      <p className="text-lg text-primaryColor font-semibold mb-0">
                        Shita Bagchi
                      </p>
                      <p className="text-base text-textGray">Bhubaneswar</p>
                    </div>
                  </div>
                </div>
                <div className="px-3">
                  <div className="bg-White rounded-2xl  p-5">
                    <div className="mb-5">
                      <img
                        src={PatientBloodTest}
                        alt="images"
                        className="w-full"
                      />
                    </div>
                    <p className="text-sm text-textGray h-[120px] overflow-y-auto">
                      These days, it is very easy for people in the cities to
                      book lab tests. They just have to search laboratory test
                      near me online and the representative will come and
                      collect the sample. Living in distant district Odisha, I
                      never thought finding a medical lab near me would be so
                      easy for us too. I live 5 km away from Kundhei and finding
                      a medical lab is a bit difficult. But a friend suggested
                      CureBay to me. They got my medical tests done through
                      their healthcare diagnostics partners. They collected
                      samples from my home and delivered the reports to me on
                      the phone. This was very convenient.
                    </p>
                    <div className="text-center mt-5">
                      <p className="text-lg text-primaryColor font-semibold mb-0">
                        Anjan Ghosh
                      </p>
                      <p className="text-base text-textGray">Cuttack</p>
                    </div>
                  </div>
                </div>
                <div className="px-3">
                  <div className="bg-White rounded-2xl  p-5">
                    <div className="mb-5">
                      <img
                        src={PatientPulseChecking}
                        alt="images"
                        className="w-full"
                      />
                    </div>
                    <p className="text-sm text-textGray h-[120px] overflow-y-auto">
                      A few years back, it was quite difficult to buy
                      prescription medicine online in my area which is a rural
                      part of Odisha. All my friends in cities buy medicine
                      online. But recently I visited CureBay eclinic. I
                      consulted a doctor through their teleconsultation service,
                      and I was able to place medicine orders through their
                      eclinic, did not have to visit near by city. I am
                      satisfied with their services. I really like their concept
                      of bringing a revolutionary change in primary healthcare
                      services through their teleconsultation services at their
                      e-clinic, pathological test services, and ambulance
                      services in small cities.
                    </p>
                    <div className="text-center mt-5">
                      <p className="text-lg text-primaryColor font-semibold mb-0">
                        Bijetree Sarkar
                      </p>
                      <p className="text-base text-textGray">Puri</p>
                    </div>
                  </div>
                </div>
                <div className="px-3">
                  <div className="bg-White rounded-2xl  p-5">
                    <div className="mb-5">
                      <img
                        src={PatientBPChecking1}
                        alt="images"
                        className="w-full"
                      />
                    </div>
                    <p className="text-sm text-textGray h-[120px] overflow-y-auto">
                      I live in Gop and six months back I discovered that my
                      father has some heart issues. I consulted a renowned
                      cardiologist at our nearest metro-city. But due to the
                      need of regular checkups it was not possible to pay
                      frequent visits to the cardiologist. But recently I got to
                      know about CureBay e-clinics. I went to the nearest
                      e-clinic and consulted a leading cardiologist as well as a
                      general surgeon. I am quite happy as it saves a lot of
                      time and resources.
                    </p>
                    <div className="text-center mt-5">
                      <p className="text-lg text-primaryColor font-semibold mb-0">
                        Dipayan Bhui
                      </p>
                      <p className="text-base text-textGray">Puri</p>
                    </div>
                  </div>
                </div>
                <div className="px-3">
                  <div className="bg-White rounded-2xl  p-5">
                    <div className="mb-5">
                      <img
                        src={PatientBPChecking2}
                        alt="images"
                        className="w-full"
                      />
                    </div>
                    <p className="text-sm text-textGray h-[120px] overflow-y-auto">
                      I am a diabetic, so I have to keep my blood sugar in
                      control. For my recent HbA1c test, I contacted CureBay
                      e-clinic. The test was donethe report of the test was
                      delivered on my phone. I found it to be time-saving and
                      energy-saving. To know more about the HbA1c test price you
                      can either visit their e-clinic or contact them.
                    </p>
                    <div className="text-center mt-5">
                      <p className="text-lg text-primaryColor font-semibold mb-0">
                        Souhardya Chandra
                      </p>
                      <p className="text-base text-textGray">Khorda</p>
                    </div>
                  </div>
                </div>
                <div className="px-3">
                  <div className="bg-White rounded-2xl  p-5">
                    <div className="mb-5">
                      <img
                        src={PatientBPChecking3}
                        alt="images"
                        className="w-full"
                      />
                    </div>
                    <p className="text-sm text-textGray h-[120px] overflow-y-auto">
                      I have been searching for a medical lab near me, but all
                      of them required me to physically visit the lab collection
                      centre. I always searched best pathology lab near me
                      online, they were quite far. Then I got to know about
                      CureBay e-clinics. I was quite surprised to have this kind
                      of fast medical service in Bhubaneswar. I contacted them
                      and they scheduled an appointment and the samples were
                      collected from my doorstep. The reports were sent on my
                      smartphone. It was a hassle free process and I am quite
                      happy with their services.
                    </p>
                    <div className="text-center mt-5">
                      <p className="text-lg text-primaryColor font-semibold mb-0">
                        Doyelshree Bhui
                      </p>
                      <p className="text-base text-textGray">Bhubaneswar</p>
                    </div>
                  </div>
                </div>
                <div className="px-3">
                  <div className="bg-White rounded-2xl  p-5">
                    <div className="mb-5">
                      <img
                        src={PatientBPChecking4}
                        alt="images"
                        className="w-full"
                      />
                    </div>
                    <p className="text-sm  text-textGray h-[120px] overflow-y-auto">
                      I am a senior citizen and stay in Bhubaneswar and it is
                      not possible for me to get out of my house every time I
                      need to get a diagnostic lab test done. Recently I got to
                      know about CureBay e-clinics. I contacted them to get
                      pathological tests done. I am quite satisfied with their
                      services, it was a smooth and hassle-free process. They
                      came to take my samples and my reports were sent on my
                      phone. It saved a lot of time.
                    </p>
                    <div className="text-center mt-5">
                      <p className="text-lg text-primaryColor font-semibold mb-0">
                        Ishani Bagchi
                      </p>
                      <p className="text-base text-textGray">Cuttack</p>
                    </div>
                  </div>
                </div>
                <div className="px-3">
                  <div className="bg-White rounded-2xl  p-5">
                    <div className="mb-5">
                      <img
                        src={PatientBPChecking5}
                        alt="images"
                        className="w-full"
                      />
                    </div>
                    <p className="text-sm text-textGray h-[120px] overflow-y-auto">
                      I live on the outskirts of Bamanala and here it is quite
                      difficult to avail good quality medical care. I contacted
                      CureBay for my typhoid fever treatment. I visited their
                      e-clinic and consulted leading doctors in Odisha through
                      video-conferencing. The doctor recommended a few tests and
                      medicines. CureBay helped me to get all my tests done and
                      the e-clinic near me sent the reports on my smartphone.
                      They also helped with prescriptions and made the
                      prescribed medicines available at the e-clinic. I am very
                      happy with the medical care CureBay offered for treating
                      my typhoid fever.
                    </p>
                    <div className="text-center mt-5">
                      <p className="text-lg text-primaryColor font-semibold mb-0">
                        Kaumudi Daudkar
                      </p>
                      <p className="text-base text-textGray">Puri</p>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
            <div className="md:mt-8 mt-14 mb-[40px] md:mb-0">
              <Slider {...bottomTwo} className="splSlider">
                <div className="px-3">
                  <div className="bg-White rounded-2xl p-3 md:p-5">
                    <video
                      className="w-full md:rounded-[20px] "
                      // autoPlay
                      loop
                      muted
                      preload="auto"
                      playsInline
                      controls
                      poster={VideoImage1}
                    >
                      <source
                        src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Videos%201/Videos/Akshya%20Nayak.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
                <div className="px-3">
                  <div className="bg-White rounded-2xl p-3 md:p-5">
                    <video
                      className="w-full md:rounded-[20px] "
                      // autoPlay
                      loop
                      muted
                      preload="auto"
                      playsInline
                      controls
                      poster={VideoImage2}
                    >
                      <source
                        src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Videos%201/Videos/Anita%20Dehuri.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
                <div className="px-3">
                  <div className="bg-White rounded-2xl p-3 md:p-5">
                    <video
                      className="w-full md:rounded-[20px] "
                      // autoPlay
                      loop
                      muted
                      preload="auto"
                      playsInline
                      controls
                      poster={VideoImage3}
                    >
                      <source
                        src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Videos%201/Videos/Basanti%20Ratha.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
                <div className="px-3">
                  <div className="bg-White rounded-2xl p-3 md:p-5">
                    <video
                      className="w-full md:rounded-[20px] "
                      // autoPlay
                      loop
                      muted
                      preload="auto"
                      playsInline
                      controls
                      poster={VideoImage4}
                    >
                      <source
                        src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Videos%201/Videos/Dipti%20Ranjan%20Parida_subtitle.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
                <div className="px-3">
                  <div className="bg-White rounded-2xl p-3 md:p-5">
                    <video
                      className="w-full md:rounded-[20px] "
                      // autoPlay
                      loop
                      muted
                      preload="auto"
                      playsInline
                      controls
                      poster={VideoImage5}
                    >
                      <source
                        src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Videos%201/Videos/Kumuduni%20Parida.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
                <div className="px-3">
                  <div className="bg-White rounded-2xl p-3 md:p-5">
                    <video
                      className="w-full md:rounded-[20px] "
                      // autoPlay
                      loop
                      muted
                      preload="auto"
                      playsInline
                      controls
                      poster={VideoImage6}
                    >
                      <source
                        src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Videos%201/Videos/Manmohan%20Biswal_subtitle.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
                <div className="px-3">
                  <div className="bg-White rounded-2xl p-3 md:p-5">
                    <video
                      className="w-full md:rounded-[20px] "
                      // autoPlay
                      loop
                      muted
                      preload="auto"
                      playsInline
                      controls
                      poster={VideoImage7}
                    >
                      <source
                        src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Videos%201/Videos/Mitali%20Sahoo_subtitle.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
                <div className="px-3">
                  <div className="bg-White rounded-2xl p-3 md:p-5">
                    <video
                      className="w-full md:rounded-[20px] "
                      // autoPlay
                      loop
                      muted
                      preload="auto"
                      playsInline
                      controls
                      poster={VideoImage8}
                    >
                      <source
                        src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Videos%201/Videos/Prasanna%20Behera_subtitle.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
                <div className="px-3">
                  <div className="bg-White rounded-2xl p-3 md:p-5">
                    <video
                      className="w-full md:rounded-[20px] "
                      // autoPlay
                      loop
                      muted
                      preload="auto"
                      playsInline
                      controls
                      poster={VideoImage9}
                    >
                      <source
                        src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Videos%201/Videos/Rabi%20Behera.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
                <div className="px-3">
                  <div className="bg-White rounded-2xl p-3 md:p-5">
                    <video
                      className="w-full md:rounded-[20px] "
                      // autoPlay
                      loop
                      muted
                      preload="auto"
                      playsInline
                      controls
                      poster={VideoImage10}
                    >
                      <source
                        src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Videos%201/Videos/Sanatan%20Kar_subtitle.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </section>
      </Layouts>
      {isModalOpen && <Login mode="Login" />}
      <Modal
        centered={true}
        footer={false}
        className="commonModal"
        title="Add Family Member"
        width="60%"
        open={isModalOpen1}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {/* <AddFamilyMember /> */}
        <div className="grid grid-cols-2 items-center gap-5 mt-10 mb-5">
          <button className="bg-White text-primaryColor border-primaryColor border rounded-lg w-full h-[50px] text-lg">
            Cancel
          </button>
          <button className="bg-primaryColor w-full h-[50px] text-lg rounded-lg text-White">
            Submit
          </button>
        </div>
      </Modal>

      <Modal
        title="Add a Family Member"
        className="commonModal"
        open={showaddmemberpopup}
        onOk={() => setshowaddmemberpopup(!showaddmemberpopup)}
        onCancel={() => {
          setshowaddmemberpopup(!showaddmemberpopup);
          refreshFamilyMembers();
        }}
        width="65%"
        style={{ top: 20 }}
        footer={[]}
      >
        <AddFamilymember
          editmembers={Editmember}
          closePopup={() => {
            setshowaddmemberpopup(!showaddmemberpopup);
            refreshFamilyMembers();
          }}
          saveMessage={(message) => {
            setshowaddmemberpopup(!showaddmemberpopup);
            // toast(message);
            refreshFamilyMembers();
          }}
        />
      </Modal>

      {!isHide && (
        <RescheduleModal
          data={appointmentdetails}
          onClose={() => onRescheduleModelClose()}
        />
      )}

      {showCancelDialog ? (
        <CancelAppointmentPopup
          isErrorMsg={isErrorMsg}
          onCancel={confirmCancelAppointment}
          isLoading={isLoading}
          close={() => {
            setShowCancelDialog(false);
            setErrorMsg("");
          }}
        />
      ) : null}

      <Modal
        title="View Order details"
        className="commonModal"
        centered="true"
        open={modal1}
        onCancel={() => setModal1(false)}
        width="60%"
        footer={[]}
      >
        <div className="flex justify-between items-center mb-2 md:mb-3">
          <div>
            <p class="text-sm md:text-base font-medium mb-1 text-primaryColor">
              Number of items :{" "}
              <span className="text-secondaryColor">
                {currSelectedOrder?.totalOrderItems}
              </span>
            </p>
          </div>
          <div>
            <p class="text-sm md:text-base font-medium mb-1 text-primaryColor hidden md:block">
              Amount Paid :{" "}
              <span className="text-secondaryColor">
                ₹ {currSelectedOrder?.amount}
              </span>
            </p>
            <p class="text-sm md:text-base font-medium mb-1 text-primaryColor block md:hidden">
              <span className="text-secondaryColor">
                ₹ {currSelectedOrder?.amount}
              </span>
            </p>
          </div>
          <div className="hidden md:block">
            <div className="">
              <p className="text-sm font-medium bg-lightGreen px-5 py-1 rounded-full text-secondaryColor">
                <span>{currSelectedOrder?.orderStatus}</span>
              </p>
            </div>
            <div>
              {/* <p className="text-sm font-medium mb-4  text-gray-500">
                      Order ID: <span>REF637UND153851</span>
                    </p> */}
            </div>
          </div>
        </div>
        <div className="block md:hidden mb-3">
          <p className="text-sm font-medium bg-lightGreen px-5 py-1 rounded-full text-secondaryColor w-fit">
            <span>{currSelectedOrder?.orderStatus}</span>
          </p>
        </div>
        <div className="grid grid-cols-2 md:flex gap-3 justify-between ">
          <div className="mb-3">
            <p class="text-xs font-light mb-1 text-textGray">Order ID</p>
            <p class="text-xs md:text-sm font-medium text-textGray">
              {currSelectedOrder?.patientOrderId}
            </p>
          </div>
          <div className="mb-3">
            <p class="text-xs font-light mb-1 text-textGray">Placed On </p>
            <p class="text-xs md:text-sm font-medium text-textGray">
              {currSelectedOrder?.paymentDateTime}
            </p>
          </div>
          <div className="mb-3 hidden md:block">
            <p class="text-xs font-light mb-1 text-textGray">
              Scheduled Delivery
            </p>
            <p class="text-xs md:text-sm font-medium text-textGray">
              {currSelectedOrder?.scheduledDelivery}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-2 md:flex justify-between md:items-center">
          <div className="mb-3 md:hidden block">
            <p class="text-xs font-light mb-1 text-textGray">
              Scheduled Delivery
            </p>
            <p class="text-xs md:text-sm font-medium text-textGray">
              {currSelectedOrder?.scheduledDelivery}
            </p>
          </div>
          <div className="hidden md:block">
            <p class="text-xs font-light mb-1 text-textGray">
              Delivery Address
            </p>
            <p class="text-xs md:text-sm  mb-2 font-medium text-textGray">
              {currSelectedOrder?.deliveryAddress1}{" "}
              {currSelectedOrder?.deliveryAddress2},{" "}
              {currSelectedOrder?.deliveryCity},{" "}
              {currSelectedOrder?.deliveryState} -{" "}
              {currSelectedOrder?.deliveryPinCode}
            </p>
          </div>
          {/* <div className="flex justify-end md:block">
            <button class="text-White text-sm font-medium h-8 px-3 rounded-md bg-primaryColor">
              Reorder
            </button>
          </div> */}
        </div>
        <div className="md:hidden block">
          <p class="text-xs font-light mb-1 text-textGray">Delivery Address</p>
          <p class="text-xs md:text-sm  mb-2 font-medium text-textGray">
            {currSelectedOrder?.deliveryAddress1}{" "}
            {currSelectedOrder?.deliveryAddress2},{" "}
            {currSelectedOrder?.deliveryCity},{" "}
            {currSelectedOrder?.deliveryState} -{" "}
            {currSelectedOrder?.deliveryPinCode}
          </p>
        </div>
        <div className="mt-5 overflow-x-auto">
          <table className=" border-collapse border border-gray w-full h-full mx-auto ">
            <thead>
              <tr className="bg-gray-200">
                <th className="border border-gray-400 p-2">ITEM NAME</th>
                <th className="border border-gray-400 p-2">QTY</th>
                <th className="border border-gray-400 p-2">UNIT PRICE</th>
                <th className="border border-gray-400 p-2">
                  UNIT DISCOUNTED PRICE
                </th>
                <th className="border border-gray-400 p-2">TOTAL AMOUNT</th>
              </tr>
            </thead>
            <tbody>
              {currSelectedOrder?.orderDetails[0]?.refundableAmountForItems?.map(
                (drug, idx) => (
                  <tr key={idx}>
                    <td className="border border-gray-400 p-2">
                      {drug?.drugName}
                    </td>
                    <td className="border border-gray-400 p-2">
                      {drug?.quantity}
                    </td>
                    <td className="border border-gray-400 p-2">
                      ₹ {drug?.unitPrice}
                    </td>
                    <td className="border border-gray-400 p-2">
                      ₹ {drug?.discountAmount}
                    </td>
                    <td className="border border-gray-400 p-2">
                      ₹ {drug?.totalAmount}
                    </td>
                  </tr>
                )
              )}
              {currSelectedOrder?.orderDetails[0]?.orderItems?.map(
                (drug, idx) => (
                  <tr key={idx}>
                    <td className="border border-gray-400 p-2">
                      {drug?.drugName}
                    </td>
                    <td className="border border-gray-400 p-2">
                      {drug?.quantity}
                    </td>
                    <td className="border border-gray-400 p-2">
                      ₹ {drug?.unitPrice}
                    </td>
                    <td className="border border-gray-400 p-2">
                      ₹ {drug?.discountAmount}
                    </td>
                    <td className="border border-gray-400 p-2">
                      ₹ {drug?.totalAmount}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </Modal>
      <Modal
        title="Order Details"
        className="commonModal"
        centered="true"
        open={isDialogOpen}
        onCancel={() => setDialog(false)}
        footer={[]}
      >
        <div className="overflow-x-auto">
          <table className="border-collapse border border-gray w-full h-full mx-auto ">
            <thead>
              <tr className="bg-gray-200">
                <th className="border border-gray-400 p-2">ITEM NAME</th>
                <th className="border border-gray-400 p-2">QTY</th>
                <th className="border border-gray-400 p-2">UNIT PRICE</th>
                {/* <th className="border border-gray-400 p-2">
                        UNIT DISCOUNTED PRICE
                      </th>
                      <th className="border border-gray-400 p-2">
                        TOTAL AMOUNT
                      </th> */}
              </tr>
            </thead>
            {reorderData.map((item, i) => {
              return (
                <>
                  <tbody>
                    <tr>
                      <td className="border border-gray-400 p-2">
                        {item.drugName}
                      </td>
                      <td className="border border-gray-400 p-2">
                        {item.quantity}
                      </td>
                      <td className="border border-gray-400 p-2">
                        ₹ {parseFloat(item.medicineRate).toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </>
              );
            })}
          </table>
        </div>
        <div>
          <button
            disabled={addToCartLoading}
            onClick={(e) => addtoCart(e, cartData)}
            class="text-White text-sm font-semibold px-8 w-full mt-5 h-10 rounded-md bg-secondaryColor"
          >
            Add to cart
          </button>
        </div>
      </Modal>
      <Modal
        title={
          <p className="font-bold text-primaryColor text-xl">Prescription</p>
        }
        open={prescriptionDialog}
        onCancel={() => setPrescriptionDialog(false)}
        footer={[]}
        width="60%"
      >
        <div className="flex flex-col text-center justify-center">
          {prescriptionDoc?.length &&
            prescriptionDoc?.map((res) => {
              return (
                <div className="w-auto">
                  {res.split(".")[1].toUpperCase() == "PDF" ? (
                    <iframe
                      style={{ width: "100%", height: "500px" }}
                      src={`${process.env.REACT_APP_IMG_BASEURL}${res}`}
                      alt="profile image"
                    />
                  ) : (
                    <img
                      style={{ width: "100%", height: "500px" }}
                      src={`${process.env.REACT_APP_IMG_BASEURL}${res}`}
                      alt="profile image"
                    />
                  )}
                </div>
              );
            })}
        </div>
      </Modal>

      <Modal
         open={prescriptionView}
        width={900}
        footer={false}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <EprescriptionsView userID={userID} />
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => ({
  booleanValue: state.needHelp,
});
export default connect(mapStateToProps)(Home);

// export default ;
