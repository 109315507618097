import HIW1 from "../../../Assets/Images/hiwsaresathi1.svg";
import HIW2 from "../../../Assets/Images/hiwcaresathi2.svg";
import HIW3 from "../../../Assets/Images/labinsightstep3.svg";
import HIW4 from "../../../Assets/Images/hiwcaresathi4.svg";
import SmartVitalsImage1 from "../../../Assets/Images/smartVitalsImage1.svg";
import SmartVitalsImage2 from "../../../Assets/Images/smartVitalsImage2.svg";
import SmartVitalsImage3 from "../../../Assets/Images/smartVitalsImage3.svg";
import SmartVitalsImage4 from "../../../Assets/Images/smartVitalsImage4.svg";
// import SubHeader from "../../Shared/SearchBar";
import Layouts from "../../Layouts/index";

import TrustIcon from "../../../Assets/Images/Icons/Trust Icon.svg";
import TrustIcon1 from "../../../Assets/Images/Icons/TrustIcon1.svg";
import CARESATHIIMAGE from "../../../Assets/Images/CareSathi/care-sathi-image.png";
import docticon from "../../../Assets/Images/doctor_ai.svg";
import Labinsight from "../../../Assets/Images/smartVitals.svg";
import DENTISTRYIMAGE from "../../../Assets/Images/dentalCare.svg";
import Docmob from "../../../Assets/Images/docmob.svg";
import LABREPORTANALYSERIMAGE from "../../../Assets/Images/labInsight.svg";
import phoneVideoImage from "../../../Assets/Images/phoneVideo.svg";
import Caresathivid from "../../../Assets/Images/careSathi_video.mp4";

const CaresathiMainpage = () => {
  return (
    <Layouts style={{ backgroundColor: "#004171" }}>
      {/* Title Content */}
      {/* <SubHeader /> */}
      <section className="home-banner min-h-[70vh] my-8  ">
        <div className="container mx-auto">
          <div className="bg-primaryColor absolute left-0 md:w-[90%] h-[70vh] rounded-e-xl"></div>
          <div className="md:grid grid-cols-2 gap-5 min-h-[70vh] items-center relative">
            <div className="relative z-1 pt-7 md:pt-5 p-5 md:p-0 rounded-2xl bg-[#F3F8FF] md:bg-primaryColor">
              <span className="text-secondaryColor text-sm md:text-6xl font-medium  mb-5 md:mb-3">
                CareSathi
              </span>
              <div className="flex flex-row">
                <h1 className="text-primaryColor md:text-White text-2xl md:text-3xl font-bold sm:mt-8 mt-0 ">
                  Your Trusted Friend In Healing,{" "}
                  <span className="text-secondaryColor ">
                    Always By Your Side
                  </span>
                </h1>
              </div>
              <p className="text-primaryColor md:text-White font-extralight text-sm md:text-base mt-2 mb-4">
                When life feels heavy, and your worries seem overwhelming,
                CareSathi truly listens. It understands the unspoken language of
                your emotions, sensing your anxiety, fear, and need for comfort.
                Whether you're confused about symptoms, need help managing
                medications, or want reassurance, CareSathi connects you to
                CureBay’s healthcare network, ensuring you’re never alone.
              </p>
              <a
                className=" rounded-lg px-4 py-2 mr-2 mt-4 bg-secondaryColor"
                style={{ color: "#ffff" }}
                href="ask-care-sathi"
              >
                Ask CareSathi
              </a>
            </div>

            <div className="hidden sm:block z-1 absolute right-24 top-12">
            <video
                      className="w-full h-[500px] rounded-[20px]"
                      autoPlay
                      loop
                      muted
                      preload="auto"
                      playsInline
                     
                    >
                      <source
                        src={Caresathivid}
                        type="video/mp4"
                      />
                    </video>
            </div>
            <div className="flex md:hidden justify-center items-center gap-3 mt-5">
              <img src={TrustIcon1} alt="images" width={25} />
              <p className="text-primaryColor font-extralight text-sm">
                Trusted by{" "}
                <span className="font-medium">Over 100,000 Patients</span>
              </p>
            </div>
          </div>
          {/* <div className="swipicon">
                          <img src={Swipicon} alt="images" />
                      </div> */}
        </div>
      </section>
      <section className="">
        <div className="container mx-auto py[50px] md:py-[90px]">
          <h2 className="text-center text-2xl md:text-4xl font-semibold text-primaryColor leading-normal mb-8 md:mb-[50px]">
            How it <span className="text-secondaryColor">Works</span>?
          </h2>
          <div className="px-[14px] pb-6 sm:pb-auto sm:px-auto md:w-[80%] mx-auto flex sm:flex-row flex-col justify-between items-start gap-10">
            {/* Step 1 */}
            <div className="gap-3 sm:gap-auto flex sm:flex-col flex-row text-left">
              <div className="bg-[#F0F8FF] rounded-full w-16 sm:w-20 sm:h-20 h-14 flex items-center justify-center mb-4">
                <img src={HIW1} alt="Step 1" className="w-16 sm:h-16 h-10" />
              </div>
              <div className="flex flex-col">
                <div
                  className="sm:text-base text-sm font-semibold text-left"
                  style={{ color: "#42BA85" }}
                >
                  STEP 1
                </div>
                <div
                  className="text-base font-semibold text-left"
                  style={{ color: "#004171" }}
                >
                  Reach Out Anytime
                </div>
                <p
                  className="sm:text-sm text-[13px] font-medium"
                  style={{ color: "#676C80" }}
                >
                 CareSathi is available 24/7, ready to listen with empathy and compassion.
                </p>
              </div>
            </div>

            {/* Step 2 */}
            <div className="flex gap-3 sm:gap-auto  sm:flex-col flex-row">
              <div className="bg-[#F0F8FF] rounded-full w-16 sm:w-20 sm:h-20 h-14 flex items-center justify-center mb-4">
                <img src={HIW2} alt="Step 2" className="w-16 sm:h-16 h-10" />
              </div>
              <div className="flex flex-col">
                <div
                  className="sm:text-base text-sm font-semibold"
                  style={{ color: "#42BA85" }}
                >
                  STEP 2
                </div>
                <div
                  className="text-base font-semibold"
                  style={{ color: "#004171" }}
                >
                  Express Your Concerns
                </div>
                <p
                  className="sm:text-sm text-[13px] font-medium"
                  style={{ color: "#676C80" }}
                >
                  Share your symptoms and worries—CareSathi translates them into clear, actionable advice.
                </p>
              </div>
            </div>

            {/* Step 3 */}
            <div className="flex gap-3 sm:gap-auto  sm:flex-col flex-row">
              <div className="bg-[#F0F8FF] rounded-full w-16 sm:w-20 sm:h-20 h-14 flex items-center justify-center mb-4">
                <img src={HIW3} alt="Step 3" className="w-16 sm:h-16 h-10" />
              </div>
              <div className="flex flex-col">
                <div
                  className="sm:text-base text-sm font-semibold"
                  style={{ color: "#42BA85" }}
                >
                  STEP 3
                </div>
                <div
                  className="text-base font-semibold"
                  style={{ color: "#004171" }}
                >
                 Get Personalised Guidance
                </div>
                <p
                  className="sm:text-sm text-[13px] font-medium"
                  style={{ color: "#676C80" }}
                >
                 Get tailored advice to ensure you feel heard and supported.
                </p>
              </div>
            </div>

            {/* Step 4 */}
            <div className="flex gap-3 sm:gap-auto  sm:flex-col flex-row ">
              <div className="bg-[#F0F8FF] rounded-full w-16 sm:w-20 sm:h-20 h-14 flex items-center justify-center mb-4">
                <img src={HIW4} alt="Step 4" className="w-16 sm:h-16 h-10" />
              </div>
              <div className="flex flex-col">
                <div
                  className="sm:text-base text-sm font-semibold"
                  style={{ color: "#42BA85" }}
                >
                  STEP 4
                </div>
                <div
                  className="text-base font-semibold"
                  style={{ color: "#004171" }}
                >
                  Connect To Services
                </div>
                <p
                  className="sm:text-sm text-[13px] font-medium"
                  style={{ color: "#676C80" }}
                >
                  Easily access CureBay’s healthcare services, from consultations to lab tests and medications.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-[#f3f8ff]">
        <div className="container mx-auto py[50px] md:py-[90px] px-4">
          <h2 className="text-center pt-5 sm:pt-auto text-2xl md:text-4xl font-semibold text-primaryColor leading-normal mb-6 md:mb-[50px]">
            What You Can <span className="text-secondaryColor">Expect</span>
          </h2>
          <div className="flex justify-center items-center min-h-screen">
            <div className="grid sm:grid-cols-2 grid-cols-1 sm:gap-8 gap-0">
              <div className="flex flex-col items-center">
                <img src={SmartVitalsImage1} alt="Touch-Free Monitoring" />
                <div
                  style={{ backgroundColor: "#ffff" }}
                  className="sm:w-[68%] w-[90%] rounded-xl relative bottom-8 left-0 px-4 py-2"
                >
                  <p
                    style={{ color: "#004171" }}
                    className="sm:text-xl text-base font-semibold"
                  >
                    24/7 Availability
                  </p>
                  <p
                    style={{ color: "#676C80" }}
                    className="sm:text-base text-sm font-medium"
                  >
                    Receive healthcare support anytime, anywhere, right when you
                    need it
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center">
                <img src={SmartVitalsImage2} alt="Quick & Accurate Readings" />
                <div
                  style={{ backgroundColor: "#ffff" }}
                  className="sm:w-[68%] w-[90%] rounded-xl relative bottom-8 left-0 px-4 py-2"
                >
                  <p
                    style={{ color: "#004171" }}
                    className="sm:text-xl text-base font-semibold"
                  >
                    Expert Advice
                  </p>
                  <p
                    style={{ color: "#676C80" }}
                    className="sm:text-base text-sm font-medium"
                  >
                    Access professional advice on your symptoms and health
                    concerns
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center">
                <img src={SmartVitalsImage3} alt="Convenient Setup" />
                <div
                  style={{ backgroundColor: "#ffff" }}
                  className="sm:w-[68%] w-[90%] rounded-xl relative bottom-8 left-0 px-4 py-2"
                >
                  <p
                    style={{ color: "#004171" }}
                    className="sm:text-xl text-base font-semibold"
                  >
                    Instant Answers
                  </p>
                  <p
                    style={{ color: "#676C80" }}
                    className="sm:text-base text-sm font-medium"
                  >
                    Receive quick responses to your health-related questions
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center">
                <img src={SmartVitalsImage4} alt="Peace Of Mind" />
                <div
                  style={{ backgroundColor: "#ffff" }}
                  className="sm:w-[68%] w-[90%] rounded-xl relative bottom-8 left-0 px-4 py-2"
                >
                  <p
                    style={{ color: "#004171" }}
                    className="sm:text-xl text-base font-semibold"
                  >
                    Direct Connection
                  </p>
                  <p
                    style={{ color: "#676C80" }}
                    className="sm:text-base text-sm font-medium"
                  >
                    Seamlessly connect to CureBay’s healthcare services when
                    required
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-lightGray mb-4">
        <div className="bg-[#023b72] py-12 flex flex-col justify-center items-center min-h-screen">
          {/* Main Container */}

          <div className=" block sm:hidden text-center  z-10">
            <h2 className="text-3xl font-bold mb-2 text-White">
              Check Our Other
            </h2>
            <h3 className="text-3xl font-bold text-[#42BA85] mb-6">AI Tools</h3>
          </div>
          <div className="w-full max-w-5xl rounded-2xl flex flex-col  items-start justify-between relative overflow-hidden">
            {/* Left Section with Heading */}

            <div className=" hidden sm:flex w-full  max-w-5xl bg-[#0a5a9c]  rounded-t-2xl  items-start justify-between relative overflow-hidden">
              <div className="text-white z-10 md:w-1/2 sm:pl-[50px] sm:pt-[100px]">
                <h2 className="text-3xl font-bold mb-2 text-White">
                  Check Our Other
                </h2>
                <h3 className="text-3xl font-bold text-[#42BA85] mb-6">
                  AI Tools
                </h3>
              </div>

              {/* Background Image */}

              <img
                src={docticon}
                alt="Doctor"
                className="  md:w-[66%] w-full object-cover rounded-t-2xl "
              />
            </div>

            {/* AI Tools Cards Section */}
            <div className=" rounded-b-xl bg-[#0a5a9c] grid grid-cols-1 md:grid-cols-3 gap-6 z-10 mt-8 md:mt-0 px-[50px] py-[45px]">
              {/* Card 1 */}
              <div className="bg-white rounded-xl overflow-hidden ">
                <img
                  src={Labinsight}
                  alt="Labinsight"
                  className="w-full object-cover"
                />
                <div
                  className="p-4 bg-White rounded-b-xl "
                  style={{ height: "132px" }}
                >
                  <h4 className="text-lg font-semibold text-[#004171]">
                    SmartVitals
                  </h4>
                  <p className="text-sm text-[#676C80]">
                    Blood pressure, pulse rate, heart rate without touch
                  </p>
                  <a
                    href="/smart-vitals-detail"
                    className="text-[#42BA85] text-sm font-medium mt-2 inline-block"
                  >
                    Know More &rarr;
                  </a>
                </div>
              </div>

              {/* Card 2 */}
              <div className="bg-white rounded-xl overflow-hidden">
                <img
                  src={LABREPORTANALYSERIMAGE}
                  alt="Smart Vitals"
                  className="w-full  object-cover"
                />
                <div className="p-4  bg-White rounded-b-xl ">
                  <h4 className="text-lg font-semibold text-[#004171]">
                    LabInsights
                  </h4>
                  <p className="text-sm text-[#676C80]">
                    Get insights from your lab reports with AI precision
                  </p>
                  <a
                    href="/lab-insight"
                    className="text-[#42BA85] text-sm font-medium mt-2 inline-block"
                  >
                    Know More &rarr;
                  </a>
                </div>
              </div>

              {/* Card 3 */}

              <div className="bg-white rounded-xl overflow-hidden">
                <img
                  src={DENTISTRYIMAGE}
                  alt="AI Powered Dentistry"
                  className="w-full  object-cover"
                />
                <div
                  className="p-4  bg-White rounded-b-xl"
                  style={{ height: "128px" }}
                >
                  <h4 className="text-lg font-semibold text-[#004171]">
                    DentalCare
                  </h4>
                  <p className="text-sm text-[#676C80]">
                    Transform your smile with technology
                  </p>
                  <a
                    href="/services/dentalcare"
                    className="text-[#42BA85] text-sm font-medium mt-2 inline-block"
                  >
                    Know More &rarr;
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layouts>
  );
};

export default CaresathiMainpage;
