import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Drawer, Menu, message, Popover } from "antd";
import { Link, useLocation } from "react-router-dom"; // Import Link from React Router
import Logo from "../../Assets/Images/Logo.svg";
import SignupIcon from "../../Assets/Images/Icons/signup-icon.svg";
import { CaretDownOutlined, MenuOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import Login from "../Authentications/Login";
import DummyRoute from "../Pages/PostLogin/DummyRoute";
import Membership_icon1 from "../../Assets/Images/Membership_icon1.gif";
import Voucher_animated from "../../Assets/Images/Icons/couponIcon.jpeg";
import BellIcon from "../../Assets/Images/headerBellIcon.svg";
import Cart from "../../Assets/Images/headerCart.svg";
import { APP_ROUTES } from "../PageRouting/AppRoutes";
import { connect } from "react-redux";
import toggleBoolean from "../../Redux/Action/needhelpAction";
import { getCartDetails } from "../../Redux/Action/cartPlaceOrderAction";
import MedicalSummaryIcon from "../../Assets/Images/Icons/medical-medical-record.png";
import { set } from "lodash";
import { getNotification } from "../../Redux/Action/UserprofileActions";
import { useCookies } from "react-cookie";
import { CopyOutlined } from "@ant-design/icons";

function Header(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [current, setCurrent] = useState("mail");
  const [mode, setMode] = useState("Login");
  const [click, setClick] = useState(false);

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const location = useLocation();
  const userData = useSelector((state) => state.authReducer.patientData);
  const notificationdata = useSelector((state) => state.mynotificatons);
  const { notificationData } = notificationdata;
  const { cartList } = useSelector((state) => state.cartReducer);
  const [cookies, setCookie, removeCookie] = useCookies(["cookieConsent"]);

  const giveCookieConsent = () => {
    setCookie("cookieConsent", true, { path: "/", maxAge: 31536000 });
  };
  console.log(cookies, "cjdvnjns ");

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleOpenNotificationChange = (newOpen) => {
    setOpenNotification(newOpen);
  };

  const handleClick = (event) => {
    event.preventDefault();

    if (window.FreshworksWidget) {
      window.FreshworksWidget("open");
    }
  };
  useEffect(() => {
    // setTimeout(() => [
      if(userData?.code){
        dispatch(getCartDetails(userData.code));
        dispatch(getNotification(userData.code));
      }

    // ], 100);
  }, [userData?.code]);

  const handleClose = () => {
    setMode("Login");
    setIsModalOpen(false);
  };

  const items = [
    { label: "Home", path: "/" },

    {
      label: "Our Services",
      key: "Sub1",
      //icons: <CaretDownOutlined />,
      children: [
        { label: "Totalcare", path: "/services/totalcare" },
        { label: "Membership", path: "/services/membership" },
        // { label: "Find Doctor", path: "/find-doctors" },
        { label: "Consult a Doctor", path: "/services/book-an-appointment" },
        { label: "Order Medicine", path: "/services/order-medicines" },
        { label: "Book a Lab Test", path: "/services/lab" },
        { label: "Consult for Surgery", path: "/services/surgery" },
        { label: "Concierge Service", path: "/services/concierge" },
        { label: "Dental Care", path: "/services/dentalcare" },
      ],
    },
    { label: "Our Team", path: "/our-team" },
    { label: "About Us", path: "/about-us" },
    {
      label: "Join Us",
      key: "Sub2",
      // icons: <CaretDownOutlined />,
      children: [
        { label: "Hospital", path: "/partners/hospital" },
        { label: "Diagnostics", path: "/partners/diagnostics" },
        { label: "Doctors", path: "/partners/doctors" },
        { label: "Allied Services", path: "/partners/allied-service" },
        { label: "Franchise", path: "/partners/franchise" },
        { label: "Swasthya Mitra", path: "/partners/swashtya-mitra" },
        { label: "Careers", path: "/curebay-career" },
      ],
    },
  ];

  React.useEffect(() => {
    const path = location.pathname;
    const menuItem = items.find((item) => item.path === path);
    if (menuItem) {
      setCurrent(menuItem.label);
    } else {
      const subMenuItem = items.reduce((acc, item) => {
        if (item.children) {
          return item.children.find((child) => child.path === path) || acc;
        }
        return acc;
      }, null);
      if (subMenuItem) {
        setCurrent(subMenuItem.label);
      }
    }
  }, [location.pathname, items]);

  const onClick = (e) => {
    setCurrent(e.key);
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  };
  const pushtoHome = () => {
    history.push("/", { from: "Need" });
    // history.push('/' ,{ from: 'Needhelp' })
    props.toggleBoolean();
  };

  const pushtoMembership = () => {
    history.push("/services/membership", { from: "Plans" });
    props.toggleBoolean();
  };

  const handleNavigation = (path) => {
    history.push(path);
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  };
  const openLogin = (mode) => {
    setClick(!click);
    setMode(mode);
    setIsModalOpen(true);
  };
  const cartCount = (list) => {
    let count = 0;
    list?.patientMedicineOrder?.forEach((item) => {
      count = count + item.patientMedicineOrderDetailsList.length;
    });

    list?.patientLabTestsOrder?.forEach((item) => {
      count = count + item.patientLabTestsOrderDetailsList.length;
    });
    //  list?.patientLabTestsOrder.forEach(item => {
    //   count
    //  });
    console.log("lollss", count);
    return count;
  };

  const redirectTo = (event, location) => {
    event.preventDefault();
    history.push(location);
  };
  const handleCopy = async (coupon) => {
    try {
      await navigator.clipboard.writeText(coupon);
      message.success("Copied to clipboard!");
    } catch (err) {
      message.error("Failed to copy!");
    }
  };

  const content = (
    <div>
    <div className="w-auto py-1">
      <div className="flex px-1">
        <div className="flex justify-between flex-wrap mx-3 my-1 font-normal w-full">
          <div className="">
            <div
              className="w-full text-sm mb-2"
              style={{
                color: "#005D8D",
                fontWeight: "bold",
              }}
            >
              <span>CB_MED10</span>
              <CopyOutlined
                onClick={() => handleCopy("CB_MED10")}
                style={{
                  fontSize: "14px",
                  cursor: "pointer",
                  color: "#313131",
                  marginLeft: "12px",
                }}
              />
            </div>
            <div className="w-52 text-sm">
              <span>Flat 10% discount on all the medicines</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="w-auto py-1">
      <div className="flex px-1">
        <div className="flex justify-between flex-wrap mx-3 my-1 font-normal w-full">
          <div className="">
            <div
              className="w-full text-sm mb-2"
              style={{
                color: "#005D8D",
                fontWeight: "bold",
              }}
            >
              <span>CB_CONSULT10</span>
              <CopyOutlined
                onClick={() => handleCopy("CB_CONSULT10")}
                style={{
                  fontSize: "14px",
                  cursor: "pointer",
                  color: "#313131",
                  marginLeft: "12px",
                }}
              />
            </div>
            <div className="w-52 text-sm">
              <span>10% off on doctor Consultation</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );

  const notificationContent = (
    <div>
      {notificationData.length ? (
        notificationData.slice(0, 4).map((res, index) => {
          return (
            <div className="w-auto py-1">
              <div className="flex px-1">
                <div className="flex justify-between flex-wrap mx-3 my-1 font-normal w-full">
                  <div className="">
                    <div
                      className="w-full text-sm mb-2"
                      style={{
                        color: "#005D8D",
                        fontWeight: "bold",
                      }}
                    >
                      <span>{res?.subject}</span>
                    </div>
                    <div className="w-64 text-sm">
                      <span>{res?.content.slice(0, 250)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div> </div>
      )}
    </div>
  );

  return (
    <>
      <header className="bg-white h-20 flex items-center header-shadow">
        <div className="container mx-auto">
          <div className="flex justify-between items-center w-full">
            <div className="logo flex gap-3">
              <div className="w-full flex justify-end md:hidden">
                <MenuOutlined
                  onClick={() => setDrawerVisible(true)} // Open drawer on click
                  className="text-lg text-primaryColor cursor-pointer"
                />
              </div>
              <img
                src={Logo}
                alt="logo"
                className="cursor-pointer w-[210px] md:w-[170px]"
                onClick={() => handleNavigation("/")}
              />
            </div>

            <div className="flex md:flex w-[80%] mx-auto justify-center">
              <Menu
                onClick={onClick}
                multiple={false}
                selectedKeys={[current]}
                mode="horizontal"
                className="w-full justify-center hidden md:flex "
              >
                {items.map((item) => {
                  if (typeof item === "string") {
                    return <Menu.Item key={item}>{item}</Menu.Item>;
                  } else if (item.children) {
                    return (
                      <Menu.SubMenu
                        key={item.key}
                        title={item.label}
                        icon={item.icons}
                      >
                        {item.children.map((child) => {
                          return (
                            <Menu.Item key={child.label}>
                              <Link to={child.path}>{child.label}</Link>
                            </Menu.Item>
                          );
                        })}
                      </Menu.SubMenu>
                    );
                  } else {
                    if (item.label === "login") {
                      return (
                        <Menu.Item
                          key={item.label}
                          onClick={() => {
                            setIsModalOpen(true);
                            console.log("hi...");
                          }}
                        >
                          <Link>{item.label}</Link>
                        </Menu.Item>
                      );
                    } else {
                      if (Object.keys(userData).length === 0) {
                        if (item.label !== "Media & News") {
                          return (
                            <Menu.Item
                              key={item.label}
                              onClick={() => {
                                setIsModalOpen(true);
                              }}
                            >
                              <Link to={item.path}>{item.label}</Link>
                            </Menu.Item>
                          );
                        }
                      } else {
                        return (
                          <Menu.Item
                            key={item.label}
                            onClick={() => {
                              setIsModalOpen(false);
                            }}
                          >
                            <Link to={item.path}>{item.label}</Link>
                          </Menu.Item>
                        );
                      }
                    }
                  }
                })}
              </Menu>
            </div>

            {!cookies.cookieConsent && (
              <div className="cookie-consent fixed bottom-0 left-0 w-full bg-[#bfe4ff] py-4 px-6 flex justify-between items-center">
                <p className="text-sm">
                  We use cookies to enhance your user experience. By using our
                  website, you agree to our use of cookies.{" "}
                  <a href="/privacy-policy" className="underline">
                    Learn more.
                  </a>
                  <button
                    onClick={giveCookieConsent}
                    className="bg-[#004171] hover:bg-[#004171] text-White font-bold py-2 px-4 rounded ml-2"
                  >
                    Accept
                  </button>
                </p>
              </div>
            )}

            {Object.keys(userData).length === 0 ? (
              <div className="flex items-center justify-end gap-2 md:gap-3">
                <button
                  className="bg-primaryColor text-White md:flex justify-center items-center rounded-full text-xs md:text-base w-[60px] md:w-[100px] h-[28px]  md:h-[40px]"
                  onClick={() => openLogin("Login")}
                >
                  <span>Login</span>
                </button>
                <button
                  onClick={() => openLogin("Signup")}
                  className="bg-primaryColor text-White md:flex justify-center items-center  rounded-full text-xs md:text-base w-[70px] md:w-[100px] h-[28px] md:h-[40px]"
                >
                  <span>Sign Up</span>
                </button>
              </div>
            ) : (
              <></>
            )}
            {Object.keys(userData).length !== 0 && (
              <div className=" md:flex items-center">
                <div className="flex gap-2 md:gap-5 items-center">
                  <div className=" sm:block relative">
                    <button
                      className="w-[25px] md:w-[30px] "
                      onClick={() => handleNavigation("/medical-summary")}
                    >
                      <img
                        className="cursor-pointer"
                        src={MedicalSummaryIcon}
                        alt="BellIcon"
                      />
                    </button>
                  </div>
                  <div className="hidden sm:block relative">
                    <Popover
                      open={open}
                      onOpenChange={handleOpenChange}
                      trigger="click"
                      content={content}
                    >
                      <div>
                        <p className="text-size bg-secondaryColor top-[-4px] right-[-4px] absolute text-center text-[10px] text-White font-semibold bg-brand-lightgreen rounded-xl h-[16px] w-[17px]">
                          3+
                        </p>
                        <button className="w-[40px]">
                          <img
                            className="h-10 cursor-pointer"
                            src={Voucher_animated}
                            alt="Voucher_animated"
                          />
                        </button>
                      </div>
                    </Popover>
                  </div>

                  <div className=" sm:block relative">
                    <Popover
                      open={openNotification}
                      onOpenChange={handleOpenNotificationChange}
                      trigger="click"
                      content={notificationContent}
                    >
                      <div>
                        <p className="text-size bg-secondaryColor bottom-4 left-2 absolute text-center text-[10px] text-White font-semibold bg-brand-lightgreen rounded-xl h-[16px] w-[17px]">
                          {notificationData.length > 9
                            ? "9+"
                            : notificationData.length}
                        </p>
                        <button className="w-[30px]">
                          <img
                            className="h-5 cursor-pointer"
                            src={BellIcon}
                            alt="BellIcon"
                          />
                        </button>
                      </div>
                    </Popover>
                  </div>

                  <div className=" sm:block  relative">
                    {cartList?.patientMedicineOrder?.length > 0 ||
                    cartList?.patientLabTestsOrder?.length > 0 ? (
                      <div>
                        <p className="text-size bg-[red] top-[-4px] right-[-4px] absolute text-center text-[10px] text-White font-semibold bg-brand-lightgreen rounded-xl h-[15px] w-[15px]">
                          {cartCount(cartList) <= 9
                            ? cartCount(cartList)
                            : "9+"}
                        </p>
                        <button
                          className="w-[20px]"
                          // onClick={() => {
                          //   history.push("/cartPage");
                          // }}
                          onClick={() => handleNavigation("/cart")}
                        >
                          <img
                            className="h-6 w-full cursor-pointer"
                            src={Cart}
                            alt="Cart"
                          />
                        </button>
                      </div>
                    ) : (
                      <button
                        className="w-[20px]"
                        // onClick={() => {
                        //   history.push("/cartPage");
                        // }}
                        onClick={() => handleNavigation("/cart")}
                      >
                        <img
                          className="h-6 w-full cursor-pointer"
                          src={Cart}
                          alt="Cart"
                        />
                      </button>
                    )}
                  </div>

                  {/* <div className="hidden sm:block relative">
                    <button className="w-[30px]">
                      <p
                        className="flex text-black text-xs sm:text-sm font-medium"
                        onClick={pushtoMembership}
                      >
                        <img
                          className="h-8 w-full cursor-pointer"
                          src={Membership_icon1}
                          alt="Membership_icon1"
                        />
                      </p>
                    </button>
                  </div> */}
                  <DummyRoute />
                </div>
              </div>
            )}
          </div>
        </div>
        <Drawer
          title="Menu"
          placement="right"
          onClose={() => setDrawerVisible(false)}
          visible={drawerVisible}
        >
          <Menu onClick={onClick} mode="inline" selectedKeys={[current]}>
            {items.map((item) => {
              if (typeof item === "string") {
                return <Menu.Item key={item}>{item}</Menu.Item>;
              } else if (item.children) {
                return (
                  <Menu.SubMenu
                    key={item.key}
                    title={item.label}
                    icon={item.icons}
                  >
                    {item.children.map((child) => {
                      return (
                        <Menu.Item key={child.label}>
                          <Link to={child.path}>{child.label}</Link>
                        </Menu.Item>
                      );
                    })}
                  </Menu.SubMenu>
                );
              } else {
                return (
                  <Menu.Item key={item.label}>
                    <Link to={item.path}>{item.label}</Link>
                  </Menu.Item>
                );
              }
            })}
          </Menu>
        </Drawer>
      </header>
      {isModalOpen && (
        <Login
          isModalOpen={isModalOpen}
          mode={mode}
          click={click}
          handleClose={handleClose}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  booleanValue: state.booleanValue,
});

const mapDispatchToProps = {
  toggleBoolean,
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
