import React, {useEffect, useState} from "react";
import { DownOutlined } from "@ant-design/icons";
import { Button, Popover } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import {
    setAddressString,
    setLatLong,
    actioncustomPinCode,
    setRadius,
  } from "../../../Redux/Action/userActions";
import LocateIcon from "../../../Assets/Images/locate.svg";
import { LocationMarkerIcon } from "@heroicons/react/solid";

const LocationPicker = ({getLocation}) => {
    const [error, setError] = useState({
        isMMILoading: false,
        locationError: "",
      });
    const dispatch = useDispatch();
    const patientinfo = useSelector((state) => state.patientinfo);
    const { patientinfoData, isLoading, isSuccess } = patientinfo;
    const [open, setOpen] = useState(false);
  const {customPinCode} = useSelector((state) => state.authReducer)
    const saveAddress = (pincode, city = "") =>{
        const pincodePackage = require("pincode-lat-long");
        let coords = pincodePackage.getlatlong(pincode);
        if (coords == undefined) {
            setError({
              locationError: "Please use valid pincode",
            });
            return;
          }
          let coordsObj = {
            lat: coords.lat,
            long: coords.long,
          };
          // dispatch(setLatLong(coordsObj));
          if(city.length){
            dispatch(setAddressString(city));
          }
          dispatch(
            actioncustomPinCode({
              pincode: pincode,
              location: "profileaddress",
            })
          );
          dispatch(setLatLong(coordsObj))
          sessionStorage.setItem("customPinCode" , pincode)
          sessionStorage.setItem("coords" , JSON.stringify(coordsObj))
    }

    const handleSavedAddressClick = () => {
        setError({
          locationError: "",
        });
    
        if (patientinfoData?.pinCode) {
          let city = patientinfoData?.city;
          let pinCode = patientinfoData?.pinCode;
          saveAddress(pinCode, city)
          // setLocation();
        }
      };

      const handleRadiusChange =(e) =>{
        dispatch(setRadius(e.target.value))
      }

    const popoverContent = () =>{
        return(
            <div>
            <div className="p-2">
              <div>
                <h1 className="text-primaryColor text-base font-medium">
                  Where do you want the delivery?
                </h1>
                <span className="text-textGray text-sm font-light">
                  Get access to your Addresses and Orders
                </span>
                <div
                  className={`${
                    patientinfoData?.address1 === null ||
                    patientinfoData?.address2 === null ||
                    patientinfoData?.address1 === undefined ||
                    patientinfoData?.address2 === undefined ||
                    patientinfoData?.city === undefined ||
                    patientinfoData?.pinCode === undefined
                      ? "hidden"
                      : "block"
                  }`}
                >
                  <div
                    className="cursor-pointer  w-full p-4 mt-2 bg-lightBlue border border-[#bee0ff] rounded  mb-1"
                    onClick={handleSavedAddressClick}
                  >
                    <p className="text-primaryColor  text-sm font-normal ">
                      {patientinfoData?.address1 +
                        ", " +
                        patientinfoData?.address2 +
                        ", " +
                        patientinfoData?.city +
                        ", " +
                        patientinfoData?.pinCode}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-1 py-1">
              <div className="">
                <h1 className="text-primaryColor text-base font-medium">
                  Enter Pincode or Radius
                </h1>
                <span className="text-textGray text-sm font-light">
                  Select pincode or Radius to see product availability.
                </span>
                <div className="flex  gap-2">
                  <div>
                    <input
                      className="border border-gray h-[50px] text-sm w-full py-1 pl-2 my-3 rounded"
                      onChange={(e) => e.target.value.length == 6 && saveAddress(e.target.value)}
                      maxLength={6}
                      placeholder="Enter Pincode"
                      type="number"
                    />
                  </div>
                  <div  >
                    <select
                      name="radius"
                      id="radius"
                      onChange={handleRadiusChange}
                     className="border border-gray h-[50px]  text-sm  py-1 pl-2 my-3 rounded sm:w-[180px] w-[120px] text-textGray"
                    >
                       <option className=" text-sm font-light" >Select Services within</option>
                      <option value="5" >5 km</option>
                      <option value="10" >10km</option>
                      <option value="15" >15km</option>
                      <option value="25" >25km</option>
                      <option value="50" >50km</option>
                      <option value="100" >100km</option>
                    </select>
                  </div>
                </div>

                <div
                  className="flex gap-1 items-center cursor-pointer content-center bg-lightGreen"
                  onClick={getLocation}
                  role="button"
                >
                  <img
                    src={LocateIcon}
                    alt=""
                    draggable={false}
                    className="h-8 w-8"
                  />
                  <p className="text-secondaryColor  flex items-center text-base font-medium">
                    Detect my location{" "}
                    {error.isMMILoading && (
                      <div className="loader ml-2 float-right ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-5 w-5"></div>
                    )}
                  </p>
                </div>
                {error.locationError && (
                  <div className="text-xs font-semibold text-red-500">
                    {" "}
                    {error.locationError}{" "}
                  </div>
                )}
              </div>
            </div>
          </div>
        )
    }

    const hide = () => {
        setOpen(false);
      };
      const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
      };

    return(
        <Popover
        content={popoverContent}
        title=""
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
      >
            <button onClick={(e) => {e.stopPropagation();}} className="flex items-center justify-center px-4 ml-2 sm:ml-0" style={{borderRadius:"20px 0px 0px 20px", backgroundColor: "white", height: 44}} >
          <LocationMarkerIcon
            style={{
              height: "21px",
              color: "#5dbb63",
              marginRight: "10px",
            }}
          />
          <p className="text-sm  font-semibold text-black-700">
            {customPinCode}
          </p>
      </button>
      </Popover>
    )
}


export default LocationPicker;
