import React, { useState } from 'react';
import HIW1 from "../../../Assets/Images/labinsightstep1.svg";
import HIW2 from "../../../Assets/Images/labinsightstep2.svg";
import HIW3 from "../../../Assets/Images/labinsightstep3.svg";
import HIW4 from "../../../Assets/Images/labinsightstep4.svg";
import SmartVitalsImage1 from "../../../Assets/Images/Frame 1321317831 (1).svg";
import SmartVitalsImage2 from "../../../Assets/Images/image (6).svg";
import SmartVitalsImage3 from "../../../Assets/Images/image (7).svg";
import SmartVitalsImage4 from "../../../Assets/Images/image (8).svg";
import SubHeader from "../../Shared/SearchBar";
import Layouts from "../../Layouts/index";

import TrustIcon from "../../../Assets/Images/Icons/Trust Icon.svg";
import TrustIcon1 from "../../../Assets/Images/Icons/TrustIcon1.svg";
import docticon from "../../../Assets/Images/doctor_ai.svg";
import CARESATHIIMAGE from "../../../Assets/Images/careSathiMain.svg";
import LABREPORTIMAGE from '../../../Assets/Images/LabReportAnayser/lab-report-image.png'
import DENTISTRYIMAGE from "../../../Assets/Images/dentalCare.svg";
import Docmob from "../../../Assets/Images/docmob.svg";
import LABREPORTANALYSERIMAGE from "../../../Assets/Images/careSathiMain.svg";
import phoneVideoImage from "../../../Assets/Images/phoneVideo.svg";
import smartVitals from "../../../Assets/Images/smartVitals.svg";
import LanInsidevvid from "../../../Assets/Images/labinsight_video.mp4";
const Labinsight = () => {
  return (
    <Layouts style={{ backgroundColor: "#004171" }}>
      {/* Title Content */}
      {/* <SubHeader /> */}
      <section className="home-banner min-h-[70vh] my-8  ">
        <div className="container mx-auto">
          <div className="bg-primaryColor absolute left-0 md:w-[90%] h-[70vh] rounded-e-xl"></div>
          <div className="md:grid grid-cols-2 gap-5 min-h-[70vh] items-center relative">
            <div className="relative z-1 pt-7 md:pt-5 p-5 md:p-0 rounded-2xl bg-[#F3F8FF] md:bg-primaryColor">
              <span className="text-secondaryColor text-sm md:text-6xl font-medium  mb-5 md:mb-3">
                LabInsights
              </span>
              <h1 className="text-primaryColor md:text-White text-2xl md:text-3xl font-bold sm:mt-8 mt-0 ">
                From Uncertainty To Clarity,
              </h1>
              <span className="text-secondaryColor text-sm md:text-3xl font-medium  mb-1 md:mb-3">
                We Are With You
              </span>

              <p className="text-primaryColor md:text-White font-extralight text-sm md:text-base mt-2 mb-4">
                LabInsights transforms complex lab results into clear,
                meaningful insights, providing clarity and comfort when faced
                with overwhelming medical data. It turns confusion into
                reassurance, empowering you to make informed decisions and
                manage your health confidently. With direct access to CureBay’s
                network, LabInsights ensures you're never alone on your wellness
                journey.
              </p>
              <a
                className=" rounded-lg px-4 py-2 mr-2 mt-4 bg-secondaryColor"
                style={{ color: "#ffff" }}
                href="ask-care-sathi?lab_report_analyser=true&popup=true"
              >
                Analyse Lab Reports
              </a>
            </div>

            <div className="hidden sm:block z-1 absolute right-24 top-12">
            <video
                      className="w-full h-[500px] rounded-[20px]"
                      autoPlay
                      loop
                      muted
                      preload="auto"
                      playsInline   
                    >
                      <source
                        src={LanInsidevvid}
                        type="video/mp4"
                      />
                    </video>
            </div>
            <div className="flex md:hidden justify-center items-center gap-3 mt-5">
              <img src={TrustIcon1} alt="images" width={25} />
              <p className="text-primaryColor font-extralight text-sm">
                Trusted by{" "}
                <span className="font-medium">Over 100,000 Patients</span>
              </p>
            </div>
          </div>
          {/* <div className="swipicon">
                          <img src={Swipicon} alt="images" />
                      </div> */}
        </div>
      </section>
      <section className="">
        <div className="container mx-auto py[50px] md:py-[90px]">
          <h2 className="text-center text-2xl md:text-4xl font-semibold text-primaryColor leading-normal mb-8 md:mb-[50px]">
            How it <span className="text-secondaryColor">Works</span>?
          </h2>
          <div className="px-[14px] pb-6 sm:pb-auto sm:px-auto md:w-[80%] mx-auto flex sm:flex-row flex-col justify-between items-start gap-10">
            {/* Step 1 */}
            <div className="gap-3 sm:gap-auto flex sm:flex-col flex-row text-left">
              <div className="bg-[#F0F8FF] rounded-full w-32 sm:h-20 h-14 flex items-center justify-center mb-4">
                <img src={HIW1} alt="Step 1" className="w-16 sm:h-16 h-10" />
              </div>
              <div className="flex flex-col">
                <div
                  className="sm:text-base text-sm font-semibold text-left"
                  style={{ color: "#42BA85" }}
                >
                  STEP 1
                </div>
                <div
                  className="text-base font-semibold text-left"
                  style={{ color: "#004171" }}
                >
                  Upload Your Lab Report
                </div>
                <p
                  className="sm:text-sm text-[13px] font-medium"
                  style={{ color: "#676C80" }}
                >
                  Quickly upload your lab report—LabInsights analyzes it in seconds
                </p>
              </div>
            </div>

            {/* Step 2 */}
            <div className="flex gap-3 sm:gap-auto  sm:flex-col flex-row">
              <div className="bg-[#F0F8FF] rounded-full sm:w-20 w-32 sm:h-20 h-14 flex items-center justify-center mb-4">
                <img src={HIW2} alt="Step 2" className="w-16 sm:h-16 h-10" />
              </div>
              <div className="flex flex-col">
                <div
                  className="sm:text-base text-sm font-semibold"
                  style={{ color: "#42BA85" }}
                >
                  STEP 2
                </div>
                <div
                  className="text-base font-semibold"
                  style={{ color: "#004171" }}
                >
                 Clear Analysis
                </div>
                <p
                  className="sm:text-sm text-[13px] font-medium"
                  style={{ color: "#676C80" }}
                >
                  Converts complex jargon into easy-to-understand information.
                </p>
              </div>
            </div>

            {/* Step 3 */}
            <div className="flex gap-3 sm:gap-auto  sm:flex-col flex-row">
              <div className="bg-[#F0F8FF] rounded-full w-28 sm:h-20 h-14 flex items-center justify-center mb-4">
                <img src={HIW3} alt="Step 3" className="w-16 sm:h-16 h-10" />
              </div>
              <div className="flex flex-col">
                <div
                  className="sm:text-base text-sm font-semibold"
                  style={{ color: "#42BA85" }}
                >
                  STEP 3
                </div>
                <div
                  className="text-base font-semibold"
                  style={{ color: "#004171" }}
                >
                  Personalised Insights
                </div>
                <p
                  className="sm:text-sm text-[13px] font-medium"
                  style={{ color: "#676C80" }}
                >
                  Provides actionable advice for informed health decisions
                </p>
              </div>
            </div>

            {/* Step 4 */}
            <div className="flex gap-3 sm:gap-auto  sm:flex-col flex-row ">
              <div className="bg-[#F0F8FF] rounded-full w-32 sm:h-20 h-14 flex items-center justify-center mb-4">
                <img src={HIW4} alt="Step 4" className="w-16 sm:h-16 h-10" />
              </div>
              <div className="flex flex-col">
                <div
                  className="sm:text-base text-sm font-semibold"
                  style={{ color: "#42BA85" }}
                >
                  STEP 4
                </div>
                <div
                  className="text-base font-semibold"
                  style={{ color: "#004171" }}
                >
                  Stay Connected
                </div>
                <p
                  className="sm:text-sm text-[13px] font-medium"
                  style={{ color: "#676C80" }}
                >
                  Connects you with CureBay’s experts for further consultations or treatments.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-[#f3f8ff]">
        <div className="container mx-auto py[50px] md:py-[90px] px-4">
          <h2 className="text-center pt-5 sm:pt-auto text-2xl md:text-4xl font-semibold text-primaryColor leading-normal mb-6 md:mb-[50px]">
            What You Can <span className="text-secondaryColor">Expect</span>
          </h2>
          <div className="flex justify-center items-center min-h-screen">
            <div className="grid sm:grid-cols-2 grid-cols-1 sm:gap-8 gap-0">
              <div className="flex flex-col items-center">
                <img src={SmartVitalsImage1} alt="Touch-Free Monitoring" />
                <div
                  style={{ backgroundColor: "#ffff" }}
                  className="sm:w-[68%] w-[90%] rounded-xl relative bottom-8 left-0 px-4 py-2"
                >
                  <p
                    style={{ color: "#004171" }}
                    className="sm:text-xl text-base font-semibold"
                  >
                    Clarity and Comfort
                  </p>
                  <p
                    style={{ color: "#676C80" }}
                    className="sm:text-base text-sm font-medium"
                  >
                    Emotionally intelligent interpretations for clear,
                    reassuring insights.
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center">
                <img src={SmartVitalsImage2} alt="Quick & Accurate Readings" />
                <div
                  style={{ backgroundColor: "#ffff" }}
                  className="sm:w-[68%] w-[90%] rounded-xl relative bottom-8 left-0 px-4 py-2"
                >
                  <p
                    style={{ color: "#004171" }}
                    className="sm:text-xl text-base font-semibold"
                  >
                    Instant Analysis
                  </p>
                  <p
                    style={{ color: "#676C80" }}
                    className="sm:text-base text-sm font-medium"
                  >
                    Quick, personalized breakdowns for peace of mind before your
                    next appointment
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center">
                <img src={SmartVitalsImage3} alt="Convenient Setup" />
                <div
                  style={{ backgroundColor: "#ffff" }}
                  className="sm:w-[68%] w-[90%] rounded-xl relative bottom-8 left-0 px-4 py-2"
                >
                  <p
                    style={{ color: "#004171" }}
                    className="sm:text-xl text-base font-semibold"
                  >
                    Empowerment
                  </p>
                  <p
                    style={{ color: "#676C80" }}
                    className="sm:text-base text-sm font-medium"
                  >
                    Deep understanding of results to take control of your health
                    decisions.
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center">
                <img src={SmartVitalsImage4} alt="Peace Of Mind" />
                <div
                  style={{ backgroundColor: "#ffff" }}
                  className="sm:w-[68%] w-[90%] rounded-xl relative bottom-8 left-0 px-4 py-2"
                >
                  <p
                    style={{ color: "#004171" }}
                    className="sm:text-xl text-base font-semibold"
                  >
                    Seamless Connection
                  </p>
                  <p
                    style={{ color: "#676C80" }}
                    className="sm:text-base text-sm font-medium"
                  >
                    Direct access to CureBay’s services for ongoing support and
                    expert advice.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-lightGray mb-4">
        <div className="bg-[#023b72] py-12 flex flex-col justify-center items-center min-h-screen">
          {/* Main Container */}

          <div className=" block sm:hidden text-center  z-10">
            <h2 className="text-3xl font-bold mb-2 text-White">
              Check Our Other
            </h2>
            <h3 className="text-3xl font-bold text-[#42BA85] mb-6">AI Tools</h3>
          </div>
          <div className="w-full max-w-5xl rounded-2xl flex flex-col  items-start justify-between relative overflow-hidden">
            {/* Left Section with Heading */}

            <div className=" hidden sm:flex w-full  max-w-5xl bg-[#0a5a9c]  rounded-t-2xl  items-start justify-between relative overflow-hidden">
              <div className="text-white z-10 md:w-1/2 sm:pl-[50px] sm:pt-[100px]">
                <h2 className="text-3xl font-bold mb-2 text-White">
                  Check Our Other
                </h2>
                <h3 className="text-3xl font-bold text-[#42BA85] mb-6">
                  AI Tools
                </h3>
              </div>

              {/* Background Image */}

              <img
                src={docticon}
                alt="Doctor"
                className="  md:w-[66%] w-full object-cover rounded-t-2xl "
              />
            </div>

            {/* AI Tools Cards Section */}
            <div className=" rounded-b-xl bg-[#0a5a9c] grid grid-cols-1 md:grid-cols-3 gap-6 z-10 mt-8 md:mt-0 px-[50px] py-[45px]">
              {/* Card 1 */}
              <div className="bg-white rounded-xl overflow-hidden ">
                <img
                  src={smartVitals}
                  alt="Labinsight"
                  className="w-full object-cover"
                />
                <div
                  className="p-4 bg-White rounded-b-xl "
                  style={{ height: "132px" }}
                >
                  <h4 className="text-lg font-semibold text-[#004171]">
                    SmartVitals
                  </h4>
                  <p className="text-sm text-[#676C80]">
                    Blood pressure, pulse rate, heart rate without touch
                  </p>
                  <a
                    href="/smart-vitals-detail"
                    className="text-[#42BA85] text-sm font-medium mt-2 inline-block"
                  >
                    Know More &rarr;
                  </a>
                </div>
              </div>

              {/* Card 2 */}
              <div className="bg-white rounded-xl overflow-hidden">
                <img
                  src={LABREPORTANALYSERIMAGE}
                  alt="Smart Vitals"
                  className="w-full  object-cover"
                />
                <div className="p-4  bg-White rounded-b-xl ">
                  <h4 className="text-lg font-semibold text-[#004171]">
                    CareSathi
                  </h4>
                  <p className="text-sm text-[#676C80]">
                    Your trusted friend in healing, always by your side
                  </p>
                  <a
                    href="/care-sathi"
                    className="text-[#42BA85] text-sm font-medium mt-2 inline-block"
                  >
                    Know More &rarr;
                  </a>
                </div>
              </div>

              {/* Card 3 */}

              <div className="bg-white rounded-xl overflow-hidden">
                <img
                  src={DENTISTRYIMAGE}
                  alt="AI Powered Dentistry"
                  className="w-full  object-cover"
                />
                <div
                  className="p-4  bg-White rounded-b-xl"
                  style={{ height: "128px" }}
                >
                  <h4 className="text-lg font-semibold text-[#004171]">
                    DentalCare
                  </h4>
                  <p className="text-sm text-[#676C80]">
                    Transform your smile with technology
                  </p>
                  <a
                    href="/services/dentalcare"
                    className="text-[#42BA85] text-sm font-medium mt-2 inline-block"
                  >
                    Know More &rarr;
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layouts>
  );
};

export default Labinsight;
