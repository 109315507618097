import Search from "antd/es/input/Search";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "@headlessui/react";
import { SearchIcon } from "@heroicons/react/outline";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import LocateIcon from "../../../Assets/Images/locate.svg";
import {
  getPatientDetails,
  patientaddresslists,
} from "../../../Redux/Action/UserprofileActions";
import hospitalservice from "../../../Redux/Services/hospitalservice";
import doctorService from "../../../Redux/Services/doctorService";
import { secureStorage } from "../../../Redux/Reducer/authReducer";
import axios from "axios";
import http from "../../../Redux/Services/http-common";
import { APP_ROUTES } from "../../PageRouting/AppRoutes";
import {
  setAddressString,
  setLatLong,
  actioncustomPinCode,
  //actioncustomRadius
} from "../../../Redux/Action/userActions";
import { debounce } from "lodash";
import { CloseOutlined } from "@ant-design/icons";
import "../../../Assets/Css/slide_vertical.css";
import { AutoComplete, Input } from "antd";
import LocationPicker from "./LocationPicker";
import { LocationMarkerIcon } from "@heroicons/react/solid";
import "./index.css";
// import locationSetter from "../../utils/locationSetter";

const words = [
  "Medicines or Healthcare Products",
  "Lab Tests",
  "Surgery",
  "Doctors or symtoms or speciality",
  "Hospitals",
];

function SearchBar({ visible = true, searchedText = () => {} }) {
  const history = useHistory();
  const prevScrollY = useRef(0);
  const menuRef = useRef();
  const locationDropdownRef = useRef(null);
  const [wordIndex, setWordIndex] = useState(0);
  const location = useLocation();
  const pincodelocation = useSelector(
    (state) => state.authReducer.pincodelocation
  );
  const address = useSelector((state) => state.authReducer.address);
  const userData = useSelector((state) => state.authReducer.patientData);
  const dispatch = useDispatch();
  const [screen, setscreen] = useState(window.innerWidth);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const patientinfo = useSelector((state) => state.patientinfo);
  const { patientinfoData, isLoading, isSuccess } = patientinfo;
  const addressinfo = useSelector((state) => state.patientaddresslist);
  const { patientaddressinfoData } = addressinfo;
  const commonHealtList = useSelector((state) => state.doctspecialitylist);
  const { doctspecialityData } = commonHealtList;
  const [searchData, setSearchData] = useState();
  const { radius, customPinCode, coords } = useSelector(
    (state) => state.authReducer
  );
  const [newPinCode, setPinCode] = useState("");
  const [banner, setBanner] = useState("");
  const [searchedLabtest, setSearchedLabtest] = useState([]);
  const [pincoder, setPincode] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [error, setError] = useState({
    isMMILoading: false,
    locationError: "",
  });
  const [placeholderText, setPlaceholderText] = useState("Search for ");

  console.log(location, "dsviodsbvousdbvuosdvds");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setBanner(location.pathname);
  }, [location]);

  React.useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  // const history = useHistory()

  useEffect(() => {
    const interval = setInterval(() => {
      setWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 3000); // Change every 3 second

    return () => clearInterval(interval); // Clean up the interval
  }, []);

  useEffect(() => {
    if (location.pathname == APP_ROUTES.HOSPITALSERVICE) {
      setPlaceholderText(`Search for ${words[4]}`);
    } else if (location.pathname == APP_ROUTES.DIAGNOSTICDETAILS) {
      setPlaceholderText(`Search for ${words[1]}`);
    } else if (location.pathname == APP_ROUTES.MEDICINE) {
      setPlaceholderText(`Search for ${words[0]}`);
    } else if (location.pathname == APP_ROUTES.BOOKANAPPOINTMENT) {
      setPlaceholderText(`Search for ${words[3]}`);
    } else {
      setPlaceholderText(`Search for ${words[wordIndex]}`);
    }
  }, [wordIndex, location.pathname]);

  useEffect(() => {
    if (userData?.code) {
      console.log("in UseEffect 2", userData);
      dispatch(getPatientDetails(userData.code)).then((res) => {
        let coords = {};
        let pinCode = "751009";
        if (res?.pinCode && pincodelocation != "map") {
          let city = res?.city;
          pinCode = res.pinCode;
          dispatch(setAddressString(city));
        }
        const pincodePackage = require("pincode-lat-long");
        coords = pincodePackage.getlatlong(pinCode);
        if (coords == undefined) {
          setError({
            locationError: "Please use valid pincode in profile",
          });
          return;
        }
        console.log(pinCode, coords, "AObuosdcsduvsdvdsvd");
        if (!customPinCode) {
          dispatch(
            actioncustomPinCode({
              pincode: pinCode,
              location: "current",
            })
          );
          dispatch(setLatLong(coords));
          sessionStorage.setItem("customPinCode", pinCode);
          sessionStorage.setItem("coords", JSON.stringify(coords));
        }
      });

      dispatch(patientaddresslists(userData.code));
    } else {
      if (!customPinCode) {
        const pincodePackage = require("pincode-lat-long");
        const coords = pincodePackage.getlatlong("751009");
        dispatch(
          actioncustomPinCode({
            pincode: "751009",
            location: "current",
          })
        );
        dispatch(setLatLong(coords));
        sessionStorage.setItem("customPinCode", "751009");
        sessionStorage.setItem("coords", JSON.stringify(coords));
      }
    }
  }, [userData.code]);

  const DEBOUNCE_DELAY = 300;
  const debouncedSearch = useRef(
    debounce(async (coords, searchTerm, radius) => {
      if (searchTerm) {
        // Initialize separate arrays for different types of data
        let api_list_doctor = [];
        let api_list_hospital = [];
        let api_list_lab = [];
        let api_list_medicine = [];
        let api_list_surgery = [];

        // console.log(coords,"coooordssss1")

        // Make API calls for doctors
        if (
          location.pathname !== APP_ROUTES.HOSPITALSERVICE &&
          location.pathname !== APP_ROUTES.DIAGNOSTICDETAILS &&
          location.pathname !== APP_ROUTES.MEDICINE
        ) {
          const resDoctor = await doctorService.esgetDoctorslist(
            coords,
            searchTerm,
            1,
            40,
            radius
          );
          resDoctor.data?.forEach((search) => {
            api_list_doctor.push({
              code: search.userId,
              text: search.userName,
              symptoms: search.symptoms,
              specialities: search.specialities,
            });
          });
        }

        if (
          location.pathname !== APP_ROUTES.BOOKANAPPOINTMENT &&
          location.pathname !== APP_ROUTES.DIAGNOSTICDETAILS &&
          location.pathname !== APP_ROUTES.MEDICINE
        ) {
          const resHospital = await hospitalservice.esgetHopitalslist(
            coords,
            searchTerm,
            1,
            40,
            radius
          );
          resHospital.data?.forEach((search) => {
            api_list_hospital.push({
              code: search.code,
              text: search.hospitalName,
            });
          });
        }

        if (
          location.pathname !== APP_ROUTES.BOOKANAPPOINTMENT &&
          location.pathname !== APP_ROUTES.HOSPITALSERVICE &&
          location.pathname !== APP_ROUTES.MEDICINE
        ) {
          const resLab = await axios.get(
            `${process.env.REACT_APP_ELASTIC_BASEURL}labtest?latitude=${coords.lat}&longitude=${coords.long}&freeTextSearch=${searchTerm}&source=b2c&radius=${radius}`
          );

          resLab.data?.forEach((search) => {
            api_list_lab.push({
              code: search.labTestCode,
              text: search.testDisplayName,
              otherNames: search.testKnownAs,
            });
          });
        }

        if (
          location.pathname !== APP_ROUTES.BOOKANAPPOINTMENT &&
          location.pathname !== APP_ROUTES.HOSPITALSERVICE &&
          location.pathname !== APP_ROUTES.DIAGNOSTICDETAILS
        ) {
          const resMedicine = await axios.get(
            `${process.env.REACT_APP_ELASTIC_BASEURL}medicine?freeTextSearch=${searchTerm}&pageNo=1&pageSize=20`
          );
          resMedicine.data?.forEach((search) => {
            api_list_medicine.push({
              code: search.id,
              text: search.medicineName,
              symptoms: "",
            });
          });
        }

        // Make API calls for surgery
        // const resSurgery = await axios.get(`${process.env.REACT_APP_ELASTIC_BASEURL}surgeries?freeTextSearch=${searchTerm}&pageNo=1&pageSize=200`);
        // resSurgery.data?.forEach((search, index) => {
        //   api_list_surgery.push({
        //     code: search.surgeryId,
        //     text: search.surgeryName,
        //     seoName: search.seoNameId,
        //     index: index,
        //   });
        // });

        // Concatenate all lists
        let api_list = [
          ...api_list_doctor,
          ...api_list_hospital,
          ...api_list_lab,
          ...api_list_medicine,
          ...api_list_surgery,
        ];

        // Set the concatenated list as the search data
        setSearchData(api_list);
        let result = api_list.map((res) => {
          return {
            value: res.text,
            label: (
              <>
                <div className=" font-medium text-sm mt-2 ml-2 flex">
                  <div className="w-8">
                    <SearchIcon
                      className="h-6 p-1 rounded-full mr-2 w-6"
                      color={"#AEAEAE"}
                    />
                  </div>
                  {res.specialities ? (
                    <div className=" flex flex-col gap-2 justify-between ">
                      <div className="text-secondaryColor">
                        {res.text + " "}
                      </div>
                      <div className="text-secondaryColor text-xs font-normal">
                        {res.specialities + " "}
                      </div>
                    </div>
                  ) : (
                    <div className="text-secondaryColor">{res.text + " "}</div>
                  )}
                </div>
                {res.otherNames && (
                  <div
                    style={{
                      display: "flex",
                      marginLeft: "40px",
                    }}
                  >
                    <div className="text-xs font-medium">
                      <span className="text-xs font-bold text-gray-800">
                        Also know as:{" "}
                      </span>
                      {" " + res.otherNames}
                    </div>
                  </div>
                )}
              </>
            ),
          };
        });
        setSearchResults(result);
        // setSearchData( api_list_doctor,
        //   api_list_hospital,
        // );
      } else {
      }
    }, DEBOUNCE_DELAY)
  ).current;

  const onSearchText = (text) => {
    setSearchValue(text);
    // setSearchText(text);
    setSearchData([]);
    // const searchTerm = e.target.value;
    debouncedSearch(coords, text, radius);
  };

  const redirectTo = (e = "", text, i) => {
    if (text.length == 0) return;

    if (location.pathname == APP_ROUTES.BOOKANAPPOINTMENT) {
      searchedText(text);
      return;
    }

    if (location.pathname == APP_ROUTES.DIAGNOSTICDETAILS) {
      searchedText(text);
      return;
    }

    if (location.pathname == APP_ROUTES.MEDICINE) {
      searchedText(text);
      return;
    }

    if (text) {
      history.push(`/all/${text}`);
    }

    // if (location.pathname == "/" || location.pathname == "/why_curebay") {
    //   console.log(searchData, i, "dvsdvusdguvids");
    //   if (!text) {
    //     return;
    //   }
    //   if (searchData.find((res) => res.text == text)?.seoName) {
    //     history.push(
    //       `/surgerydetails/${
    //         searchData.find((res) => res.text == text).seoName
    //       }`
    //     );
    //   } else if (text) {
    //     history.push({
    //       pathname: APP_ROUTES.ALL_SEARCH,
    //       search: `?search_query=${text}`,
    //     });
    //   } else {
    //     history.push({ pathname: APP_ROUTES.ALL_SEARCH });
    //   }
    // } else if (location.pathname === "/hospital") {
    //   if (text) {
    //     history.push({
    //       pathname: APP_ROUTES.HOSPITAL,
    //       search: `?search_query=${text}`,
    //     });
    //   } else {
    //     history.push({ pathname: APP_ROUTES.HOSPITAL });
    //   }
    //   // })seoNameId
    // } else if (
    //   location.pathname === "/surgery" ||
    //   location.pathname.includes("surgery")
    // ) {
    //   if (!text) {
    //     return;
    //   }
    //   if (searchData[i]?.seoName) {
    //     history.push(`/surgerydetails/${searchData[i]?.seoName}`);
    //   }
    // } else if (location.pathname === "/find-doctors") {
    //   doctorService.esgetDoctorslist(coords, text, 1, 20).then((res) => {
    //     let data = res?.data.map((search) => {
    //       return {
    //         code: search?.code,
    //         text: search?.userName,
    //         symptoms: search?.symptoms,
    //       };
    //     });
    //     setSearchData([]);
    //     setSearchData(data);
    //   });

    //   if (text) {
    //     history.push({
    //       pathname: APP_ROUTES.FINDDOCTORS,
    //       search: text,
    //     });
    //   } else {
    //     history.push({ pathname: APP_ROUTES.FINDDOCTORS });
    //   }
    //   // })
    // } else if (location.pathname === "/all") {
    //   if (text) {
    //     history.push({
    //       pathname: APP_ROUTES.ALL_SEARCH,
    //       search: `?search_query=${text}`,
    //     });
    //   } else {
    //     history.push({ pathname: APP_ROUTES.ALL_SEARCH });
    //   }
    // } else if (
    // //   location.pathname === "/labtests" ||
    //   location.pathname === "/services/lab"
    // ) {
    //   if (text) {
    //     history.push({
    //       pathname: APP_ROUTES.LAB,
    //       search: `?search_query=${text}`,
    //     });
    //   } else {
    //     history.push({ pathname: APP_ROUTES.LAB });
    //   }
    // } else if (
    // //   location.pathname === "/medicine" ||
    //   location.pathname === "/services/order-medicines"
    // ) {
    //   if (text) {
    //     history.push({
    //       pathname: APP_ROUTES.MEDICINE,
    //       search: `?search_query=${text}`,
    //     });
    //   } else {
    //     history.push({ pathname: APP_ROUTES.MEDICINE });
    //   }
    //   // })
    // }
  };

  var customClass;
  customClass =
    "absolute w-full md:w-7/12 lg:w-7/12 -top-1 md:top-0 lg:top-15 flex flex-wrap justify-center mt-2 md:p-3 lg:p-6 bg-brand-secondary  ";
  var isIOS = (function () {
    var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    return isIOS;
  })();

  function getLocation() {
    setError({
      isMMILoading: true,
      locationError: "",
    });
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      if (result.state === "granted") {
        if (window.navigator.geolocation) {
          window.navigator.geolocation.getCurrentPosition(showPosition);
        } else {
        }
      } else if (result.state === "prompt") {
        // showButtonToEnableMap();
      } else if (result.state === "denied") {
        setError({
          locationError: "Please allow browser to detect location",
          isMMILoading: false,
        });
      }
      // Don't do anything if the permission was denied.
    });
  }

  function showPosition(position) {
    const { coords } = position;
    var coordsObj = {
      lat: coords.latitude,
      long: coords.longitude,
    };
    var apiUrl = "MMI/accesstoken";
    http.get(apiUrl).then((response) => {
      let data = response.data;
      axios
        .get(
          `https://apis.mapmyindia.com/advancedmaps/v1/${data.access_token}/rev_geocode?lat=${coords.latitude}&lng=${coords.longitude}`
        )
        .then((res) => {
          if (!res.data.results[0].pincode) {
            setError({
              isMMILoading: false,
              locationError: "Not able to detect your location.",
            });
            setOpen(false);
          } else {
            dispatch(
              actioncustomPinCode({
                pincode: res.data.results[0].pincode,
                location: "current",
              })
            );
            dispatch(setLatLong(coordsObj));
            sessionStorage.setItem(
              "customPinCode",
              res.data.results[0].pincode
            );
            sessionStorage.setItem("coords", JSON.stringify(coordsObj));
            setError({
              isMMILoading: false,
            });
            setOpen(false);
          }
        })
        .catch((err) => {
          setError({
            locationError: "Error occured in MMI",
            isMMILoading: false,
          });
        });
    });
  }

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && !scroll) {
        setScroll(true);
      }
      if (currentScrollY < 5) {
        setScroll(false);
      }

      prevScrollY.current = currentScrollY;
    };
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [location]);

  const closeOpenMenus = (e) => {
    if (
      locationDropdownRef.current &&
      open &&
      !locationDropdownRef.current.contains(e.target)
    ) {
      setOpen(false);
      setError({
        locationError: "",
      });
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", closeOpenMenus);
    return () => window.removeEventListener("mousedown", closeOpenMenus);
  }, [open]);

  const [scroll, setScroll] = useState(false);

  return (
    <div className="w-full h-[75px] bg-[#bfe4ff] py-3 sticky top-[80px] z-[999] header-search-bar">
      <div
        className={` ${visible} flex justify-center sticky top-16 lg:top-10 z-10 ${
          location.pathname == "/" ? "pb-0 sm:pb-0" : "pb-20 sm:pb-0"
        }`}
      >
        <LocationPicker getLocation={getLocation} />
        <AutoComplete
          className="w-full sm:w-2/5 mr-2 sm:mx-0"
          style={{ borderRadius: 18 }}
          options={searchResults}
          onSelect={(value) => redirectTo("", value)}
          onSearch={onSearchText}
          size="large"
        >
          <Input.Search
            onClear={() => {
              setSearchResults([]);
              searchedText(" ");
            }}
            onPressEnter={(e) => redirectTo("", e.target.value)}
            onSearch={(value) => redirectTo("", value)} // handles the click on the search button
            className="animated-placeholder"
            style={{ width: "100%" }}
            styles={{
              affixWrapper: {
                borderRadius: 0,
                height: 44,
                backgroundColor: "white",
              },
            }}
            size="large"
            placeholder={placeholderText}
            variant="borderless"
            enterButton 
            allowClear
          />
        </AutoComplete>
      </div>
    </div>
  );
}

export default SearchBar;
