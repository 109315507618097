import React, { useState, useEffect } from "react";
import Layouts from "../../Layouts";
import SurgeryImage from "../../../Assets/Images/Surgery.jpg";
import hospital from "../../../Assets/Images/hospital.svg";
import doctorImage from "../../../Assets/Images/doctorImage.svg";
import Surgery1 from "../../../Assets/Images/Icons/Surgery/Piles.svg";
import Surgery2 from "../../../Assets/Images/Icons/Surgery/Fistula.svg";
import Surgery3 from "../../../Assets/Images/Icons/Surgery/Fissures.svg";
import Surgery4 from "../../../Assets/Images/Icons/Surgery/Kidney Stones.svg";
import Surgery5 from "../../../Assets/Images/Icons/Surgery/Hydrocele.svg";
import Surgery6 from "../../../Assets/Images/Icons/Surgery/Gall Stones.svg";
import Surgery7 from "../../../Assets/Images/Icons/Surgery/Lipoma.svg";
import Surgery8 from "../../../Assets/Images/Icons/Surgery/Varicocele.svg";
import Surgery9 from "../../../Assets/Images/Icons/Surgery/Varicose veins.svg";
import Surgery10 from "../../../Assets/Images/Icons/Surgery/Appendicitis.svg";
import Surgery11 from "../../../Assets/Images/Icons/Surgery/Gynaecomastia.svg";
import Surgery12 from "../../../Assets/Images/Icons/Surgery/Hernia.svg";
import Surgery13 from "../../../Assets/Images/Icons/Surgery/Arthroplasty THR.svg";
import Surgery14 from "../../../Assets/Images/Icons/Surgery/Arthroplasty_TKR.svg";
import Surgery15 from "../../../Assets/Images/Icons/Surgery/Cystoscopy.svg";
import Surgery16 from "../../../Assets/Images/Icons/Surgery/Rhinoplasty.svg";
import Surgery17 from "../../../Assets/Images/Icons/Surgery/Liposuction.svg";
import Surgery18 from "../../../Assets/Images/Icons/Surgery/LASIK.svg";
import Surgery19 from "../../../Assets/Images/Icons/Surgery/Phimosis.svg";
import choose1 from "../../../Assets/Images/Icons/Holistic.svg";
import choose2 from "../../../Assets/Images/Icons/Smooth.svg";
import choose3 from "../../../Assets/Images/Icons/Surgical.svg";
import choose4 from "../../../Assets/Images/Icons/Digital Records.svg";
import choose5 from "../../../Assets/Images/Icons/Online Enquiries.svg";
import choose6 from "../../../Assets/Images/Icons/Customer.svg";
import choose7 from "../../../Assets/Images/Icons/Elite Doctor.svg";
import Surg_2 from "../../../Assets/Images/Surgery/popup.svg";
import Surg_3 from "../../../Assets/Images/Surgery/popup_1.svg";
import Surg_4 from "../../../Assets/Images/Surgery/popup_2.svg";
import Surg_5 from "../../../Assets/Images/Surgery/popup_3.svg";
import female_icon from "../../../Assets/Images/Female-Vector-icon.svg";
import male_icon from "../../../Assets/Images/Male-Vector-icon.svg";
import { useHistory } from "react-router-dom";
import { Form, Input, InputNumber, Modal, Select, message } from "antd";
import { Dropdown } from "primereact/dropdown";
import { EnvironmentOutlined } from "@ant-design/icons";
import DoctorImage from "../../../Assets/Images/Doctor.png";
import HospitalImage from "../../../Assets/Images/Hospital.png";
import { useDispatch, useSelector } from "react-redux";
import { gethospitallist } from "../../../Redux/Action/hospitalpageActions";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import rooms from "../../../Assets/Images/room.svg";
import AdityaAshwiniHospital from "../../../Assets/Images/Surgery/AdityaAshwiniHospital.jpg";
import AdoreasiaHospital from "../../../Assets/Images/Surgery/AdoreasiaHospital.jpg";
import apollohospitalsvectorlogo from "../../../Assets/Images/Surgery/apollohospitalsvectorlogo.png";
import SaraGastroLaproscopicHospital from "../../../Assets/Images/Surgery/SaraGastroLaproscopicHospital.jpg";
import SushrutaHospitalTraumaCare from "../../../Assets/Images/Surgery/SushrutaHospitalTraumaCare.jpg";
import TrinityHospital from "../../../Assets/Images/Surgery/TrinityHospital.jpg";
import UtkalHospital from "../../../Assets/Images/Surgery/UtkalHospital.png";
import Rotary from "../../../Assets/Images/Surgery/Rotary.jpg";
import Slider from "react-slick";
import PrevArrow from "../../../Assets/Images/left_arrow.png";
import NextArrow from "../../../Assets/Images/right_arrow.png";
import {
  getDoctorslist,
  getDoctorspecialitylist,
} from "../../../Redux/Action/doctorAction";
import http from "../../../Redux/Services/http-common";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import surgeryimg1 from "../../../Assets/Images/surgeryimg1.png";
import surgeryimg2 from "../../../Assets/Images/surgeryimg2.png";
import surgeryimg3 from "../../../Assets/Images/surgeryimg3.png";
import surgerytype1 from "../../../Assets/Images/surgerytype1.png";
import surgerytype2 from "../../../Assets/Images/surgerytype2.png";
import surgerytype3 from "../../../Assets/Images/surgerytype3.png";
import surgerytype4 from "../../../Assets/Images/surgerytype4.png";
import surgerytype5 from "../../../Assets/Images/surgerytype5.png";
import surgerytype6 from "../../../Assets/Images/surgerytype6.png";
import surgerytype7 from "../../../Assets/Images/surgerytype7.png";
import surgerytype8 from "../../../Assets/Images/surgerytype8.png";
import surgerytype9 from "../../../Assets/Images/surgerytype9.png";
import surgerytype10 from "../../../Assets/Images/surgerytype10.png";
import surgerytype11 from "../../../Assets/Images/surgerytype11.png";
import surgerytype12 from "../../../Assets/Images/surgerytype12.png";
import surgerytype13 from "../../../Assets/Images/surgerytype13.png";
import surgerytype14 from "../../../Assets/Images/surgerytype14.png";
import surgerytype15 from "../../../Assets/Images/surgerytype15.png";
import surgerytype16 from "../../../Assets/Images/surgerytype16.png";
import surgerytype17 from "../../../Assets/Images/surgerytype17.png";
import surgerytype18 from "../../../Assets/Images/surgerytype18.png";
import surgerycare from "../../../Assets/Images/surgerycare.png";
import surgerycaremobile from "../../../Assets/Images/surgerycaremobile.png";
import FAQ from "../FAQ";
import { trackPageEvent } from "../../utils/analytics/events";

function Surgery() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [openModal, setModal] = useState(false);
  const [appointmentMessage, setAppointmentMessage] = useState(false);
  const search = useSelector((state) => state.authReducer.search);
  // const search_params = useLocation().search;
  const [form] = Form.useForm();
  const searchss = useLocation().search;
  const search_query = new URLSearchParams(searchss).get("search_query");
  const { coords ,radius } = useSelector((state) => state.authReducer);
  const customPinCode = useSelector((state) => state.authReducer.customPinCode);
  const [speciality, setSpeciality] = useState([]);
  const [city, setCity] = useState([]);
  const [surgeryObj, setSurgeryObj] = useState([]);
  const hospitallist = useSelector((state) => state.hospitallist);
  const { hospitallistData } = hospitallist;
  const doctorlist = useSelector((state) => state.doctorlist);
  const { doctorData } = doctorlist;
  const userData = useSelector((state) => state.authReducer.patientData);
  const [surgeryData, setSurgeryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const patientinfo = useSelector((state) => state.patientinfo);
  const [profile, setProfile] = useState(userData);
  const { patientinfoData, isLoading, isSuccess } = patientinfo;
  console.log(doctorData, "doctorData");
  const appointment = () => {
    setModal(true);
  };


  useEffect(() =>{
    trackPageEvent("surgery_page_view")
  },[])

  const handleCancel = () => {
    setModal(false);
  };

  const handleKeyPress = (e) => {
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
    ];
    if (!allowedKeys.includes(e.key)) {
      if (/^[A-Za-z]$/.test(e.key)) {
        message.error("Alphabetic characters are not allowed.");
      }
      e.preventDefault();
    }
  };

  const handleSubmit = () => {
    let values = form.getFieldValue();
    if (values.surgeryId === undefined || values.surgeryId === "") {
      message.error("Please fill in all fields");
      return;
    }

    if (profile.firstName === undefined || profile.firstName === "") {
      if (values.patientName === undefined || values.patientName === "") {
        message.error("Please fill in all fields");
        return;
      }
    }

    if (profile.email === undefined || profile.email === "") {
      if (values.patientEmail === undefined || values.patientEmail === "") {
        message.error("Please fill in all fields");
        return;
      }
    }

    if (profile.mobile === undefined || profile.mobile === "") {
      if (values.mobileNo === undefined || values.mobileNo === "") {
        message.error("Please fill in all fields");
        return;
      }
    }

    if (values.city === undefined || values.city === "") {
      message.error("Please fill in all fields");
      return;
    }

    let payload;

    if (Object.keys(userData).length === 0) {
      payload = {
        patientName: values.patientName,
        patientEmail: values.patientEmail,
        city: values.city,
        patientId: userData.code,
        mobileNo: values.mobileNo,
        sourceChannel: "B2c",
        state: "OD",
        status: 1,
        surgeryId: values.surgeryId,
      };
    } else {
      payload = {
        patientName: values.patientName
          ? values.patientName
          : profile.firstName,
        patientEmail: values.patientEmail ? values.patientEmail : profile.email,
        city: values.city,
        patientId: userData.code,
        mobileNo: values.mobileNo ? values.mobileNo : profile.mobile,
        sourceChannel: "B2c",
        state: "OD",
        status: 1,
        surgeryId: values.surgeryId,
      };
    }

    payload = {
      patientName: values.patientName ? values.patientName : profile.firstName,
      patientEmail: values.patientEmail ? values.patientEmail : profile.email,
      city: values.city ? values.city : profile.city,
      patientId: userData.code,
      mobileNo: values.mobileNo ? values.mobileNo : profile.mobile,
      sourceChannel: "B2c",
      state: "OD",
      status: 1,
      surgeryId: values.surgeryId,
    };
    setLoading(true);
    http
      .post(`${process.env.REACT_APP_BASEURL}surgery/bookAppointment`, payload)
      .then((res) => {
        message.success("Appointment Booked");
        form.resetFields();
        setLoading(false);
        setModal(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleViewClick = (e, hosp) => {
    e.preventDefault();
    history.push(
      `/hospital/${hosp.seoNameId}`
      // state: hosp
    );
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (coords) {
          await dispatch(
            getDoctorslist(
              coords,
              search_query ? search_query : "",
              1,
              100,
              radius ? radius : ""
            )
          );
        }
      } catch (error) {
        console.error("Error fetching doctors list:", error);
      }
    };

    fetchData();
  }, [coords, search_query, dispatch]);

  useEffect(() => {
    // let customPinCode = sessionStorage.getItem('customPinCode')
    // if (customPinCode) {

    dispatch(
      gethospitallist(
        coords,
        search_query ? search_query : "",
        1,
        80,
        radius ? radius : ""
      )
    );
    // }
  }, [sessionStorage.getItem("customPinCode"), search_query]);

  const handleChange = (e) => {
    setSubmitData({
      ...submitData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const fetchSpecialityList = async () => {
      try {
        const res = await dispatch(getDoctorspecialitylist());
        setSpeciality(res);
      } catch (error) {
        console.error("Error fetching speciality list:", error);
      }
    };

    fetchSpecialityList();
  }, [dispatch]);

  const handleCardClick = (e, doctor) => {
    e.preventDefault();
    history.push(`/doctors/${doctor.seoNameId}`);

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [formerror, setFormerror] = useState({
    patientId: "",
    patientName: "",
    mobileNo: "",
    state: "",
    city: "",
    surgeryId: "",
  });

  const [submitData, setSubmitData] = useState({
    patientId: "",
    patientName: "",
    mobileNo: "",
    state: "",
    city: "",
    surgeryId: "",
  });

  const Industry = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: <img src={PrevArrow} alt="icons" />,
    nextArrow: <img src={NextArrow} alt="icons" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const Surgery = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: <img src={PrevArrow} alt="icons" />,
    nextArrow: <img src={NextArrow} alt="icons" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handleImgError = (e, gender) => {
    if (gender === "M" || gender === "F") {
      e.target.src = doctorImage;
    } else {
      e.target.src = hospital;
    }
  };

  const getSurgeries = async () => {
    try {
      let data = await http.get(
        `${process.env.REACT_APP_BASEURL}surgery/allSurgTreatments`
      );
      setSurgeryData(data.data);
      let diseases = data.data.map((obj) => {
        return obj.surgeryName;
      });
      setSurgeryObj(data.data);
    } catch (err) {
      console.log("error", err);
    }
  };
  useEffect(() => {
    getSurgeries();
  }, []);

  const handleNavigation = (name) => {
    let preData = surgeryData.find((res) => res.surgeryName === name);
    console.log(preData)
    if (preData) {
      history.push(`/surgery-details/${preData.surgeryName}--${preData.id}`);
      if (/Mobi|Android/i.test(navigator.userAgent)) {
        window.scrollTo(0, 0);
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      console.log(`Surgery "${name}" not found in surgeryData.`);
    }
  };
  const surgery = [
    {
      surgeryname: "Phimosis",
      surgeryimg: surgerytype1,
      link: "Phimosis",
    },
    {
      surgeryname: "Rhinoplasty",
      surgeryimg: surgerytype2,
      link: "Rhinoplasty",
    },
    {
      surgeryname: "Hernia",
      surgeryimg: surgerytype3,
      link: "Hernia",
    },
    {
      surgeryname: "Gynaecomastia",
      surgeryimg: surgerytype4,
      link: "Gynaecomastia",
    },
    {
      surgeryname: "Varicocele",
      surgeryimg: surgerytype5,
      link: "Varicocele",
    },
    {
      surgeryname: "Cystoscopy",
      surgeryimg: surgerytype6,
      link: "Cystoscopy",
    },

    {
      surgeryname: "Varicose veins",
      surgeryimg: surgerytype7,
      link: "Varicose veins",
    },
    {
      surgeryname: "Gall stones",
      surgeryimg: surgerytype8,
      link: "Gall Stones",
    },
    {
      surgeryname: "Liposuction",
      surgeryimg: surgerytype9,
      link: "Liposuction",
    },
    {
      surgeryname: "Kidney stones",
      surgeryimg: surgerytype10,
      link: "Kidney Stones",
    },
    {
      surgeryname: "Fissures",
      surgeryimg: surgerytype11,
      link: "Fissures",
    },
    {
      surgeryname: "Hydrocele",
      surgeryimg: surgerytype12,
      link: "Hydrocele",
    },

    {
      surgeryname: "Piles",
      surgeryimg: surgerytype13,
      link: "Piles",
    },
    {
      surgeryname: "Fistulas",
      surgeryimg: surgerytype14,
      link: "Fistula",
    },
    {
      surgeryname: "Appendicitis",
      surgeryimg: surgerytype15,
      link: "Appendicitis",
    },
    {
      surgeryname: "Cataract",
      surgeryimg: surgerytype16,
      link: "Cataract",
    },
    {
      surgeryname: "Lipoma",
      surgeryimg: surgerytype17,
      link: "Lipoma",
    },
    {
      surgeryname: "Arthroplasty THR",
      surgeryimg: surgerytype18,
      link: "Arthroplasty THR",
    },
  ];
  return (
    <Layouts>
      {/* {loading &&<Loader/>} */}
      <ToastContainer />
      <section className="home-banner bg-White md:min-h-[60vh] py-8 ">
        <div className="container mx-auto">
          <div className="bg-primaryColor absolute left-0 md:w-[70%] md:h-[60vh] rounded-e-xl"></div>
          <div className="md:grid grid-cols-2 gap-5 md:min-h-[60vh] items-center">
            <div className="relative z-1 pt-7 md:pt-5 p-5 md:p-0 rounded-2xl bg-[#F3F8FF] md:bg-primaryColor">
              <h1 className="text-primaryColor md:text-White text-2xl md:text-5xl font-bold mb-5 !leading-snug">
                Precision{" "}
                <span className="text-secondaryColor">procedures</span> with
                personalised care
              </h1>
              <p className="text-primaryColor md:text-White font-extralight text-sm md:text-lg">
                Simple Surgical Solutions with CureBay.
              </p>
              <button
                className="sm:bg-secondaryColor bg-primaryColor h-10 px-6 rounded-xl text-white text-White my-6"
                onClick={appointment}
              >
                Consult for a Surgery
              </button>
            </div>
            <div className="relative z-1 flex justify-end mt-5 md:mt-0">
              <div className="w-full md:w-[90%] md:h-[400px]  rounded ">
                <img
                  src={SurgeryImage}
                  alt="images"
                  className="w-full h-full object-cover image-boxshadow rounded-xl"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-White">
        <div className="container mx-auto py-[50px] md:py-[90px] relative">
          <div className="hidden md:block">
            <div className="tech-grid">
              <div
                className=" bg-primaryColor px-7 py-10 rounded-2xl flex flex-col justify-between"
                id="techOne"
              >
                <p className="uppercase text-White font-semibold text-base">
                  CONSULTATIONS
                </p>
                <span className="text-White text-7xl font-medium">15K+</span>
              </div>
              <div className="w-full" id="techTwo">
                <img src={surgeryimg1} alt="images" className="w-full" />
              </div>
              <div
                className=" bg-primaryColor px-7 py-10 rounded-2xl flex flex-col justify-between"
                id="techThree"
              >
                <p className="uppercase text-White font-semibold text-base">
                  Surgeries
                </p>
                <span className="text-White text-6xl font-medium">5500+</span>
              </div>
              <div className="w-full" id="techFour">
                <img src={surgeryimg2} alt="images" className="w-full" />
              </div>
              <div
                className=" bg-primaryColor px-7 py-10 rounded-2xl flex flex-col justify-between"
                id="techFive"
              >
                <p className="uppercase text-White font-semibold text-base">
                  verified doctors
                </p>
                <span className="text-White text-6xl font-medium">170+</span>
              </div>
              <div className="w-full" id="techSix">
                <img src={surgeryimg3} alt="images" className="w-full" />
              </div>
              <div
                className=" bg-primaryColor px-7 py-10 rounded-2xl flex flex-col justify-between"
                id="techSeven"
              >
                <p className="uppercase text-White font-semibold text-base">
                  HOSPITAL PARTNERS
                </p>
                <span className="text-White text-6xl font-medium">100+</span>
              </div>
            </div>
          </div>
          <div className="block md:hidden">
            <div className="flex gap-3">
              <div className="w-[40%]">
                <div
                  className="mb-3 bg-primaryColor p-3 md:px-7 sm:h-[150px] h-[116px] md:py-10 rounded-2xl flex flex-col justify-between"
                  id="techSeven"
                >
                  <p className="capitalize text-White font-medium text-xs md:text-base">
                    Happy Patients
                  </p>
                  <span className="text-White text-2xl md:text-6xl font-medium">
                    1 Lac+
                  </span>
                </div>
                <div className="w-full mb-3" id="techFour">
                  <img src={surgeryimg3} alt="images" className="w-full" />
                </div>
                <div
                  className="mb-3 bg-primaryColor p-3 md:px-7 h-[100px] md:py-10 rounded-2xl flex flex-col justify-between"
                  id="techFive"
                >
                  <p className="capitalize text-White font-medium text-xs md:text-base">
                    Pin-Code Covers
                  </p>
                  <span className="text-White text-2xl md:text-6xl font-medium">
                    398
                  </span>
                </div>
                <div className="w-full" id="techSix">
                  <img
                    src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/patient3.png"
                    alt="images"
                    className="w-full"
                  />
                </div>
              </div>
              <div className="w-[60%]">
                <div className="w-full mb-3" id="techTwo">
                  <img
                    src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/patient1.png"
                    alt="images"
                    className="w-full"
                  />
                </div>
                <div
                  className="mb-3 bg-lightGray p-3 md:px-7 h-[225px] md:py-10 rounded-2xl flex flex-col justify-between"
                  id="techSeven"
                >
                  <p className="capitalize text-primaryColor font-medium text-base md:text-base">
                    Hospital Partners
                  </p>
                  <span className="text-primaryColor text-4xl md:text-6xl font-medium">
                    100+
                  </span>
                </div>
                <div
                  className="mb-3 bg-lightGray p-3 md:px-7 h-[120px]  md:py-10 rounded-2xl flex flex-col justify-between"
                  id="techSeven"
                >
                  <p className="capitalize text-primaryColor font-medium text-xs md:text-base">
                    Verified Doctors
                  </p>
                  <span className="text-primaryColor text-2xl md:text-6xl font-medium">
                    170+
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{ backgroundColor: "#025B91" }}>
        <h6 className="sm:text-4xl text-2xl font-semibold  text-white text-center sm:pt-[60px] pt-[24px] sm:pb-[32px] pb-[15px]">
          <p style={{ color: "#49C285" }}>
            Curebay <span className="text-White"> cares for you</span>
          </p>
        </h6>
        <div>
          <div
            style={{ backgroundColor: "#025b91" }}
            className="rounded-2xl flex justify-center items-center pb-[30px] hidden sm:flex"
          >
            <img src={surgerycare} />
          </div>

          <div
            style={{ backgroundColor: "#025b91" }}
            className="rounded-2xl flex sm:hidden justify-center items-center "
          >
            <img src={surgerycaremobile} />
          </div>
        </div>
      </section>

      <section className="bg-White">
        <div className="container mx-auto py-[50px] md:py-[90px]">
          <h2 className="text-primaryColor text-center md:text-primaryColor text-2xl md:text-5xl font-bold mb-10 !leading-snug">
            Unlock expertise in 50+{" "}
            <span className="text-secondaryColor">
              <br />
              cutting-edge surgeries
            </span>
          </h2>
          <div className="grid grid-cols-3 md:grid-cols-6 sm:gap-6 gap-2 justify-center flex-wrap">
            {surgery.map((surgery, index) => (
              <div
                onClick={() => handleNavigation(surgery.link)}
                className="cursor-pointer bg-[#F3F8FF] hover:bg-primaryColor rounded-xl p-3 md:p-5  hoverWhite sm:h-[177px] h-[107px]"
              >
                <div className=" items-center gap-5">
                  <div
                    key={index}
                    className="flex flex-col items-center w-full"
                  >
                    <img
                      src={surgery.surgeryimg}
                      alt={surgery.surgeryname}
                      className="w-[40px] md:w-[60px]"
                    />
                    <h5 className="text-primaryColor text-sm md:text-xl font-semibold mt-2 md:mt-4">
                      {surgery.surgeryname}
                    </h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="bg-[#F3F8FF]  ">
        <div className="container mx-auto pt-[25px] pb-[50px] md:py-[60px]">
          <h2 className="text-center text-2xl md:text-4xl font-semibold text-primaryColor leading-normal sm:mb-[50px] mb-[15px]">
            Available
            <span className="text-secondaryColor">Hospitals</span>
          </h2>
          {hospitallistData.length ? <Slider {...Industry}>
            {hospitallistData.map((hosp, index) => (
              <div key={index}>
                <div className="card2">
                  <img
                    src={
                      hosp.hospitalPhoto
                        ? `${process.env.REACT_APP_IMG_BASEURL}${hosp.hospitalPhoto}`
                        : hospital
                    }
                    className="card2-image"
                  />
                  <div className="card2-content justify-center items-center">
                    <h3 className="card2-title">{hosp.hospitalName}</h3>
                    <p className="card2-location flex">
                      <EnvironmentOutlined
                        className="text-secondaryColor"
                        style={{ marginRight: "5px" }}
                      />
                      <span>{hosp.city ? hosp.city : "NA"}</span>
                    </p>
                  </div>
                  <button
                    onClick={(e) => handleViewClick(e, hosp)}
                    className="w-full bg-primaryColor text-White px-2 py-2 rounded-md mt-1 hover:bg-secondaryColor"
                  >
                    View Details
                  </button>
                </div>
              </div>
            ))}
          </Slider> : <div className="text-md font-medium flex justify-center" >Hospitals not available on your location.</div>}
        </div>
      </section>

      <section className="bg-White">
        <div className="container mx-auto py-[50px] md:py-[60px]">
          <h2 className="text-center text-2xl md:text-4xl font-semibold text-primaryColor leading-normal sm:mb-[50px] mb-[20px]">
            Meet our{" "}
            <span className="text-secondaryColor">top rated surgeons</span>
          </h2>
          <Slider {...Surgery}>
            {doctorData.map((doc, index) => (
              <div>
                <div
                  className="bg-White rounded-xl p-2  mb-2 min-h-[150px] topCardwidth h-full"
                  style={{ border: "1px solid #E2E2E3" }}
                >
                  <div className="flex gap-5">
                    <div className="w-[75px] h-[75px]">
                      <img
                        onClick={(e) => handleCardClick(e, doc)}
                        src={
                          doc.userPhoto
                            ? `${process.env.REACT_APP_IMG_BASEURL}${doc.userPhoto}`
                            : doctorImage
                        }
                        alt={doc.userName}
                        onError={(e) => handleImgError(e, doc.gender)}
                        className="rounded-full bg-lightBlue text-xs  w-[65px] h-[65px] object-contain"
                      />
                    </div>
                    <div className="w-[80%]">
                      <h5 className="text-primaryColor w-full truncate overflow-hidden whitespace-nowrap font-semibold ">
                        Dr. {doc.userName}
                      </h5>
                      <div className="text-textGray text-sm ">
                        <span>{doc.specialities}</span>
                      </div>
                      <p className="text-textGray text-xs font-light mt-2 mb-1.5 flex">
                        <span className="font-medium mr-2">Experience :</span>
                        {doc.yearOfExperience} years
                      </p>
                      <p className="text-textGray text-xs font-light mb-1.5 flex">
                        <span className="font-medium mr-2">Location :</span>
                        {doc.city}
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-3 mt-3 mb-3">
                    <button
                      onClick={(e) => handleCardClick(e, doc)}
                      className="hover:text-secondaryColor text-primaryColor text-xs border border-primaryColor hover:border-secondaryColor w-full py-2 rounded-lg "
                    >
                      View Profile
                    </button>
                    <button
                      className="hover:bg-secondaryColor bg-primaryColor rounded-lg text-White text-xs w-full py-2 "
                      onClick={(e) => handleCardClick(e, doc)}
                    >
                      Book Consultation
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>

      {/* FAQ */}
      <section className="bg-primaryColor sm:mt-0 mt-8 sm:mb-24 mb-12">
        <div className="container mx-auto sm:py-[90px] py-[10px]">
          <h2 className="text-center text-2xl md:text-4xl font-bold text-White leading-normal mb-[50px]">
            Frequently Asked{" "}
            <span className="text-secondaryColor">Questions</span>
          </h2>
          <div className="md:w-[90%] mx-auto">
            <FAQ />
          </div>
        </div>
      </section>

      <section className="bg-White none" style={{ display: "none" }}>
        <div className="container mx-auto py-[50px] md:py-[90px]">
          <h2 className="text-center text-2xl md:text-4xl font-semibold text-primaryColor leading-normal mb-[50px]">
            Why Choose <span className="text-secondaryColor">CureBay</span>?
          </h2>
          <div className="md:flex gap-5 justify-center flex-wrap">
            <div className="bg-White hover:bg-primaryColor rounded-xl p-3 md:p-5 common-shadow  mb-5 md:mb-0 w-full md:w-[32%] hoverWhite">
              <div className="mb-5">
                <img src={choose1} alt="images" />
              </div>
              <h5 className="text-primaryColor text-lg md:text-xl font-semibold mb-3 ">
                Holistic and Personalized Care
              </h5>
              <p className="text-textGray md:leading-loose text-base">
                Experience individualized treatment plans tailored to your
                needs.
              </p>
            </div>
            <div className="bg-White hover:bg-primaryColor rounded-xl p-3 md:p-5 common-shadow mb-5 md:mb-0 w-full md:w-[32%] hoverWhite">
              <div className="mb-5">
                <img src={choose2} alt="images" />
              </div>
              <h5 className="text-primaryColor text-lg md:text-xl font-semibold mb-3 ">
                Smooth Insurance Approval
              </h5>
              <p className="text-textGray md:leading-loose text-base">
                Streamlined process for insurance and government scheme claims.
              </p>
            </div>
            <div className="bg-White hover:bg-primaryColor rounded-xl p-3 md:p-5 common-shadow mb-5 md:mb-0 w-full md:w-[32%] hoverWhite">
              <div className="mb-5">
                <img src={choose3} alt="images" />
              </div>
              <h5 className="text-primaryColor text-lg md:text-xl font-semibold mb-3 ">
                Comprehensive Surgical Treatment
              </h5>
              <p className="text-textGray md:leading-loose text-base">
                Complete surgical care, including post-operative support.
              </p>
            </div>
            <div className="bg-White hover:bg-primaryColor rounded-xl p-3 md:p-5 common-shadow mb-5 md:mb-0 w-full md:w-[32%] hoverWhite">
              <div className="mb-5">
                <img src={choose4} alt="images" />
              </div>
              <h5 className="text-primaryColor text-lg md:text-xl font-semibold mb-3 ">
                Digital Records
              </h5>
              <p className="text-textGray md:leading-loose text-base">
                Access your medical records digitally, eliminating paperwork
                hassles.
              </p>
            </div>
            <div className="bg-White hover:bg-primaryColor rounded-xl p-3 md:p-5 common-shadow mb-5 md:mb-0 w-full md:w-[32%] hoverWhite">
              <div className="mb-5">
                <img src={choose5} alt="images" />
              </div>
              <h5 className="text-primaryColor text-lg md:text-xl font-semibold mb-3 ">
                Online Enquiries
              </h5>
              <p className="text-textGray md:leading-loose text-base">
                Resolve hospital queries conveniently through the app or
                eClinic.
              </p>
            </div>
            <div className="bg-White hover:bg-primaryColor rounded-xl p-3 md:p-5 common-shadow mb-5 md:mb-0 w-full md:w-[32%] hoverWhite">
              <div className="mb-5">
                <img src={choose6} alt="images" />
              </div>
              <h5 className="text-primaryColor text-lg md:text-xl font-semibold mb-3 ">
                Dedicated Customer Service
              </h5>
              <p className="text-textGray md:leading-loose text-base">
                Our team is available round-the-clock for assistance.
              </p>
            </div>
            <div className="bg-White hover:bg-primaryColor rounded-xl p-3 md:p-5 common-shadow mb-5 md:mb-0 w-full md:w-[32%] hoverWhite">
              <div className="mb-5">
                <img src={choose7} alt="images" />
              </div>
              <h5 className="text-primaryColor text-lg md:text-xl font-semibold mb-3 ">
                Elite Doctor Consultations
              </h5>
              <p className="text-textGray md:leading-loose text-base">
                Connect with top-tier doctors from across India for expert
                advice.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Modal
        title="Book Appointment"
        visible={openModal}
        width={"50%"}
        okText="Submit"
        centered={true}
        onOk={() => {
          setModal(false);
        }}
        cancelText="Cancel"
        className="commonModal"
        onCancel={handleCancel}
        footer={null}
      >
        <>
          <div className="flex">
            <div className="w-1/2 flex-col mr-2 hidden">
              <img
                style={{ height: "30%" }}
                className="m-auto"
                src={Surg_2}
                alt="#"
              />
              <div className="p-2 gap-4 space-y-3">
                <h1 className="font-bold text-2xl">Our next step</h1>
                <p>Check how we make your process easy :</p>

                <div className=" flex p-3 shadow-lg mt-4">
                  <div className="w-1/5">
                    <img src={Surg_3} alt="#" />
                  </div>
                  <div className="w-full">
                    <h1
                      classname=""
                      style={{ color: "#004171", fontWeight: "700" }}
                    >
                      Care Coordinator
                    </h1>
                    <p className="text-sm">
                      Share your details and our care coordinator will get in
                      touch with you.
                    </p>
                  </div>
                </div>

                <div className=" flex p-3 shadow-lg">
                  <div className="w-1/5 mt-2">
                    <img src={Surg_4} alt="#" />
                  </div>
                  <div className="w-full">
                    <h1 style={{ color: "#004171", fontWeight: "700" }}>
                      Detailed Evaluation
                    </h1>
                    <p className="text-sm">
                      Top notch doctors at your service who will make a detailed
                      evaluation of your health.
                    </p>
                  </div>
                </div>

                <div className=" flex  p-3 shadow-lg">
                  <div className="w-1/5 mt-2">
                    <img src={Surg_5} alt="#" />
                  </div>
                  <div className="w-full ml-2">
                    <h1
                      classname=""
                      style={{ color: "#004171", fontWeight: "700" }}
                    >
                      Schedule
                    </h1>
                    <p className="text-sm">
                      Appointment will be scheduled to the earliest at our
                      partner hospitals..
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full">
              <p className="text-base text-textGray mb-5">
                Fill up the following details and we'll appoint you the best
                suitable surgeon
              </p>
              <Form
                // onChange={handleChange}
                form={form}
                onFinish={handleSubmit}
                className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5"
              >
                <div className="">
                  <label className="text-primaryColor text-sm mb-2 inline-block">
                    Patient Name <span className="text-danger">*</span>
                  </label>
                  <Form.Item className="mb-0" name="patientName">
                    <Input
                      type="text"
                      placeholder="Name"
                      defaultValue={profile.firstName}
                      name="patientName"
                      required={true}
                      className=" h-[50px] border border-gray rounded-md w-full px-3 text-sm"
                    />
                  </Form.Item>
                  {formerror.patientName && (
                    <span>{formerror.patientName}</span>
                  )}
                </div>
                <div className="">
                  <label className="text-primaryColor text-sm mb-2 inline-block">
                    Email ID <span className="text-danger">*</span>
                  </label>
                  <Form.Item className="mb-0" name="patientEmail">
                    <Input
                      type="text"
                      placeholder="Mail ID"
                      defaultValue={profile.email}
                      disabled={
                        Object.keys(userData).length === 0 ? false : true
                      }
                      // name="mailId"
                      required={true}
                      className="h-[50px] border border-gray rounded-md w-full px-3 text-sm"
                    />
                  </Form.Item>
                  {formerror.patientName && (
                    <span>{formerror.patientName}</span>
                  )}
                </div>
                <div>
                  <label className="text-primaryColor text-sm mb-2 inline-block">
                    Mobile Number <span className="text-danger">*</span>
                  </label>
                  <Form.Item className="mb-0" name="mobileNo">
                    <InputNumber
                      name="mobileNo"
                      maxLength={10}
                      onKeyPress={handleKeyPress}
                      disabled={
                        Object.keys(userData).length === 0 ? false : true
                      }
                      required={true}
                      placeholder="Phone No."
                      defaultValue={profile.mobile}
                      className="h-[50px] border border-gray rounded-md w-full px-3 text-sm"
                      // onInput={(e) =>
                      //   (e.target.value = e.target.value.slice(0, 10))
                      // }
                    />
                  </Form.Item>
                </div>
                <div>
                  <label className="text-primaryColor text-sm mb-2 inline-block">
                    City <span className="text-danger">*</span>
                  </label>
                  <Form.Item className="mb-0" name="city">
                    <Select
                      autocomplete="off"
                      id="city"
                      name="city"
                      // onChange={handleChange}
                      options={[
                        {
                          value: "Bhubaneswar",
                          label: "Bhubaneswar",
                        },
                        {
                          value: "Puri",
                          label: "Puri",
                        },
                        {
                          value: "Cuttack",
                          label: "Cuttack",
                        },
                      ]}
                      required={true}
                      optionLabel=""
                      placeholder="Select a City"
                      // defaultValue={profile.city}
                      className="h-[50px] rounded-md w-full text-sm"
                    />
                  </Form.Item>
                </div>
                <div>
                  <label className="text-primaryColor text-sm mb-2 inline-block">
                    Select Disease <span className="text-danger">*</span>
                  </label>
                  <Form.Item className="mb-0" name="surgeryId">
                    <Select
                      autocomplete="off"
                      id="surgeryId"
                      name="surgeryId"
                      value={submitData.surgeryId}
                      required={true}
                      options={surgeryObj.map((hc) => ({
                        value: hc.id,
                        label: hc.surgeryName,
                        key: hc.surgeryName,
                      }))}
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      optionLabel=""
                      placeholder="Select Disease"
                      className="h-[50px] rounded-md w-full text-sm"
                    />
                  </Form.Item>
                </div>
              </Form>
              <div className=" flex justify-center gap-3 md:gap-5 mt-8">
                <button
                  onClick={handleCancel}
                  className=" border border-primaryColor text-center text-primaryColor md:p-2 rounded-lg font-medium mt-4 text-sm md:text-base h-10 md:h-14 w-full"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  onClick={handleSubmit}
                  className="bg-primaryColor text-center text-White md:p-2 rounded-lg font-medium mt-4 text-sm md:text-base h-10 md:h-14 w-full"
                >
                  Book Appointment
                </button>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </Layouts>
  );
}

export default Surgery;
