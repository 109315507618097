import { useEffect, useState } from "react"
import doctorService from "../../../Redux/Services/doctorService"
import { useSelector } from "react-redux";
import doctorImage from "../../../Assets/Images/doctorImage.svg";
import VideoCall from "../../../Assets/Images/Icons/videocall.svg";
import LocationImages from "../../../Assets/Images/Icons/locationblue.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from 'axios'
import { Button } from 'antd';
import Snackbar from "@mui/material/Snackbar";
import http from '../../../Redux/Services/http-common'
const CallToActionComponent = ({data,snackbarmsg, setOpenSnackbar, sessionId}) =>{
  // Phone number to be copied or dialed
  const userData = useSelector((state) => state.authReducer.patientData);


  if(!data.length){
    return
  }
  const phoneNumber = "+918335000999"; // Replace with actual phone number

  // Function to handle "Call Us" button click
  const handleCallUs = () => {
    // Check if the user is on a mobile device
    if (navigator.userAgent.match(/iPhone|Android/i)) {
      window.location.href = `tel:${phoneNumber}`; // Open dial pad on mobile
    } else {
      navigator.clipboard.writeText(phoneNumber) // Copy phone number on desktop
        .then(() => {
          snackbarmsg({msg: "Phone number copied to clipboard!", type: "success"});
          setOpenSnackbar(true);
        })
        .catch(() => {
          snackbarmsg({msg: "Failed to copy the phone number. Try again", type: "warning"});
          setOpenSnackbar(true);
        });
    }
  };

  // Function to handle "Request a Callback" button click
  const handleRequestCallback = async () => {
    try {
      const response = await http.post(`${process.env.REACT_APP_CARE_SATHI}call_to_action/call_back_V1`, {
        // Add any payload if required for your API
        identifier: userData.code,
        session_id: sessionId
      });
      // let res = await response.json()
      console.log(response, "sdvsdovusdguovsdsdv")
      if(response.data.data.status == "success"){
        snackbarmsg({msg: "Callback request sent successfully!", type: "success"});
        setOpenSnackbar(true);
      }
      
    } catch (error) {
      snackbarmsg({msg: "Failed to send callback request. Try again!", type: "error"});
      setOpenSnackbar(true);
      console.error("Error:", error);
    }
  };

  // Function to handle "Send an Email" button click
  const handleSendEmail = () => {
    const email = "support@curebay.com"; // Replace with actual email
    window.location.href = `mailto:${email}`;
  };

    return(
        <div className= "">
          <div>
            {data.map((res, index) => {
              return(
                <>
                {/* <div style={{fontSize: "14px"}}>{res.reasoning}</div> */}
                <div style={{fontSize: "14px"}}>{res.service} - <a target="_blank" className='text-White underline' href= {res.link} >{res.link}</a></div>
                </>
              )
            } )}
</div>
<div className="grid grid-cols-2 sm:grid-cols-3 gap-2" >
          <Button
          type="primary"
        className="bg-blue-500 text-white font-bold py-2 px-2 rounded m-2 h-auto"
        onClick={handleCallUs}
        size="large" // Makes the button larger
        style={{
          backgroundColor: "#1890ff", // Custom blue color
          borderColor: "#1890ff",
          color: "#fff",
          boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)", // Adds shadow for a beautiful effect
          fontSize: 12
        }}
      >
        Call Us <p>(+918335000999)</p>
      </Button>
      
      {/* Request a Callback Button */}
      <Button
      type="primary"
        className="bg-green-500 text-white font-bold py-2 px-2 rounded m-2 h-auto"
        onClick={handleRequestCallback}
        size="large" // Makes the button larger
        style={{
          backgroundColor: "#1890ff", // Custom blue color
          borderColor: "#1890ff",
          color: "#fff",
          boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)", // Adds shadow for a beautiful effect
          fontSize: 12

        }}
      >
        Request a Callback
      </Button>
      
      {/* Send an Email Button */}
      <Button
      type="primary"
        className="bg-yellow-500 text-white font-bold py-2 px-2 rounded m-2 h-auto"
        onClick={handleSendEmail}
        size="large" // Makes the button larger
        style={{
          backgroundColor: "#1890ff", // Custom blue color
          borderColor: "#1890ff",
          color: "#fff",
          boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)", // Adds shadow for a beautiful effect
          fontSize: 12
        }}
      >
        Send an Email <p>(support@curebay.com)</p>
      </Button>
          </div>
      </div>
    )
}

export default CallToActionComponent